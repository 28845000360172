import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import Webcam from "react-webcam"
import { FaCamera, FaCircle, FaDesktop, FaSpinner, FaTimes } from 'react-icons/fa'
import { IoMdSettings } from 'react-icons/io'
import { MEETING_HOST, SESSION_LOCATION_TYPE, USER_TYPES, USER_TYPE } from '../../constants'
import { addVideoEntry, directUpload, generateNewJitsiKey, getUser, getUserById, updateStudio, uploadNewVideo } from '../../services'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { update as updateStudioInStore} from '../../store/studios'

let loading = false

const FRAME_FORCUS = {
  LOCAL: 'local',
  REMOTE: 'remote',
  BOTH: 'both'
}

let search = window.location.search.substring(1);
let queryParams = {}
try {
  queryParams = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
} catch (err) {
  queryParams = {}
}

const MeetFrame = ({ meeting_id, record, studio, session, inPersonFrame = false, remoteFrame = true, backOfficeFrame = false }) => {
  const [user, setUser] = useState(null)
  const [api, setApi] = useState(null)
  const [api2, setApi2] = useState(null)
  const [key, setKey] = useState(0)
  const [key2, setKey2] = useState(0)
  const [hide, setHide] = useState(false)
  const [focus, setFocus] = useState(FRAME_FORCUS.BOTH)
  const [localRecording, setLocalRecording] = useState(false)
  const webcamRef = useRef(null)
  const mediaRecorderRef = useRef(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [uploading, setUploading] = useState(false)
  const [cameras, setCameras] = useState([])
  const [audios, setAudios] = useState([])
  const [selectedCameraId, setSelectedCameraId] = useState(null)
  const [selectedAudioId, setSelectedAudioId] = useState(null)
  const [showSettings, setShowSettings] = useState(false)
  const [backOfficeMeetingId, setBackOfficeMeetingId] = useState(null)
  const dispatch = useDispatch()

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        if (!session.currentGroup) { return }
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks, session.currentGroup]
  );

  const handleStartCaptureClick = useCallback(() => {
    setLocalRecording(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      type: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, localRecording, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
  }, [mediaRecorderRef]);

  useEffect(() => {
    if (recordedChunks.length === 0 || (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive')) { return }
    const handleUpload = async () => {
      setLocalRecording(false);

      setUploading(true)
      const videoFile = new File(recordedChunks, `${new Date()}.webm`, {
        type: "video/webm"
      })
      const res = await directUpload(videoFile, meeting_id, '', null, '.local-upload-progress')
      await addVideoEntry(res.Key, session.currentGroup, session._id, 'thumb-' + res.Key)

      setUploading(false)
      const elem = document.querySelector('#group-video-reload-btn')
      if (elem) { elem.click() }
      setRecordedChunks([])
    }
    handleUpload()
  }, [recordedChunks])

  useEffect(() => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop()
    }
  }, [session.currentGroup])

const handleRemoteLoad = (meetingId, frameId) => {
  loading = true;

  if (!window.JitsiMeetExternalAPI) {
    setTimeout(() => { handleRemoteLoad(meetingId, frameId); }, 1000);
  } else {
    const url = new URL(MEETING_HOST);
    let options = {
      roomName: meetingId,
      parentNode: document.querySelector(`#${frameId}`)
    };

    // Apply toolbar changes to the Jitsi frame (iframe-wrapper) only when in back office mode
    if (frameId === 'iframe-wrapper' && backOfficeFrame) {
      options.interfaceConfigOverwrite = {
        TOOLBAR_ALWAYS_VISIBLE: false 
      };

      // Show only the buttons you want
      options.configOverwrite = {
        toolbarButtons: ['chat', 'sharedvideo', 'hangup', 'filmstrip']
      };
    }

    let apiInstance;

    if (frameId === 'backoffice-iframe-wrapper') {
      if (api2) { api2.dispose(); }
      apiInstance = new window.JitsiMeetExternalAPI(url.host, options);
      setApi2(apiInstance);
    } else {
      if (api) { api.dispose(); }
      apiInstance = new window.JitsiMeetExternalAPI(url.host, options);
      setApi(apiInstance);
    }

    loading = false;
  }
};
  
  const handleRemoteUnload = (frameId) => {
    if (!window.JitsiMeetExternalAPI) {
      setTimeout(() => { handleRemoteUnload(frameId) }, 1000)
    }
    if(frameId === 'backoffice-iframe-wrapper'){
      if (api2) { api2.dispose() }
      setApi2(null)
    } else {
      if (api) { api.dispose() }
      setApi(null)
    }
  }

  useEffect(() => {
    
    const generateBackOfficeMeetingId = async () => {
      const { jitsi_meeting_id: back_office_meeting_id } = await generateNewJitsiKey()
      const formData = new FormData()
      formData.append('back_office_meeting_id', back_office_meeting_id)
      updateStudio(formData, studio._id).then(res => {
        setBackOfficeMeetingId(res.back_office_meeting_id)
        dispatch(updateStudioInStore(res))
      }).catch(err => {
        console.log(err);
      });
    }

    if(backOfficeFrame && !studio.back_office_meeting_id){
      generateBackOfficeMeetingId()
    } else {
      setBackOfficeMeetingId(studio.back_office_meeting_id)
    }

  }, [backOfficeFrame])

  useEffect(() => {
    if (!loading) {
      if (remoteFrame) {
        handleRemoteLoad(meeting_id, 'iframe-wrapper')
      } else {
        handleRemoteUnload('iframe-wrapper')
      }
    }
  }, [key, meeting_id, remoteFrame, backOfficeFrame])

  useEffect(() => {
    if (!loading) {
      if (backOfficeFrame) {
        handleRemoteLoad(backOfficeMeetingId, 'backoffice-iframe-wrapper')
      } else {
        handleRemoteUnload('backoffice-iframe-wrapper')
      }
    }
  }, [key2, backOfficeFrame, backOfficeMeetingId])

  useEffect(() => {
    if (api) {
      const iframe = api.getIFrame()
      iframe.setAttribute('id', 'jitsi-meeting-frame')
    }
  }, [api])

  useEffect(() => {
    if (api2) {
      const iframe = api2.getIFrame()
      iframe.setAttribute('id', 'backoffice-meeting-frame')
    }
  }, [api2])

useEffect(() => {
    if (api && user) {
        const hashConfig = '&config.notifications=[]';
        api.executeCommand('displayName', `${user.first_name} ${user.last_name} (${user.user_type})`);
        const iframe = api.getIFrame();
        let src = iframe.src;

        if (record && studio) {
            const onboardUrl = window.location.href;
            const auditionData = JSON.stringify({
                talent: record,
                studio: studio,
                onboard_url: onboardUrl
            });
            src += `&audition_data=${encodeURIComponent(auditionData)}`;
        }
        if ([USER_TYPES.CLIENT, USER_TYPES.TALENT].includes(user.user_type)) {
            src += hashConfig;
        }

        if (!([USER_TYPES.TALENT].includes(user.user_type))) {
            src += '&config.startWithAudioMuted=true&config.startWithVideoMuted=true';
        }

        iframe.onload = function () {
            if (backOfficeFrame) {
                setTimeout(() => {
                    if (iframe.contentDocument) {
                        const toolboxContent = iframe.contentDocument.querySelector('.toolbox-content');
                        if (toolboxContent) {
                            const buttons = toolboxContent.querySelectorAll('.toolbox-button');
                            buttons.forEach(button => {
                                const ariaLabel = button.getAttribute('aria-label');
                                if (ariaLabel !== 'toolbar.accessibilityLabel.speakermute') {
                                    button.style.display = 'none';
                                }
                            });
                        }
                    }
                }, 1000); // Add a delay to ensure the iframe content is fully loaded
            }
        };

        iframe.setAttribute('src', src);
    }
}, [api, user, backOfficeFrame]);



  useEffect(() => {
    if (api2 && user) {
       
      api2.executeCommand('displayName', `${user.first_name} ${user.last_name} (${user.user_type})`)
      const iframe = api2.getIFrame()
      let src = iframe.src
      
      src += '&config.startWithAudioMuted=true&config.startWithVideoMuted=true'
       
      iframe.setAttribute('src', src)
    }
  }, [api2, user])

  useEffect(() => {
    const u = getUser()
    if (u) {
      getUserById(u.id).then(setUser)
    } else if (record) {
      setUser(record)
    }
    if (document.querySelector('.right-frame')) {
      document.querySelector('.right-frame').addEventListener('scroll', () => {
        let offsetTop = document.querySelector('.right-frame').scrollTop
        const threshold = window.innerHeight - 225
        if (offsetTop > threshold) {
          document.querySelector('#meeting-frame').classList.add('mini-view')
        } else {
          document.querySelector('#meeting-frame').classList.remove('mini-view')
          setHide(false)
        }
      })
    }
    navigator.permissions.query({ name: 'camera' })
      .then((permissionObj) => {
        console.log(permissionObj.state);
        // permission = permissionObj.state;
      })
      .catch((error) => {
        console.log('Got error :', error);
      });
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const result = []
        const resultAudios = []
        devices.forEach((device, idx) => {
          if (device.kind === 'videoinput') {
            result.push({
              id: device.deviceId,
              label: device.label || `Camera #${idx + 1}`
            })
          } else if (device.kind === 'audioinput') {
            resultAudios.push({
              id: device.deviceId,
              label: device.label || `Audio #${idx + 1}`
            })
          }
        });
        setCameras(result)
        setAudios(resultAudios)
        setSelectedCameraId(result[0].id)
        setSelectedAudioId(resultAudios[0].id)
      })
      .catch((err) => {
        console.error(`${err.name}: ${err.message}`);
      });
  }, [])

  useEffect(() => {
    const loaderElem = document.querySelector('.dummy-player-loader')
    if (!loaderElem) { return }
    if (uploading) {
      // loaderElem.classList.remove('visible')
      loaderElem.classList.add('visible')
      setTimeout(() => {
        document.querySelector('.group-videos').scrollTo(99999, 0)
      }, 300)
    } else {
      loaderElem.classList.remove('visible')
      // loaderElem.classList.add('visible')
    }
  }, [uploading])

  const handleRecordClick = () => {
    if (session.currentGroup && !queryParams.test) {
      if (!localRecording) {
        handleStartCaptureClick()
      } else {
        handleStopCaptureClick()
      }
    }
  }

  return (
    <div id="meeting-frame" className={(inPersonFrame || backOfficeFrame) && remoteFrame && focus}>
      {inPersonFrame && (
        <div
          id="local-camera-frame"
          onClick={() => { handleRecordClick() }}
        >
          <Webcam
            ref={webcamRef}
            key={selectedCameraId}
            videoConstraints={{
              width: 1920,
              height: 1080,
              facingMode: "user",
              deviceId: selectedCameraId
            }}
            audioConstraints={{
              deviceId: selectedAudioId
            }}
            onUserMediaError={(err) => {
              console.log('error', selectedCameraId, err)
            }}
            audio={true}
            muted={true}
            className='local-webcam'
          />
          <div className='record-settings' onClick={(ev) => {
            ev.stopPropagation()
            setShowSettings(!showSettings)
          }}>
            <button className='btn btn-sm btn-secondary'>
              <IoMdSettings size={20} />
            </button>
            <div className={`${showSettings ? 'd-block' : 'd-none'} bg-white p-2 rounded-sm mt-2`} onClick={e => e.stopPropagation()}>
              <div className='d-flex align-items-center mb-1'>
                <label className='mb-0 mr-2'>Video: </label>
                <select
                  className='form-control form-control-sm'
                  value={selectedCameraId}
                  onChange={ev => {
                    setSelectedCameraId(ev.target.value)
                  }}
                >
                  <option value={'-1'}>
                    -
                  </option>
                  {cameras.map(cam => {
                    return (
                      <option key={cam.id} value={cam.id}>
                        {cam.label}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className='d-flex align-items-center'>
                <label className='mb-0 mr-2'>Audio: </label>
                <select
                  className='form-control form-control-sm'
                  value={selectedAudioId}
                  onChange={ev => {
                    setSelectedAudioId(ev.target.value)
                  }}
                >
                  {audios.map(aud => {
                    return (
                      <option key={aud.id} value={aud.id}>
                        {aud.label}
                      </option>
                    )
                  })}
                </select>
              </div>
              <button className='btn btn-sm btn-ghost mt-2 w-100' onClick={() => {
                setShowSettings(false)
              }}>
                Done
              </button>
            </div>
          </div>
          {session.currentGroup && !queryParams.test && (
            <div
              key={uploading}
              className={`local-record-btn ${localRecording ? 'recording' : ''}`}
              title={localRecording ? "Recording..." : "Start Record"}
              onClick={(ev) => {
                if (uploading) { return }
                ev.stopPropagation()
                handleRecordClick()
              }}
            >
              <strong>{uploading ? "Uploading" : "REC"}</strong>
              {uploading
                ? <span className='local-upload-progress'></span>
                : <div size={20} className='rec-icon' />}
            </div>
          )}
        </div>
      )}
      <div id="jitsi-frame" className={classNames("no-print ", {
      'hide-frame' : hide,
      'd-none': !remoteFrame
    })}>
      <button
        id="reload-jitsi"
        title="Reload Meeting frame"
        onClick={() => { setKey(key + 1) }}
      >⟳</button>
      <div id="iframe-wrapper">
        <FaTimes className='text-danger h5' id="close" onClick={() => {
          setHide(true)
        }} />
      </div>
    </div>
    <div id="backoffice-jitsi-frame" className={classNames("no-print ", {
      'hide-frame' : hide,
      'd-none': !backOfficeFrame
    })}>
      <button
        id="reload-jitsi"
        title="Reload Backoffice frame"
        onClick={() => { setKey2(key + 1) }}
      >⟳</button>
      <div id="backoffice-iframe-wrapper">
        <FaTimes className='text-danger h5' id="close" onClick={() => {
          setHide(true)
        }} />
      </div>
    </div>
  {(inPersonFrame || backOfficeFrame) && remoteFrame && !queryParams.test && (
  <div className='local-camera-view-toggle' onClick={ev => { ev.stopPropagation() }}>
    <div
      className={'toggle-button ' + (focus == FRAME_FORCUS.REMOTE ? 'active' : '')}
      onClick={() => { setFocus(FRAME_FORCUS.REMOTE) }}
      title="Session"
    >
      <span className='remote'>
        <FaCamera size={20} />
      </span>
    </div>
    <div
      className={'toggle-button ' + (focus == FRAME_FORCUS.BOTH ? 'active' : '')}
      onClick={() => { setFocus(FRAME_FORCUS.BOTH) }}
      title={`${backOfficeFrame ? 'Back Office' : 'Session'} and Remote`}
    >
      <span className='remote'>
        <FaCamera />
      </span>
      <span className='local'>
        <FaDesktop />
      </span>
    </div>
    <div
      className={'toggle-button ' + (focus == FRAME_FORCUS.LOCAL ? 'active' : '')}
      onClick={() => { setFocus(FRAME_FORCUS.LOCAL) }}
      title={backOfficeFrame ? "Back Office" : "Session"}
    >
      <span className='local'>
        <FaDesktop size={20} />
      </span>
    </div>
  </div>
)}

{/* Frame elements with conditional rendering */}
{focus === FRAME_FORCUS.REMOTE && (
  <div className="frame-content">
    {/* Remote frame content here */}
  </div>
)}
{focus === FRAME_FORCUS.BOTH && (
  <div className="frame-content">
    {/* Both frames content here */}
  </div>
)}
{focus === FRAME_FORCUS.LOCAL && (
  <div className="frame-content">
    {/* Local frame content here */}
  </div>
)}


      {!inPersonFrame && !remoteFrame && (
        <div className='w-100 d-flex align-items-center justify-content-center'>
          <div className='h4 text-center'>
            This session is not scheduled for today. To allow users to check-in today,<br/>please&nbsp;
            <a className='text-danger' href={`/session/${studio?.uri}/edit/${session._id}`}>add a date</a>
          </div>
        </div>
      )}
    </div>
  )
}

export default MeetFrame
