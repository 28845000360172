import { useCallback, useEffect, useState } from "react";
import { getUserAllAppointments } from "../../../services";
import { useAuthedUser } from "../../../hooks/auth";
import moment from 'moment-timezone';

const useProfile = () => {
    const [userAppointments,setUserAppointments] = useState([])
    const [timezoneAbbr, setTimezoneAbbr] = useState("");

    const { id: talent_id } = useAuthedUser();

    const loadAppointment = useCallback(async ({talent_id})=>{
        const resp = await getUserAllAppointments({talent_id})
        setUserAppointments(resp)
      },[])

      useEffect(()=>{
        if(talent_id){
            loadAppointment({talent_id})
        }
        
      },[talent_id])

  useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneAbbr = moment.tz(userTimezone).format('z');
    setTimezoneAbbr(timezoneAbbr);
  }, []);

      return {
        userAppointments,
        timezoneAbbr
      }
}
export default useProfile