import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import { removePostingLink } from '../../services'
import { update as updateStudioInStore } from '../../store/studios'


import { LinkIcon, SmallTrashIcon } from '../../assets/icons/session'
import './style.scss'
import PostingLinkModal from './PostingLinkModal'
import SendPostingLinkEmailModal from './SendPostingLinkEmailModal'

export default ({ studio, postingLink }) => {
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
  const [showPostingLinkModal, setShowPostingLinkModal] = useState(false)
  const [showEmailModal, setShowEmailModal] = useState(false)

  const host = window.location.origin
  const dispatch = useDispatch()


  const onConfirmDelete = () => {
    removePostingLink(studio._id, postingLink._id).then(res => {
      const idx = studio.posting_links.findIndex(it => it._id === postingLink._id)
      const posting_links = [...studio.posting_links]
      posting_links.splice(idx, 1)
      const temp = { ...studio }
      temp.posting_links = posting_links
      dispatch(updateStudioInStore(temp))
      setShowDeleteConfirmModal(false)
    })
  }

  return (
    <div key={postingLink._id} className="posting-item d-flex align-items-center justify-content-between">
      <span className='h-14-600 ls-2 mr-1'>{postingLink.name}</span>
      <div className="posting-item-right d-flex align-items-center">
        <button
          title='Share Link'
          className='action-button h-12-600'
          onClick={() => { setShowEmailModal(true) }}
        ><LinkIcon /> <span>Share Link</span></button>
        <Link to={`/posting-link-page/${studio.uri}/${postingLink._id}`} className="action-button h-12-600" target="_blank">
          View
        </Link>
        <div className="d-flex align-items-center action-wrap">
          <button
            className='action-button h-12-600'
            onClick={() => { setShowPostingLinkModal(true) }}>Edit</button>
          <button
            className='action-button h-12-600'
            onClick={() => { setShowDeleteConfirmModal(true) }}><SmallTrashIcon /></button>
        </div>
      </div>
      <Modal
        show={showDeleteConfirmModal}
        onHide={() => { setShowDeleteConfirmModal(false) }}
      >
        <Modal.Header>
          <h4>Want to delete Posting Page "{postingLink.name || 'Posting Link'}"?</h4>
        </Modal.Header>
        <Modal.Footer>
          <Button
            className="btn-w-md"
            variant="danger"
            onClick={onConfirmDelete}
          >
            Yes
          </Button>
          <Button
            variant="light"
            className="btn-w-md"
            onClick={() => { setShowDeleteConfirmModal(false) }}
          >
            Cancel
          </Button>
        </Modal.Footer>

      </Modal>
      <PostingLinkModal
        studio={studio}
        show={showPostingLinkModal}
        onHide={() => { setShowPostingLinkModal(false) }}
        postingLink={postingLink} />
      <SendPostingLinkEmailModal
        show={showEmailModal}
        onHide={() => {
          setShowEmailModal(null)
        }}
        studio={studio}
        emailSessionParams={postingLink}
        emailSessionLink={`${host}/posting-link-page/${studio.uri}/${postingLink._id}`}
      />
    </div>
  )
}