import { createSlice } from '@reduxjs/toolkit'

export const freelancerRequestSlice = createSlice({
  name: 'freelancerRequests',
  initialState: {
    requests: []
  },
  reducers: {
    set: (state, action) => {
      state = action.payload
      return state
    },
    add: (state, action) => {
      state = [...state, action.payload]
      return state
    },
    update: (state, action) => {
      const idx = state.requests.findIndex(it=>it._id === action.payload._id)
      if (idx !== -1) {
        const temp = [...state.requests]
        temp[idx] = action.payload
        state.requests = temp
      } else {
        state.requests = [...state.requests, action.payload]
      }
      
      return state
    }
  }
})
export const { set, add, update } = freelancerRequestSlice.actions

export default freelancerRequestSlice.reducer