import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, FormControl, Accordion, Container, Image, Button, Modal, Form } from 'react-bootstrap'
import { FaThumbsUp, FaThumbsDown, FaMinus, FaQuestion, FaRegGrinBeam, FaRegFrown } from 'react-icons/fa'
import _ from 'lodash'
import moment from 'moment'
import FreelancerComment from '../../../components/FreelancerComment/index'
import Pagination from '../../../components/Pagination'
import { set as setRequests, update as updateRequestStore, } from '../../../store/freelancerRequests'
import { listRequests, apiBookFreelancer, static_root, unBookFreelancer } from '../../../services'
import { userList } from '../../../utils'
import { joinRoom, leaveRoom } from '../../../ws'
import defaultLogo from '../../../assets/heyjoe-white.png'
import FreelancerItem from './FreelancerItem'
import './style.scss'
import ThumbImage from "../../../components/ThumbImage"

const responses = ['yes', 'second-hold', undefined, 'no']
const cpReq = (a, b) => {
  return responses.indexOf(a.response) - responses.indexOf(b.response)
}

const PAGE_SIZE = 10

export const Request = ({ request, profile, session, refreshData, isExpanded }) => {
  const [response, setResponse] = useState(request.response)
  const [showBookModal, setShowBookModal] = useState(false)
  const [showUnbookModal, setShowUnbookModal] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [submitData, setSubmitData] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    if (request && request._id) {
      joinRoom(`request-${request._id}`)
      document.body.addEventListener('ws-message', wsMessageHandler)
    }
    return () => {
      leaveRoom(`request-${request._id}`)
      document.body.removeEventListener('ws-message', wsMessageHandler)
    }
  }, [request && request._id])

  const onUnbookClick = async () => {
    await unBookFreelancer(request._id)
    refreshData && refreshData()
    setShowUnbookModal(false)
  }

  const wsMessageHandler = (ev) => {
    const event = ev.detail
    switch (event.type) {
      case 'set-freelancer-response':
        if (event.request === request._id) {
          setResponse(event.response)
        }
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (!showBookModal) {
      setSubmitData({})
    }
  }, [showBookModal])

  const onBookSubmit = async () => {
    const roles = []
    apiBookFreelancer(request._id, {
      status: 'book',
      requested_person: request.requested_person._id,
      session: session._id,
      dates: submitData
    }).then(res => {
      refreshData && refreshData()
    })
    setShowBookModal(false)
  }

  const onRejectBtnClick = () => {
    apiBookFreelancer(request._id, {
      status: 'reject'
    }).then(res => {
      refreshData && refreshData()
    })
  }

  const toggleDateRole = (dateId, role) => {
    const roles = Object.assign([], submitData[dateId])
    const rIdx = roles.findIndex(r => r === role)
    if (rIdx === -1) {
      roles.push(role)
    } else {
      roles.splice(rIdx, 1)
    }
    setSubmitData(({
      ...submitData,
      [dateId]: roles
    }))
  }

  const sessionDates = session.dates

  const canBook = Object.values(submitData).reduce((r, i) => r.concat(i), []).filter(i => !!i).length > 0

  return (
    <div className="mb-2">
      <Accordion.Toggle
        as="div"
        eventKey={request._id}
        className='cursor-pointer border bg-light rounded' 
      >
        <Container fluid>
          <div className="d-flex align-items-center">
            <div md={'auto'}>
              <ThumbImage
                src={request.requested_person.logo}
                className="header-logo my-2"
              />
            </div>
            <div className='ml-3 name-col'>
              <h5 className="my-0">
                {request.requested_person.first_name} {request.requested_person.last_name}
              </h5>
              <div className="text-danger text-capitalize">
                {request.requested_person.freelancer_profile.will_work_as.map(it => it.split('_').join(' ')).join(', ')}
              </div>
              <span>({request.requested_person.email})</span>
            </div>
            <h6 className="mb-0 ml-5 timezone-col">
              {_.get(request, 'requested_person.freelancer_profile.timezone', '')}
            </h6>
            <div
              className="text-center ml-auto booked-on-col mr-4"
              title={response || 'Not responded yet'}
            >
              {profile.booked_dates.length > 0 && (
                <div className='mt-2'>
                  {`${profile.booked_dates.map(d => moment(new Date(d)).format('MM/DD')).join(',')}`}
                </div>
              )}
            </div>
            <div
              className="text-center freelancer-response-col mr-4"
              title={response || 'Not responded yet'}
            >
              {(() => {
                if (response === 'yes') {
                  return (
                    <FaThumbsUp
                      size={20}
                      color="#fe0923"

                    />
                  )
                } else if (response === 'no') {
                  return (
                    <FaThumbsDown
                      size={20}
                      color="#fe0923"

                    />
                  )
                } else if (response === 'second-hold') {
                  return (
                    <FaMinus
                      size={20}
                      color="#fe0923"
                      title="Second Hold"
                    />
                  )
                } else {
                  return (
                    <FaQuestion
                      size={20}
                      color="#fe0923"
                    />
                  )
                }
              })()}
            </div>
            <div
              className="text-center book-status-col"
              title={request.status || 'Not determined'}
            >
              {(() => {
                if (request.status === 'book') {
                  return (
                    <FaRegGrinBeam
                      size={20}
                      color="#fe0923"
                      title="Book"
                    />
                  )
                } else if (request.status === 'reject') {
                  return (
                    <FaRegFrown
                      size={20}
                      color="#fe0923"
                      title="Reject"
                    />
                  )
                } else {
                  return (
                    <FaQuestion
                      size={20}
                      color="#fe0923"
                      title="Not determined"
                    />
                  )
                }
              })()}
            </div>
            <div className="ml-3 action-col">
              {request.status === 'book' ? (
                <Button
                  variant="danger"
                  onClick={(ev) => {
                    ev.stopPropagation()
                    setShowUnbookModal(true)
                  }}
                >
                  Unbook
                </Button>
              ) : (
                <Button
                  variant="danger"
                  onClick={(ev) => {
                    ev.stopPropagation()
                    if (sessionDates.length === 1) {
                      setSubmitData({
                        [sessionDates[0]._id]: []
                      })
                    }
                    setShowBookModal(true)
                  }}
                >
                  Book
                </Button>
              )}
            </div>
          </div>
        </Container>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={request._id}>
        <div className="bg-white border border-top-0">
          <div className="d-flex">
            <div className="w-50">
              <div className="px-4 py-2">
                <FreelancerItem freelancer={request.requested_person} />
              </div>
              {request.status === 'book' && (
                <Button
                  variant="danger" className="px-4 ml-3 mb-2"
                  onClick={() => { setShowUnbookModal(true) }}
                >
                  Unbook
                </Button>
              )}
              {!request.status && (
                <div className="d-flex mb-2 ml-auto px-3 py-2">
                  <Button
                    variant="danger"
                    className="px-4 mr-3"
                    onClick={() => {
                      if (sessionDates.length === 1) {
                        setSubmitData({
                          [sessionDates[0]._id]: []
                        })
                      }
                      setShowBookModal(true)
                    }}
                  >
                    Book
                  </Button>
                  <Button
                    variant="light"
                    className="px-4"
                    onClick={() => { setShowRejectModal(true) }}
                  >
                    Reject
                  </Button>
                </div>
              )}
            </div>
            <div className="p-3 w-50">
              {isExpanded && (
                <FreelancerComment request={request} />
              )}
            </div>
          </div>
        </div>
      </Accordion.Collapse>
      <Modal
        show={showBookModal}
        onHide={() => { setShowBookModal(false) }}
      >
        <div id={`book-form-${request._id}`} className="d-flex flex-column h-100">
          <Modal.Header>
            <div>
              <h5 className="my-0">Book Session Managers</h5>
              <div>{session.name}</div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Select Date</Form.Label>
              <div className="">
                {sessionDates.map(it => {
                  return (
                    <div key={it._id}>
                      <label className="d-flex align-items-center mb-1 mr-3">
                        <input
                          type='checkbox'
                          name='date'
                          value={it._id}
                          className="mr-1"
                          checked={!!submitData[it._id]}
                          onChange={ev => {
                            if (ev.target.checked) {
                              setSubmitData({
                                ...submitData,
                                [it._id]: []
                              })
                            } else {
                              setSubmitData({
                                ...submitData,
                                [it._id]: null
                              })
                            }
                          }}
                        />
                        {moment(new Date(it.start_time)).format('MM/DD')} {it.book_status} {it.start_time_type}
                      </label>
                      {submitData[it._id] && (
                        <div className="d-flex ml-3 flex-wrap">
                          <label className="d-flex align-items-center mb-1 mr-3 flex-wrap">
                            <input
                              disabled={it.managers && it.managers.length > 0}
                              type='checkbox' name='role' value='session_runner' className="mr-1"
                              checked={submitData[it._id].includes('session_runner')}
                              onChange={ev => { toggleDateRole(it._id, 'session_runner') }}
                            />
                            <span>Session Runner</span>
                            {it.managers && it.managers.length > 0 && (
                              <div className="text-danger ml-4">
                                Already assigned to <strong>{userList(it.managers)} </strong>
                              </div>
                            )}
                          </label>
                          <label className="d-flex align-items-center mb-1 mr-3 flex-wrap">
                            <input
                              disabled={it.lobbyManager && it.lobbyManager.length > 0}
                              type='checkbox' name='role' value='lobby_manager' className="mr-1"
                              checked={submitData[it._id].includes('lobby_manager')}
                              onChange={ev => { toggleDateRole(it._id, 'lobby_manager') }}
                            />
                            <span>Lobby Manager</span>
                            {it.lobbyManager && it.lobbyManager.length > 0 && (
                              <div className="text-danger ml-4">
                                Already assigned to <strong>{userList(it.lobbyManager)} </strong>
                              </div>
                            )}
                          </label>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </Form.Group>
            <p><strong>***If this is your first time working with this Freelancer, please remember to confirm their rate.</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              variant="light"
              onClick={() => { setShowBookModal(false) }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="danger"
              disabled={!canBook}
              onClick={() => { onBookSubmit() }}
            >
              Book
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
      <Modal show={showRejectModal} onHide={() => { setShowRejectModal(false) }} >
        <Modal.Header>
          <h5> Are you sure want to reject {request.requested_person.first_name} {request.requested_person.last_name}? </h5>
        </Modal.Header>
        <Modal.Footer>
          <Button type="button" variant="light" onClick={() => { setShowRejectModal(false) }} >
            Cancel
          </Button>
          <Button type="submit" variant="danger" onClick={() => { onRejectBtnClick() }} >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showUnbookModal} onHide={() => { setShowUnbookModal(false) }} >
        <Modal.Header>
          <h5> Are you sure want to unbook {request.requested_person.first_name} {request.requested_person.last_name}? </h5>
        </Modal.Header>
        <Modal.Footer>
          <Button type="button" variant="light" onClick={() => { setShowUnbookModal(false) }} >
            Cancel
          </Button>
          <Button type="submit" variant="danger" onClick={() => { onUnbookClick() }} >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
