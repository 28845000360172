import React, { forwardRef } from "react";
import Avatar from "react-avatar";

import { static_root } from "../../../../services";
import { InstaLogo, TikTokLogo } from "../../../../assets/icons/session";
import "./styles.scss";

const TalentInfoPrintCard = forwardRef(
  (
    {
      agent,
      agent_email,
      agent_phone,
      email,
      first_name,
      height,
      last_name,
      logo,
      phone,
      role,
      hideContact,
      photos, 
      tiktok_handle,
      instagram_handle,
      videos, 
      hideCommentSection,
      cmts,
      clothesType,
      sizeObject
    },
    ref
  ) => {
    return (
      <div className="talent-print-card" ref={ref}>
        <div className="print-header d-flex flex-column">
          <h5 className="mb-1">{`${first_name} ${last_name}`}</h5>
          <div className="h-14-400 secondary-color">
            Role: <span className="role">{role}</span>
          </div>
        </div>
        <div className="print-body">
        <div className="print-left" style={{ width: hideCommentSection ? '100%' : '66%'}}>
          <div className="talent-info-top">
            {logo ? (
              <img
                className="talent-avatar"
                src={`${static_root}${logo}`}
                alt="avatar"
              />
            ) : (
              <img
                className="talent-avatar"
                src={require("../../../../assets/camera.png")}
                alt="avatar"
              />
            )}
            {!hideContact && (
              <div className="top-right">
                <div className="detail-container">
                  <span className="h-12-600">Phone</span>
                  <span className="h-14-400">{phone}</span>
                </div>
                <div className="detail-container">
                  <span className="h-12-600">Email</span>
                  <span className="h-14-400">{email}</span>
                </div>
              </div>
            )}
          </div>
          {clothesType && <div className="size-wrapper">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <span className="h-14-400">I wear clothes for:</span>
                <span className="h-14-600">
                  &nbsp;{clothesType[0].toUpperCase() + clothesType.slice(1)}
                </span>
              </div>
            </div>
            <div className="size-container-grid mt-4">
              {sizeObject && Object.keys(sizeObject).length > 0 &&
                Object.keys(sizeObject).map(key => (
                  <div className="d-flex flex-column mb-2" key={key}>
                    <span className="h-12-600">{key[0].toUpperCase() + key.slice(1)} Size</span>
                    <span className="h-14-400">{sizeObject[key].label}</span>
                  </div>
                ))
              }
            </div>
          </div>}
          <div className="talent-info-bottom">
            <div className="item1 detail-container">
              <span className="h-12-600">Socials</span>
              <div className="d-flex align-items-center mt-2">
                <span className="social-link">
                  <InstaLogo />
                  {instagram_handle ? (
                    <span>
                      {instagram_handle}
                    </span>
                  ) : (
                    "-"
                  )}
                </span>
                <span className="social-link ml-2">
                  <TikTokLogo />
                  {tiktok_handle ? (
                    <span>
                      {tiktok_handle}
                    </span>
                  ) : (
                    "-"
                  )}
                </span>
              </div>
            </div>
            <div className="detail-container item2">
              <span className="h-12-600">Height</span>
              <span className="h-14-400">
                {height
                  ? `${height.ft}' ${height.inches}"`
                  : "-"}
              </span>
            </div>
            <div className="detail-container item3">
              <span className="h-12-600">Agent</span>
              <span className="h-14-400">{agent ? agent : "-"}</span>
            </div>

            <div className="detail-container item4">
              <span className="h-12-600">Agent Email</span>
              <span className="h-14-400">
                {agent_email ? agent_email : "-"}
              </span>
            </div>

            <div className="detail-container item5">
              <span className="h-12-600">Agent Phone</span>
              <span className="h-14-400">
                {agent_phone ? agent_phone : "-"}
              </span>
            </div>
          </div>
          {photos && photos.length > 0 && (
            <div className="media-container mt-3">
              <h5 className="h-14-700 primary-color mb-0">Headshots</h5>
              <div className="image-container">
                {photos?.map((headshot, index) => {
                  return (
                    <img
                      key={index}
                      className="headshot"
                      src={`${static_root}${headshot}`}
                      alt="headshot"
                      style={{objectFit: 'cover'}}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {videos && videos.length > 0 && (
            <div className="media-container mt-3">
              <h5 className="h-14-700 primary-color mb-0">Videos</h5>
              <div className="image-container">
                {videos?.map((video, index) => {
                  return (
                    <img
                      key={index}
                      className="video"
                      src={`${static_root}${video}`}
                      alt="headshot"
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {!hideCommentSection && (
          <div className="print-right">
            <h5 className="h-14-700 primary-color mb-0">Comments</h5>

            <div className="note-container">
              
              {cmts &&
                cmts.map((comment, index) => {
                  return (
                    <div
                      className="note-item d-flex aling-items-center p-1"
                      key={index}
                    >
                      <div className="left">
                        <Avatar
                          className="participantAvatar"
                          name={
                            comment?.by?.first_name +
                            " " +
                            comment?.by?.last_name
                          }
                          size="32px"
                          round="32px"
                          textSizeRatio={2}
                          // @ts-ignore
                          title={false}
                        />
                      </div>
                      <div className="ml-2 right">
                        <div className="comment-author d-flex align-items-center ">
                          <span className="h-12-600">
                            {comment?.by?.first_name +
                              " " +
                              comment?.by?.last_name}
                          </span>
                        </div>
                        <span className="h-14-400">{comment?.content}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        </div> 
      </div>
    );
  }
);

export default TalentInfoPrintCard;
