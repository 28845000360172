/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import Switch from "react-switch"
import { FaDownload, FaArchive, FaTeethOpen, FaPenAlt, FaTrash, FaPrint, FaSpinner } from 'react-icons/fa';
import {
  static_root,
  getStudioByUri,
  deletePageVideo,
  getOnePage,
  getPageVideos,
  updatePostingVideo,
  updatePostingManyVideo,
  createZipAndSendMail,
  uploadNewPostingVideo,
  updatePostingGroup,
  updatePostingGroupOrder,
  updatePostingVideoOrder,
  twrGetOneRecord,
  twrGetOneHeyjoeRecord,
  addPostingVideoEntry,
  directUpload,
  getUser,
  getPagesByStudio,
  createPage,
  copyGroupFromSession,
} from '../../services'
import Footer from '../../components/Footer'
import './style.scss'
import ReactPlayer from 'react-player'
import GroupSorter from './GroupSorter'
import { saveAs } from 'file-saver'
import { POSTINGPAGE_PERMISSIONS, USER_TYPE } from '../../constants'
import ReportPage from './report'
import PersonCardV from './FbkC';
import { listPageFeedbacks } from '../../services/feedback';
import { joinRoom, leaveRoom } from '../../ws';
import { ArchiveIcon, EditIconSmall, SortIcon } from '../../assets/icons/session';
import { TitleContext } from '../../Context'
import InlineComments from './InlineComments';
import CopyModal from '../../components/CopyPagesModal';

const itemWidth = 250
const thumbWidth = 150

const TABS = {
  VIDEOS: 'Videos',
  ARCHIVED: 'Archived'
}

class PostingPage extends Component {
  static contextType = TitleContext
  constructor(props) {
    super(props)

    this.state = {
      studio: null,
      page: null,
      countPerRow: 5,
      activeRidx: -1,
      activeGidx: -1,
      activeItem: null,
      videos: [],
      groups: [],
      records: {},
      loading: false,
      videoDates: [],
      selectedForUploads: [],
      groupRecords: [],
      archivedVideos: [],
      tab: TABS.VIDEOS,
      selectedGroup: {},
      twrCandidates: [],
      twrGroupRecords: [],
      twrStudio: '',
      filter: 'all',
      roleFilter: 'all',
      pageFeedbacks: [],
      showAvatar: false,
      currentPostingLink: null,
      userFilter: 'all',
      user: {},
      showCommentInline: false,
      showCDCommentInline: false,
      showPageCopyModal: false,
      newPostingPage: {},
      postingPages: [],
    }
  }

  setCount = () => {
    this.setState({
      countPerRow: Math.max(1, parseInt((document.documentElement.clientWidth - 96) / (itemWidth + 32)))
    })
  }

  setFilter = (v) => { this.setState({
    filter: v,
    activeRidx: -1,
    activeGidx: -1,
    activeItem: null
  }) }
  setRoleFilter = (v) => { this.setState({
    roleFilter: v,
    activeRidx: -1,
    activeGidx: -1,
    activeItem: null
  }) }
  setUserFilter = (v) => { this.setState({
    userFilter: v,
    activeRidx: -1,
    activeGidx: -1,
    activeItem: null
  }) }

  setShowCommentInline = (v) => {
    this.setState({
      showCommentInline: v
    })
  }
  setShowCDCommentInline = (v) => {
    this.setState({
      showCDCommentInline: v
    })
  }

  setShowPageCopyModal = (value) => {
    this.setState({
      showPageCopyModal: value,
    });
  }

  setNewPostingPage = (pp) => {
    this.setState({
      newPostingPage: pp
    })
  }

  loadPostingPages = async () => {
    const pages = await getPagesByStudio(this.state.studio._id)
    this.setState({
      postingPages: pages,
    })
  }

  handleGroupsCopy = async () => {
    this.setState({
      loading: true,
      showPageCopyModal: false
    })

    let sendLink = false;

    if(USER_TYPE.IS_SUPER_ADMIN()){
      sendLink = document.querySelector('#send-audition-link').checked
    }
    
    let selectedPage = '' 
    if(!this.state.newPostingPage._id){
      const res = await createPage({
        name: this.state.newPostingPage.name,
        studio: this.state.studio._id
      })
      selectedPage = res._id
      this.setNewPostingPage(res)
      
      await this.loadPostingPages(this.state.studio._id)
    } else {
      selectedPage = this.state.newPostingPage._id
    }
    const selectedGroups = this.state.groups.filter((group, idx) => this.groupSelectedForDownload(idx))

    for (let i = 0; i < selectedGroups.length; i++) {
      const group = selectedGroups[i]
      await copyGroupFromSession(group.videoReivewGroupId, selectedPage, sendLink)
      await this.loadVideos()
    }

    this.setState({
      loading: false,
      selectedForUploads: [],
    })
  }

  fetchTWRCandidates = async (twr_ids, session_id) => {
    let candidates = await Promise.all(twr_ids.map(async tid => {
      return await twrGetOneRecord(tid)
    }))
    const heyjoeCandidates = await Promise.all(twr_ids.map(async tid => {
      return await twrGetOneHeyjoeRecord(tid, session_id)
    }))
    candidates = candidates.map((c, idx) => {
      const hc = heyjoeCandidates.find(h => h.twr_id === c._id)
      return {
        ...c,
        ...hc,
        number: idx + 1,
        _id: c._id,
        twr_id: c._id,
      }
    })
    return candidates
  }

  loadVideos = async (preventRepeat = false) => {
    this.setState({
      loading: true
    })
    const videos = await getPageVideos(this.page_id, this.state.tab === TABS.ARCHIVED)
    let groups = [], gidx = {}, idx = 0
    videos.forEach(video => {
      let groupName =''
      if (!video.group) {
        video.group = {}
      }
      if (video.group.name && !video.group.name.includes('reserved field')) {
        groupName = video.group.name
      } else{
        groupName = video.group ? video.group.records.map(r => `${r.first_name} ${r.last_name}`).join(',') : ''
      }
      if (isNaN(gidx[video.group._id])) {
        gidx[video.group._id] = idx
        groups[gidx[video.group._id]] = {
          _id: video.group._id,
          name: groupName || 'Unknown',
          order: video.group.order,
          idx,
          url: video.url,
          thumbnail: video.group.thumbnail || video.thumbnail,
          videos: [],
          records: [],
          videoReivewGroupId: video.group.videoReivewGroupId
        }
        idx++
      }
      groups[gidx[video.group._id]].videos.push(video)
      video.group.records.forEach(r => {
        const addedIds = groups[gidx[video.group._id]].records.map(item => item._id)
        if (!addedIds.includes(r._id)) {
          groups[gidx[video.group._id]].records.push(r)
        }
      })
    })
    groups = groups.sort((g1, g2) => g1.order - g2.order)
    let needReorder = false
    let gids = []
    groups.forEach((g, idx) => {
      groups[idx].idx = idx
      gids.push(g._id)
      if (g.order !== idx + 1) {
        needReorder = true
      }
    })
    if (!preventRepeat && needReorder) {
      await updatePostingGroupOrder(gids)
      await this.loadVideos(true)
    } else {
      this.setState({
        videos,
        groups,
        loading: false
      })
    }
  }

  loadFeedbacks = async () => {
    const res = await listPageFeedbacks({page_id: this.page_id})
    const nullFeedBackRemoved = res.filter(f => f.feedback) //remove null feedback
    this.setState({
      pageFeedbacks: nullFeedBackRemoved
    })
  }

  downloadAllVideos = () => {
    const { selectedForUploads, page } = this.state
    const email = window.prompt(
      `You are downloading ${selectedForUploads.length} videos.\nSpecify your email address to get download link`,
      window.localStorage.getItem('email')
    )
    if (!email) {
      return
    }
    createZipAndSendMail(selectedForUploads, page.name, email, true)
      .then(() => {
        alert(`You will get an email with the download link once the archive is completed`)
        this.setState({
          selectedForUploads: []
        })
      })
  }

  downloadOneVideo = (video) => {
    saveAs(video.url, video.uri)
  }

  handleGroupItemClick = async (ridx, gidx) => {
    let twrGroupRecords = []
    if (this.state.groups[gidx]) {
      const group = this.state.groups[gidx].videos[0].group
      if (group.twr_records) {
        this.setState({ loading: true })
        twrGroupRecords = await this.fetchTWRCandidates(group.twr_records, group.session)
      }
    }
    if (gidx === this.state.activeGidx) {
      this.setState({
        activeRidx: -1,
        activeGidx: -1,
        activeItem: null,
        loading: false
      })
    }
    const group = this.state.groups[gidx].videos[0].group
    this.setState({
      activeRidx: ridx,
      activeGidx: gidx,
      activeItem: this.state.groups[gidx].videos[0],
      groupRecords: (group && group.records) || [],
      twrGroupRecords,
      loading: false
    })
  }

  groupSelectedForDownload = (gidx) => {
    const { selectedForUploads } = this.state
    return !this.state.groups[gidx].videos.filter(v => !selectedForUploads.includes(v.uri)).length
  }

  toggleGroupSelectedForDownload = (gidx, checked) => {
    const { selectedForUploads } = this.state
    const newUploads = Object.assign([], selectedForUploads)
    this.state.groups[gidx].videos.forEach(v => {
      const vIdx = newUploads.findIndex(s => s === v.uri)
      if (checked && !newUploads.includes(v.uri)) { newUploads.push(v.uri) }
      if (!checked && newUploads.includes(v.uri)) { newUploads.splice(vIdx, 1) }
    })
    this.setState({
      selectedForUploads: newUploads
    })
  }

  toggleVideoSelectedForDownload = (uri, checked) => {
    const { selectedForUploads } = this.state
    const newUploads = Object.assign([], selectedForUploads)
    const vIdx = selectedForUploads.findIndex(s => s === uri)
    if (checked && !newUploads.includes(uri)) { newUploads.push(uri) }
    if (!checked && newUploads.includes(uri)) { newUploads.splice(vIdx, 1) }
    this.setState({
      selectedForUploads: newUploads
    })
  }

  changeTab = (tab) => {
    if (this.state.tab === tab) { return }
    this.setState({
      tab,
      activeRidx: -1,
      activeGidx: -1
    }, this.loadVideos)
  }

  handleArchiveVideo = async (video_id, archive) => {
    await updatePostingVideo(video_id, { is_archived: archive })
    this.loadVideos()
  }

  handleGroupArchive = async (video_ids, archive, videoReivewGroupId) => {
    await updatePostingManyVideo(video_ids, { is_archived: archive }, videoReivewGroupId)
    await this.loadVideos()
  }

  handleVideoDelete = async (video_id) => {
    const result = window.confirm(`Are you sure?`)
    if (result) {
      await deletePageVideo(video_id)
      this.loadVideos()
    }
  }

  uploadNewVideo = async (file) => {
    this.setState({ loading: true })
    const activeGroup = this.state.groups[this.state.activeGidx]

    const { studio } = this.state
    const res = await directUpload(file, studio.jitsi_meeting_id)
    await addPostingVideoEntry(res.Key, activeGroup._id, this.page_id)

    // await uploadNewPostingVideo(file, this.page_id, activeGroup._id)
    this.loadVideos()
  }

  updateGroupOrder = async (orderdGroup) => {
    this.setState({ loading: true })
    await updatePostingGroupOrder(orderdGroup.map(g => g._id))
    this.loadVideos()
  }

  updateVideoOrder = async (orderedVideo) => {
    this.setState({ loading: true })
    await updatePostingVideoOrder(orderedVideo.map(v => v._id))
    this.loadVideos()
  }

  wsMessageHandler = (event) => {
    try {
      const ev = event.detail
      switch (ev.type) {
        case 'feedback-page':
          this.loadFeedbacks()
      }
    } catch (err) { }
  }

 componentWillUnmount() {
      // Ensure WebSocket events are cleaned up
      leaveRoom(`feedback-page-${this.page_id}`);
      document.body.removeEventListener('ws-message', this.wsMessageHandler);

      // Clear any intervals
      if (this.intervalHandle) {
        clearInterval(this.intervalHandle);
      }

      // Cancel any pending async tasks (like fetches)
      this.isUnmounted = true;
    }

    async fetchTWRCandidates() {
      if (this.isUnmounted) return;
      // Your fetch logic here...
    }


  async componentDidMount() {
    this.setCount()

    const { setTitle } = this.context
    
    const postinglink_id = this.props.match.params.postinglink_id

    this.page_id = this.props.match.params.postingpage_id
    const studio_uri = this.props.match.params.uri
    const studio = await getStudioByUri(studio_uri)
    const page = await getOnePage(this.page_id)
    
    const _currentPostingLink = studio.posting_links.find(link => link._id === postinglink_id)
    this.setState({currentPostingLink: _currentPostingLink})
    if(_currentPostingLink) {
      setTitle(_currentPostingLink.name)
    }
    if (!studio) { return }
    document.title = `${studio.name} - ${page.name} Posting Page`;
    if (studio.logo) {
      document.querySelector('#header-logo').innerHTML = `<img src="${static_root + studio.logo}" class="header-logo" />`
    }

    const user = getUser()
    this.setState({
      studio,
      page,
      user
    }, async () => {
      await this.loadVideos()
      await this.loadFeedbacks()
      await this.loadPostingPages()
    })

    window.addEventListener('resize', this.setCount)

    joinRoom(`feedback-page-${this.page_id}`)
    document.body.addEventListener('ws-message', this.wsMessageHandler)
  }

  componentDidUpdate() {
    if (this.state.activeItem && this.played !== this.state.activeItem.uri) {
      this.played = this.state.activeItem.uri
      setTimeout(() => {
        const video = document.querySelector('#active-player video')
        if (video) {
          try {
            video.play()
          } catch (err) {
            console.log("Video play interrupted.")
          }
          video.addEventListener('ended', () => {
            const activeGroup = this.state.groups[this.state.activeGidx]
            const currentTabVideos = activeGroup.videos
              .filter(v => (!!v.is_archived === (TABS.ARCHIVED === this.state.tab)))
            const nextVideoIdx = currentTabVideos.findIndex(v => v.uri === this.state.activeItem.uri) + 1
            if (nextVideoIdx < currentTabVideos.length) {
              setTimeout(() => {
                this.setState({
                  activeItem: currentTabVideos[nextVideoIdx]
                })
              }, 1200)
            }
          })
        }
      }, 1000)
    }
  }

  filterGroupFunction = () => {
    const { groups, filter, roleFilter, userFilter, pageFeedbacks } = this.state
    const filteredGroup = groups.filter(group => {
      const groupRecords = [];
    
      group.videos.forEach(video => {
        if (video.group && video.group.records) {
          video.group.records.forEach(record => {
            const recordIds = groupRecords.map(r => r._id);
            if (!recordIds.includes(record._id)) {
              groupRecords.push(record);
            }
          });
        }
      });
    
      // Role filter
      let roleMatch = roleFilter === 'all' || groupRecords.some(record => record.role === roleFilter);
    
      // User filter and Feedback filter using pageFeedbacks
      let userMatch = userFilter === 'all';
      let feedbackMatch = filter === 'all';
    
      if(userFilter !== 'all'){
        const feedBackFromUser = pageFeedbacks.filter(f => f.from_user.email === userFilter).map(f => f.ref_id)
        userMatch = feedBackFromUser.includes(group._id)
      }
      if (filter !== 'all') {
        const feedbackMatchGroups = pageFeedbacks.filter(f => f.feedback === filter && (userFilter === 'all' || f.from_user.email === userFilter))
          .map(f => f.ref_id)
        feedbackMatch = feedbackMatchGroups.includes(group._id)
      }
      
      return roleMatch && userMatch && feedbackMatch;
    });
    return filteredGroup;
  }

  allGroupsSelected = (checkTab) => {
    if (checkTab !== this.state.tab || this.state.videos.length === 0) {
      return false
    }
    return this.state?.selectedForUploads?.length === this.state.videos.length
  }

  toggleAllGroupSelect =(select) => {
    const filtered = this.filterGroupFunction()
    let selectedVideos = []

    filtered.forEach( group => {
      group.videos.forEach(v => {
        if(selectedVideos.includes(v.uri)){
          return
        }
        selectedVideos.push(v.uri)
      })
    })
    this.setState({
      selectedForUploads: select ? selectedVideos : []
    })
  }

  render() {
    const {
      studio,
      tab,
      page,
      groups,
      countPerRow,
      activeItem,
      activeRidx,
      activeGidx,
      selectedForUploads,
      twrGroupRecords,
      groupRecords,
      selectedGroup,
      filter,
      roleFilter,
      pageFeedbacks,
      userFilter,
      user,
      showCommentInline,
      showCDCommentInline
    } = this.state

    let rows = []

    if (!studio) {
      return <div>Loading...</div>
    }

    const roles = []
    groups.forEach(group => {
      group.videos.forEach(video => {
        video.group.records.forEach(record => {
          if (!roles.includes(record.role)) {
            roles.push(record.role)
          }
        })
      })
    })
    const temp =[]
    roles.forEach(role => {
      const sortIndex = studio.project_roles.indexOf(role)
      if(sortIndex !== -1){
        temp.push({sortIndex, role})
      }
    })

    const sortedRoles = temp.sort((a,b) => a.sortIndex -b.sortIndex).map(item => item.role)
    const rs = [...new Set([...sortedRoles, ...roles])] //to add roles that is not in the project_roles

    const uniqueUsers = [];
    pageFeedbacks.forEach(f => {
      if (f.from_user && f.from_user.email && !uniqueUsers.includes(f.from_user.email)) {
        uniqueUsers.push(f.from_user.email);
      }
    });

const filteredGroups = this.filterGroupFunction()


 for (let i = 0, l = filteredGroups.length; i < l; i += countPerRow) {
  rows.push(filteredGroups.slice(i, i + countPerRow));
}

    const rowWidth = countPerRow * (itemWidth + 32)
    const activeGroup = this.state.groups[activeGidx]

    const combinedGroupRecords = groupRecords.concat(twrGroupRecords)

    function pad(n) {
      return (n < 10) ? ("0" + n) : n;
    }

    return (
      <div>
        <div className="no-print video-app">
          <div className={`loading ${this.state.loading ? 'show' : ''}`}>
            <div className='d-flex flex-column align-items-center text-danger bg-white px-4 pt-3 pb-1 border-danger border'>
              <FaSpinner size='32px' className='spinning' />
              <span className='h4'>Processing...</span>
              <span className="uploading-percent-indicator" />
            </div>
          </div>
          {this.state.currentPostingLink && <div className='d-flex align-items-center justify-content-end mb-4'>
            <div>
              <select
                className="form-control action-button h-14-600"
                defaultValue={this.page_id}
                onChange={ev => {
                  this.props.history.push(`/posting-page-via-link/${this.props.match.params.uri}/${this.state.currentPostingLink._id}/${ev.target.value}`);
                  window.location.reload();
                }}
              >
                <option value="" disabled hidden>Posting Pages</option>
                {this.state.currentPostingLink && this.state.currentPostingLink.posting_pages.map(page => {
                  return <option key={page._id} value={page._id}>{page.name}</option>
                })}    
              </select>
            </div>
          </div>}
          <div className="video-header d-flex align-items-center justify-content-between">
            
            <div>
              <h2 className="h-32-400 mb-0">{page.name}</h2>
              <h4 className="h-14-400 text-secondary mb-0">{studio.name}</h4>
            </div>
            <div className="d-flex align-items-center download-selected">
              <button
                className="action-button h-12-600"
                onClick={() => window.print()}
              >
                <FaPrint className="mr-2 mt-n1" />
                Print
              </button>
              {tab === TABS.VIDEOS && POSTINGPAGE_PERMISSIONS.CAN_SORT_GROUPS() && (
                <GroupSorter
                  groups={groups}
                  update={this.updateGroupOrder}
                  btnContent={ [<SortIcon key="sort-icon-p"/>, "Sort Group"]}
                  btnClass='action-button h-12-600 ml-2'
                />
              )}
              {selectedForUploads.length > 0 && (
                <label key="1" className="ml-2 mb-0 btn btn-primary" onClick={() => this.downloadAllVideos()} >
                  <FaDownload className="mr-2 mt-n1" />
                  Download Selected
                </label>
              )}
            </div>
          </div>
          <ul className="tab-menu nav nav-tabs mt-2 align-items-end">
            <li className="nav-item">
              <a
                className={`nav-link h5 mb-0 h-14-400 ${tab === TABS.VIDEOS ? 'active' : 'text-muted'}`}
                href="#"
                onClick={() => this.changeTab(TABS.VIDEOS)}
              >
                <input
                  type="checkbox"
                  className="mr-2"
                  disabled={tab === TABS.ARCHIVED}
                  checked={this.allGroupsSelected(TABS.VIDEOS)}
                  onChange={(ev) => this.toggleAllGroupSelect(ev.target.checked)}
                />
                Videos
              </a>
              <div className={`${tab === TABS.VIDEOS ? 'active' : null} horizontal-line`}/>
            </li>
            {POSTINGPAGE_PERMISSIONS.CAN_VIEW_ARCHIVE() &&
              <li className="nav-item">
                <a
                  className={`nav-link h5 mb-0 h-14-400 ${tab === TABS.ARCHIVED ? 'active' : 'text-muted'}`}
                  href="#"
                  onClick={() => this.changeTab(TABS.ARCHIVED)}
                >
                  <input
                    type="checkbox"
                    className="mr-2"
                    disabled={tab === TABS.VIDEOS}
                    checked={this.allGroupsSelected(TABS.ARCHIVED)}
                    onChange={(ev) => this.toggleAllGroupSelect(ev.target.checked)}
                  />
                  Archived
                </a>
                <div className={`${tab === TABS.ARCHIVED ? 'active' : null} horizontal-line`}/>
              </li>}
            <li className="nav-item mb-2 filter-bar-wrapper">
              <div className="filter-bar">
                <div className='d-flex'>
                    <div className='d-flex align-items-center mr-2'>
                      <Switch
                        checkedIcon={null} uncheckedIcon={null}
                        onColor="#26354D"
                        height={16}
                        width={32}
                        checked={this.state.showAvatar}
                        onChange={v => { this.setState({ showAvatar: v })}}
                      />
                      <span className='ml-2 h-14-400'>Show Headshot</span>
                    </div>
                    <div className='d-flex align-items-center mr-2'>
                      <Switch
                        checkedIcon={null} uncheckedIcon={null}
                        height={16}
                        width={32}
                        onColor="#26354D"
                        checked={showCommentInline}
                        onChange={(state) => this.setShowCommentInline(state)}
                      />
                      <span className='ml-2 h-14-400'>Show Comments Inline</span>
                    </div>
                    <div className='d-flex align-items-center mr-2'>
                      <Switch
                        checkedIcon={null} uncheckedIcon={null}
                        height={16}
                        width={32}
                        onColor="#26354D"
                        checked={showCDCommentInline}
                        onChange={(state) => this.setShowCDCommentInline(state)}
                      />
                      <span className='ml-2 h-14-400'>Show CD Comments Inline</span>
                    </div>
                </div>
                <div className="mr-2">
                  <select
                    key={roleFilter}
                    className="form-control action-button h-14-600 "
                    value={roleFilter}
                    onChange={ev => {
                      this.setRoleFilter(ev.target.value)
                    }}
                  >
                    <option key="all" value="all">
                      All Roles
                    </option>
                    {rs.map(role => (
                      <option key={role} value={role}>
                        {role || '--no role--'}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mr-2">
                  <select
                  key={userFilter}
                  className="form-control action-button h-14-600"
                  value={userFilter}
                  onChange={ev => {
                    this.setUserFilter(ev.target.value);
                  }}
                >
                  <option key="all" value="all">
                    All Users
                  </option>
                  {uniqueUsers.map(user => (
                    <option key={user} value={user}>
                      {user}
                    </option>
                  ))}
                </select>
                </div>
                <div className="mr-2">
                  <select
                    className="form-control action-button h-14-600"
                    value={filter}
                    onChange={ev => {
                      this.setFilter(ev.target.value)
                    }}
                  >
                    <option key="all" value="all">All Feedback</option>
                    <option key="yes" value="yes">Yes</option>
                    <option key="no" value="no">No</option>
                    <option key="maybe" value="maybe">Maybe</option>
                  </select>
                </div>
                {POSTINGPAGE_PERMISSIONS.CAN_COPY_TO_POSTING_PAGE() && selectedForUploads?.length > 0 && <button
                  onClick={() =>this.setShowPageCopyModal(true)}
                  className='action-button h-14-600'>Copy to Posting Page</button>}
              </div>
            </li>
          </ul>
          <div className="video-wrapper">
            {rows.length === 0 && <div className="p-5">No videos available </div>}
            {rows.map((row, ridx) => {
              return (
                [
                  <div className="video-row" key={ridx} style={{ width: `${rowWidth}px` }}>
                    {row.map((group, gidx) => {
                      const groupVideos = group.videos.map(v => v._id);
                      const toArchive = !(tab === TABS.ARCHIVED);
                      const thumbImage = this.state.showAvatar
                        ? group.records[0]?.avatar || group.thumbnail
                        : group.thumbnail;
                      return (
                        <div
                          key={group.idx}
                          className={`mx-2 video-item ${activeGidx === group.idx ? 'active' : ''}`}
                          style={{
                            width: itemWidth
                          }}
                        >
                          {toArchive && (
                            <div className="order-indicator">
                              {pad(group.order)}
                            </div>
                          )}
                          <div
                            className="preview-wrapper"
                            onClick={() => {
                              this.handleGroupItemClick(ridx, group.idx);
                            }}
                          >
                            <img
                              loading='lazy'
                              className="dummy-player"
                              src={static_root + thumbImage}
                              onError={(ev) => {
                                ev.target.src = static_root + group.records[0]?.avatar;
                              }}
                              alt='thumbnail'
                            />
                          </div>
                          <div className="d-flex align-items-center py-2">
                            <input
                              type="checkbox"
                              checked={this.groupSelectedForDownload(group.idx)}
                              className="mr-2"
                              onChange={(ev) => this.toggleGroupSelectedForDownload(group.idx, ev.target.checked)}
                            />
                            <div className='h-14-600 ls-2'>{group.name}</div>
                            {POSTINGPAGE_PERMISSIONS.CAN_UPDATE_GROUP() && group._id &&
                              <label
                                className="mb-0 ml-2"
                                onClick={ev => {
                                  ev.stopPropagation();
                                  ev.preventDefault();
                                  this.setState({
                                    selectedGroup: group
                                  });
                                }}
                              >
                                <EditIconSmall />
                              </label>}
                            {POSTINGPAGE_PERMISSIONS.CAN_ARCHIVE() &&
                              <label
                                className="mb-0 ml-auto cursor-pointer"
                                onClick={() => {
                                  this.handleGroupArchive(groupVideos, toArchive, group.videoReivewGroupId);
                                }}
                                title={toArchive ? 'Archive' : 'Restore'}
                              >
                                {toArchive ? <ArchiveIcon /> : <FaTeethOpen />}
                              </label>}
                          </div>
                          {showCDCommentInline && <div className='cd-comments-container p-2 br-8 mb-2'>
                            <span className='h-12-600 mb-2'>Casting Director Comments</span>
                            { group.records.map(record => 
                              <InlineComments
                                refId={group.videoReivewGroupId}
                                pageId={record.session}
                                record={record}
                                user={user}
                                disableFeedbackCommentsEdit={true}/>
                            )}
                          </div>}
                          {showCommentInline && group.records.map(record => 
                            <InlineComments
                              refId={group._id}
                              pageId={this.page_id}
                              record={record}
                              user={user}/>
                          )}
                        </div>
                      )
                    })}
                  </div>,
                  ridx === activeRidx && activeGroup ?
                    <div className="d-flex flex-column active-group-row p-3" key="active-field">
                      {activeItem ? (
                        <div className="row player-row mb-2">
                          <div className="col-lg-8 d-flex flex-column">
                            <div className="video-player-wrapper d-flex">
                              <ReactPlayer
                                controls={true}
                                url={static_root + activeItem.uri}
                                key="video"
                                autoPlay
                                id="active-player"
                                height="100%"
                                width="100%"
                              />
                            </div>
                            <div
                              key={activeGidx}
                              className="d-flex align-items-start group-videos-wrapper py-2"
                              style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}
                            >
                              {activeGroup.videos.map((video, idx) => {
                                return (
                                  <div
                                    key={video.uri + idx}
                                    className={`mx-0 mb-2 mr-2 video-item ${activeItem.uri === video.uri ? 'active' : ''}`}
                                    style={{ display: 'inline-block' }}
                                  >
                                    <div
                                      style={{
                                        width: thumbWidth
                                      }}
                                    >
                                      <div
                                        className="preview-wrapper"
                                        onClick={() => this.setState({ activeItem: video })}
                                      >
                                        <img
                                          className="dummy-player dummy-video"
                                          src={static_root + video.thumbnail}
                                          onError={(ev) => {
                                            ev.target.src = static_root + activeGroup.records[0].avatar;
                                          }}
                                          alt='thumbnail'
                                        />
                                      </div>
                                      <div className="d-flex">
                                        {POSTINGPAGE_PERMISSIONS.CAN_ARCHIVE() &&
                                          <label
                                            className="mb-0 ml-2"
                                            onClick={() => {
                                              this.handleArchiveVideo(video._id, !video.is_archived);
                                            }}
                                            title={video.is_archived ? 'Restore' : 'Archive'}
                                          >
                                            {video.is_archived ? <FaTeethOpen /> : <FaArchive />}
                                          </label>}
                                        {video.is_archived && (
                                          <label
                                            className="ml-auto mb-0"
                                            onClick={() => this.handleVideoDelete(video._id)}
                                            title="Delete"
                                          >
                                            <FaTrash />
                                          </label>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                              {tab !== TABS.ARCHIVED && POSTINGPAGE_PERMISSIONS.CAN_ADD_VIDEO() && (
                                <div
                                  style={{
                                    width: thumbWidth,
                                    alignSelf: 'stretch'
                                  }}
                                  className="pb-2"
                                >
                                  <div className="video-uploader pt-4 px-3 mr-2 h-100">
                                    <span className='h-12-600'>Upload New Video</span>
                                    <input
                                      key={activeGroup.videos.length}
                                      type="file"
                                      accept="video/*"
                                      onChange={ev => {
                                        this.uploadNewVideo(ev.target.files[0])
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                              {POSTINGPAGE_PERMISSIONS.CAN_ADD_VIDEO() && (
                                <GroupSorter
                                  title="Sort Videos"
                                  groups={activeGroup.videos}
                                  update={this.updateVideoOrder}
                                  showThumbnail={true}
                                  btnClass='action-button h-12-600'
                                />
                              )}
                            </div>
                          </div>
                          <div key="info" className="col-lg-4 info col-12">
                            {combinedGroupRecords.map(record => (
                              <div className="talent-summary" key={record._id}>
                                <PersonCardV
                                  record={record}
                                  refId={activeGroup._id}
                                  pageId={this.page_id}
                                  hideContact={!POSTINGPAGE_PERMISSIONS.CAN_VIEW_CONTACT()}
                                  showInlineComments={true}
                                  allowIndividualFbks={true}
                                  videoReviewGroupId = {activeGroup.videos[0].group.videoReivewGroupId}
                                  showVideoReviewComments = {page.showVideoReviewComments}
                                />
                              </div>
                            ))}
                            {combinedGroupRecords.length === 0 &&
                              <div className="talent-summary">
                                No talent information available
                              </div>}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    : null
                ]
              );
            })}
          </div>
          <Footer />
          <CopyModal
            showPageCopyModal={this.state.showPageCopyModal}
            setShowPageCopyModal={this.setShowPageCopyModal}
            studio={studio}
            postingPages={this.state.postingPages}
            newPostingPage={this.state.newPostingPage} 
            setNewPostingPage={this.setNewPostingPage}
            handleGroupsCopy={this.handleGroupsCopy}/>
          <Modal
            show={!!selectedGroup._id}
            onHide={() => {
              this.setState({
                selectedGroup: {}
              })
            }}
          >
            <Modal.Header closeButton>
              <h5 className="mb-0">
                Edit group
              </h5>
            </Modal.Header>
            <Modal.Body>
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Group Name"
                value={selectedGroup.name}
                onChange={ev => {
                  this.setState({
                    selectedGroup: {
                      ...this.state.selectedGroup,
                      name: ev.target.value
                    }
                  })
                }}
              />
              <input
                type="file"
                className="form-control"
                onChange={ev => {
                  this.setState({
                    selectedGroup: {
                      ...this.state.selectedGroup,
                      thumbnail: ev.target.files[0]
                    }
                  })
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                disabled={selectedGroup && !selectedGroup.name}
                className="btn btn-primary"
                onClick={async () => {
                  await updatePostingGroup(selectedGroup._id, selectedGroup)
                  this.setState({
                    selectedGroup: {}
                  })
                  await this.loadVideos()
                }}
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        <ReportPage
          groups={groups}
          page={page}
          studio={studio}
        />
      </div>
    )
  }
}

export default PostingPage
