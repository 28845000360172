import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import Avatar from "react-avatar";

import { static_root, updateRecordField, updateUserFields } from "../../../../services";
import {
  CloseIconLg,
  InstaLogo,
  TikTokLogo,
} from "../../../../assets/icons/session";
import TalentInfoPrintCard from "../TalentPrintCard";
import "./styles.scss";
import SelectSize from "../../../../components/size-select";

const TalentInfoModal = ({
  showTalentInfoModal,
  setShowTalentInfoModal,
  agent,
  agent_email,
  agent_phone,
  email,
  first_name,
  height,
  instagram_handle,
  last_name,
  logo,
  phone,
  photos,
  role,
  tiktok_handle,
  user_type,
  hideContact,
  studio,
  hideFeedbackComments,
  MyFeedbackIcon,
  feedbackBar,
  cmts,
  content,
  setContent,
  addNewComment,
  hideCommentSection,
  _id,
  clothes_type,
  clothes_size_object,
  showEditButton= true
}) => {

  let talentHeight = null;

  const [sizeObject, setSizeObject] = useState({})
  const [clothesType, setClothesType] = useState()
  const [showSizeSelect, setShowSizeSelect] = useState(false)
  const [editHeight, setEditHeight] = useState(false)
  const [ft, setFt] = useState('')
  const [inches, setInches] = useState('')

  
  useEffect(() => {
    parseSizeObject(clothes_size_object)
}, [clothes_size_object]);

useEffect(() => {
  setClothesType(clothes_type)
}, [clothes_type])

  if(!height) {
    talentHeight = null;
  } else if (typeof height === 'string') {
    talentHeight = JSON.parse(height);
  } else if (typeof height === 'object') {
    talentHeight = height;
  }

  const talenInfoRef = useRef({});
  const [userLogo, setUserLogo] = useState(logo);
  const videos = [];

  function checkInstagramHandle() {
    const re = /^(?:(?:https?:\/\/)?(?:www\.)?)?instagram\.com\/(.+)/;
    const match = instagram_handle?.match(re);
    if (match) {
      console.log(`Contains URL: ${match[0]}`);
      return match[0];
    } else {
      return `https://www.instagram.com/${instagram_handle}`;
    }
  }

  function checkTikTokHandle() {
    const re = /^(?:(?:https?:\/\/)?(?:www\.)?)?tiktok\.com\/(.+)/;
    const match = tiktok_handle?.match(re);
    if (match) {
      console.log(`Contains URL: ${match[0]}`);
      return match[0];
    } else {
      return `https://www.tiktok.com/@${tiktok_handle}`;
    }
  }

  const showTextInput =
    content !== undefined &&
    setContent !== undefined &&
    addNewComment !== undefined;

  useEffect(() => {
    setUserLogo(logo);
     
  }, [logo]);

  const updateLogo = async (url) => {
    try {
      const fields = {
        logo: url,
      };
      const formData = new FormData();
      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
      });
      await updateUserFields(_id, formData);
      setUserLogo(url);
    } catch (e) {
      console.log("update logo error:>", e);
    }
  };

  const onSelectSize = (e, type) => {
    const sizeObjectCopy = {...sizeObject}
    sizeObjectCopy[type] = { value: e.value, label: e.label }
    setSizeObject(sizeObjectCopy)
  }

  const saveRecord = async() => {
    try{
      await updateRecordField(_id, {
        clothes_size_object: JSON.stringify(sizeObject),
        clothes_type: clothesType 
      })
      setShowSizeSelect(false)
    }catch(e){
      console.log('saveRecord error:>', e)
    }
  }

  const onCancel = () =>{
    setClothesType(clothes_type)
    parseSizeObject(clothes_size_object)
    setShowSizeSelect(false)
  }

  const saveHeight = async() => {
    try{
      await updateRecordField(_id, {
        height: { ft, inches }
      })
      setEditHeight(false)
    }catch(e){
      console.log('saveHeight error:>', e)
    }
  }

  const cancelHeight = () =>{
    setInches(null)
    setFt(null)
    setEditHeight(false)
  }

  const parseSizeObject =(object) => {
    if (object) {
      try {
          const parsedObject = typeof object === 'string'
              ? JSON.parse(object)
              : object;

          setSizeObject(parsedObject);
      } catch (error) {
          console.error('Failed to parse clothes_size_object:', error);
      }
    }else {
      setSizeObject({})
    }
  }

  const renderSizes = () => {
    if(showSizeSelect){
      return (
        <div className="p-2 br-8 bg-lightgray">
          <SelectSize  
            sizeObject={sizeObject}
            setSizeObject={setSizeObject}
            onSelectSize={onSelectSize}
            clothesType={clothesType}
            setClothesType= {setClothesType}
            customClass="flex-column align-items-start"/>
          <div className="mt-2">
            <button
              onClick={saveRecord}
              className="primary-solid-button h-12-600 text-white mr-2"
              disabled={!clothesType}>Save</button>
            <button
              className="primary-outline-button h-12-600"
              onClick={onCancel}>Cancel</button>
          </div>
        </div>
      )
    } else if(clothesType && typeof clothesType === 'string' && clothesType.trim() !== '' && clothesType !== 'undefined'){
      return(
        <div className="size-wrapper">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <span className="h-14-400">I wear clothes for:</span>
              <span className="h-14-600">
                &nbsp;{clothesType[0].toUpperCase() + clothesType.slice(1)}
              </span>
            </div>
            {showEditButton && <button
              className="primary-outline-button h-12-600"
              onClick={()=> setShowSizeSelect(true)}>Edit</button>}
          </div>
          <div className="size-container-grid mt-4">
            {sizeObject && Object.keys(sizeObject).length > 0 &&
              Object.keys(sizeObject).map(key => (
                <div className="d-flex flex-column mb-2" key={key}>
                  <span className="h-12-600">{key[0].toUpperCase() + key.slice(1)} Size</span>
                  <span className="h-14-400">{sizeObject[key].label}</span>
                </div>
              ))
            }
          </div>
        </div>
      )
    } else if(showEditButton) {
      return (
        <button
          className="primary-outline-button h-12-600"
          onClick={()=> setShowSizeSelect(true)}>Add Sizes</button>
      )
    } else{
      return null
    }
  }

  return (
    <Modal
      key="talent-info-modal"
      className="talent-info-modal"
      size={hideCommentSection ? "sm" : "lg"}
      show={showTalentInfoModal}
      onHide={() => setShowTalentInfoModal(false)}
    >
      <Modal.Header>
        <div className="d-flex flex-column">
          <h5 className="mb-1">{`${first_name} ${last_name}`}</h5>
          <div className="h-14-400 secondary-color">
            Role: <span className="role">{role}</span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <ReactToPrint
            pageStyle={"@page { padding: 50px 30px; }"}
            documentTitle={`${first_name} ${last_name}`}
            trigger={() => {
              return (
                <button className="primary-outline-button h-14-600 ">
                  Print
                </button>
              );
            }}
            content={() => talenInfoRef.current}
          />
          <button
            onClick={() => setShowTalentInfoModal(false)}
            className="close-button"
          >
            <CloseIconLg />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div
          className="modal-left"
          style={{ width: hideCommentSection ? "100%" : "66%" }}
        >
          <div className="talent-info-top">
            {userLogo ? (
              <img
                className="talent-avatar"
                src={`${static_root}${userLogo}`}
                alt="avatar"
              />
            ) : (
              <img
                className="talent-avatar"
                src={require("../../../../assets/camera.png")}
                alt="avatar"
              />
            )}
           
              <div className="top-right">
                {!hideContact && (
                <>
                  <div className="detail-container">
                    <span className="h-12-600">Phone</span>
                    <span className="h-14-400">{phone}</span>
                  </div>
                  <div className="detail-container">
                    <span className="h-12-600">Email</span>
                    <span className="h-14-400">{email}</span>
                  </div>
                </>
                )}
                {renderSizes()}
                   
                  
              </div>
            
          </div>
          <div className="talent-info-bottom">
            <div className="item1 detail-container">
              <span className="h-12-600">Socials</span>
              {!hideContact ? <div className="d-flex align-items-center mt-2">
                <span className="social-link">
                  <InstaLogo />
                  {instagram_handle ? (
                    <a
                      href={checkInstagramHandle()}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#498FF8", cursor: "pointer" }}
                    >
                      Instagram
                    </a>
                  ) : (
                    "-"
                  )}
                </span>
                <span className="social-link ml-2">
                  <TikTokLogo />
                  {tiktok_handle ? (
                    <a
                      href={checkTikTokHandle()}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#498FF8", cursor: "pointer" }}
                    >
                      Tiktok
                    </a>
                  ) : (
                    "-"
                  )}
                </span>
              </div> : <div>-</div>}
            </div>
            <div className="detail-container item2">
                
                <div className="d-flex align-items-center justify-content-between">
                  <span className="h-12-600">Height</span>
                  {showEditButton && !editHeight && <button
                    className="primary-outline-button h-12-600"
                    onClick={()=> setEditHeight(true)}>{(talentHeight && (talentHeight.ft || talentHeight.inches) ) ? 'Edit' : 'Add'}</button>}
                  {editHeight && <div className="mt-2">
                    <button
                      onClick={saveHeight}
                      className="primary-solid-button h-12-600 text-white mr-2"
                    >Save</button>
                    <button
                      className="primary-outline-button h-12-600"
                      onClick={cancelHeight}>Cancel</button>
                  </div>}
                </div>
              {!editHeight && <span className="h-14-400">
                {talentHeight
                  ? `${talentHeight.ft}' ${talentHeight.inches}"`
                  : "-"}
              </span>}
              {editHeight && <div className="d-flex align-items-center">
                <div className="div-input">
                  <input 
                    type="number" 
                    name="ft" 
                    id="ft" 
                    className="height-input" 
                    value={ft}
                    onChange={(ev) => setFt(ev.target.value)}/>
                  <div className="h-12-600 indicator">ft</div>
                </div>
                <div className="div-input ml-2">
                  <input
                    type="number"
                    name="inches"
                    id="inches"
                    className="height-input"
                    value={inches}
                    onChange={(ev) => setInches(ev.target.value)}/>
                  <div className="h-12-600 indicator">in</div>
                </div>
              </div>}
            </div>
            <div className="detail-container item3">
              <span className="h-12-600">Agent</span>
              <span className="h-14-400">{agent ? agent : "-"}</span>
            </div>

            {!hideContact && <div className="detail-container item4">
              <span className="h-12-600">Agent Email</span>
              <span className="h-14-400">
                {agent_email ? agent_email : "-"}
              </span>
            </div>}

            {!hideContact && <div className="detail-container item5">
              <span className="h-12-600">Agent Phone</span>
              <span className="h-14-400">
                {agent_phone ? agent_phone : "-"}
              </span>
            </div>}
          </div>
          {photos && photos.length > 0 && (
            <div className="media-container mt-3">
              <h5 className="h-14-700 primary-color mb-0">Headshots</h5>
              <div className="image-container">
                {photos?.map((headshot, index) => {
                  return (
                    <img
                      key={index}
                      className="headshot cursor-pointer"
                      src={`${static_root}${headshot}`}
                      alt="headshot"
                      style={{ objectFit: "cover" }}
                      onClick={() => updateLogo(headshot)}
                      onError={(e) => {
                        e.target.style.display = "none";
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {videos && videos.length > 0 && (
            <div className="media-container mt-3">
              <h5 className="h-14-700 primary-color mb-0">Videos</h5>
              <div className="image-container">
                {videos?.map((video, index) => {
                  return (
                    <img
                      key={index}
                      className="video"
                      src={`${static_root}${video}`}
                      alt="headshot"
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {!hideCommentSection && (
          <div className="modal-right">
            <h5 className="h-14-700 primary-color mb-0">Comments</h5>

            <div className="note-container">
              {/* <div className="horizontal-line">
              <span className="date-indicator h-12-400 secondary-color">
                Friday, April 5
              </span>
            </div> */}
              {cmts &&
                cmts.map((comment, index) => {
                  return (
                    <div
                      className="note-item d-flex aling-items-center p-1"
                      key={index}
                    >
                      <div className="left">
                        <Avatar
                          className="participantAvatar"
                          name={
                            comment?.by?.first_name +
                            " " +
                            comment?.by?.last_name
                          }
                          size="32px"
                          round="32px"
                          textSizeRatio={2}
                          // @ts-ignore
                          title={false}
                        />
                      </div>
                      <div className="ml-2 right">
                        <div className="comment-author d-flex align-items-center ">
                          <span className="h-12-600">
                            {comment?.by?.first_name +
                              " " +
                              comment?.by?.last_name}
                          </span>
                          {/* <span className="ml-2 h-12-400 secondary-color time">
                        Today at 5:46PM
                      </span> */}
                        </div>
                        <span className="h-14-400">{comment?.content}</span>
                      </div>
                    </div>
                  );
                })}
            </div>

            {showTextInput && (
              <>
                <textarea
                  className="comment-textarea br-8 h-14-400 p-2"
                  placeholder="Add your comment"
                  rows="4"
                  cols="50"
                  style={{ resize: "none" }}
                  value={content}
                  onChange={(ev) => setContent(ev.target.value)}
                />
                <button
                  className="h-14-700 primary-solid-button ml-auto"
                  onClick={addNewComment}
                  disabled={!content}
                >
                  Add Comment
                </button>
              </>
            )}
          </div>
        )}

        <div className="d-none">
          <TalentInfoPrintCard
            agent={agent}
            agent_email={agent_email}
            agent_phone={agent_phone}
            email={email}
            first_name={first_name}
            height={talentHeight}
            last_name={last_name}
            logo={logo}
            phone={phone}
            role={role}
            hideContact={hideContact}
            photos={photos}
            tiktok_handle={tiktok_handle}
            instagram_handle={instagram_handle}
            videos={videos}
            hideCommentSection={hideCommentSection}
            cmts={cmts}
            ref={talenInfoRef}
            clothesType={clothesType}
            sizeObject={sizeObject}
          />
        </div>
      </Modal.Body>
      {!hideFeedbackComments ? (
        <Modal.Footer>
          <span className="myfeedback-icon mr-auto mt-1">{MyFeedbackIcon}</span>
          {feedbackBar}
        </Modal.Footer>
      ) : null}
    </Modal>
  );
};

export default TalentInfoModal;
