import React, {
  useEffect,
  useState,
  useCallback
} from 'react'
import {
  Link,
  useLocation,
} from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  Button
} from 'react-bootstrap'
import {
  FaEye,
  FaPencilAlt
} from 'react-icons/fa'
import {
  getUserById,
} from '../../services'

import ProfileView from './components/ProfileView'
import ProfileForm from './components/ProfileForm'
import RequestList from './components/Requests'

import { useAuthedUser } from '../../hooks/auth'
import './style.scss'

export default () => {
  const {id} = useAuthedUser()
  const [user, setUser] = useState(null)
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const editProfile = search.get('edit') === 'true'

  const loadUser = useCallback(async ()=> {
    try {
      setUser(await getUserById(id))
    } catch (error) {
    }
  }, [id, editProfile])  

  useEffect(() => {
    loadUser()
  }, [loadUser])
  
  if (user === null) {
    return <></>
  }

  return (
    <div className='page py-5'>
      <Container>
        <Row>
          <Col>
            {editProfile
              ? <ProfileForm user={user} />
              : <ProfileView user={user} />
            }
          </Col>
          <Col>
            <RequestList
              user={user}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

