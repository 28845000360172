import React, { useState } from 'react'
import { RoleEditor } from './index'

const TalentQuickAdd = ({ roles, opportunityIdList, setSelectedOpportunityId, talentIsAllowedToCreateRoles }) => {
  const [role, setRole] = useState('')
  return (
    <div className='d-flex flex-column'>
      <div className="d-flex w-100">
        <div className="form-group w-50 pr-1">
          <label htmlFor="first_name">First name *</label>
          <input type="text" required className="form-control" name="first_name" id="first_name" />
        </div>
        <div className="form-group w-50 pl-1">
          <label htmlFor="last_name">Last name *</label>
          <input type="text" required className="form-control" name="last_name" id="last_name" />
        </div>
      </div>
      <div className="d-flex w-100">
        <div className="form-group w-50 pr-1">
          <label htmlFor="email">Email *</label>
          <input type="text" required className="form-control" name="email" id="email" />
        </div>
        <div className="form-group w-50 pl-1">
          <label htmlFor="phone">Phone</label>
          <input type="text" className="form-control" name="phone" id="phone" />
        </div>
      </div>
      <div className="d-flex w-100">
        <div className="form-group w-50 pr-1">
          <label htmlFor="agent">Agent</label>
          <input type="text" className="form-control" name="agent" id="agent" />
        </div>
        <div className="form-group w-50 pl-1">
          <label htmlFor="Role">Role *</label>
          <RoleEditor
            selectedRecord={{ role: role ? role : '' }}
            roles={roles}
            setRole={text => {
              setRole(text)
            }}
            talentIsAllowedToCreateRoles={talentIsAllowedToCreateRoles}
            role={role}
            opportunityIdList={opportunityIdList}
            setSelectedOpportunityId={setSelectedOpportunityId}
          />
          <input type="text" className="form-control d-none" name="role" value={role} />
        </div>
      </div>
      <div className="form-group w-100">
        <label htmlFor="avatar">Photo</label>
        <input type="file" accept="image/*" className="form-control" name="avatar" id="avatar" />
      </div>
      <div className='w-100 text-sm'>
        Please use the talent's email address for those auditioning virtually so they will receive the link to join the lobby
      </div>
    </div>
  )
}

export default TalentQuickAdd
