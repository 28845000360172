import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import { Modal } from 'react-bootstrap'
import {
  static_root,
  updateVideo,
  getGroupVideos,
  updateVideoSort
} from '../../services'
import './groupvideos.scss'
import { FaArchive, FaSpinner } from 'react-icons/fa'
import { VscRefresh } from 'react-icons/vsc'
import GroupSorter from '../PostingPage/GroupSorter'
import { joinRoom, leaveRoom } from '../../ws'
import { ImSpinner2 } from 'react-icons/im'
const videoPlaceholderImg = require('../../assets/video-placeholder.png')

class GroupVideos extends Component {
  constructor(props) {
    super(props)

    this.state = {
      videos: [],
      videoLoading: false,
      selectedVideo: null
    }

    this.wsMessageHandler = this.wsMessageHandler.bind(this)
  }

  componentDidMount() {
    this.loadVideos()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.groupId !== this.props.groupId) {
      this.loadVideos()
    }
    const uris = this.state.videos.map(v => v.uri)
    if (uris.length !== [...new Set(uris)].length) {
      // found dup videos
      this.setState({
        videos: this.state.videos.reduce((res, video) => {
          const us = res.map(v => v.uri)
          return us.includes(video.uri) ? res : res.concat(video)
        }, [])
      })
    }
    if (prevState.videos.length !== this.state.videos.length) {
      document.querySelector('.group-videos').scrollTo(99999, 0)
    }
  }

  componentWillUnmount () {
    leaveRoom(this.props.groupId)
    document.body.removeEventListener('ws-message', this.wsMessageHandler)
  }

  wsMessageHandler (ev) {
    try {
      const event = ev.detail
      switch(event.type) {
        case 'add-video':
          const uris = this.state.videos.map(v => v.uri)
          if (!uris.includes(event.data.uri)) {
            this.props.setMediafiles(this.state.videos.concat(event.data).map(v => {
              return {fileName: v.uri, fileKey: v.uri, mimeType: 'video/mp4'}
            }))
            this.setState({
              videos: this.state.videos.concat(event.data)
            })
          }
          break
        case 'refresh-videos':
          this.loadVideos()
          break
      }
    } catch (err) {
      console.log('socket msg handle err: ', err);
    }
  }

  loadVideos = async () => {
    this.setState({ videoLoading: true })
    const res = await getGroupVideos(this.props.groupId)

    const mediafiles = res.map(v => {
      return {fileName: v.uri, fileKey: v.uri, mimeType: 'video/mp4'}
    })
    this.props.setMediafiles(mediafiles)
    this.setState({
      videos: res,
      videoLoading: false
    })
    joinRoom(this.props.groupId)
    document.body.addEventListener('ws-message', this.wsMessageHandler)
  }

  handleArchiveVideo = async (video_id) => {
    await updateVideo(video_id, { is_archived: true })
    this.setState({
      videos: this.state.videos.filter(v => v._id !== video_id)
    })
  }

render() {
  const { videos, selectedVideo, videoLoading } = this.state;

  return (
    <div className='group-videos'>
      {videos.map((video, index) => {
        return (
          <div key={video.uri} className='video-item'>
            <div className="index-indicator">
              {index + 1}
            </div>
            {video.thumbnail ? (
              <img
                className="dummy-player"
                src={static_root + video.thumbnail}
                onClick={() => {
                  this.setState({
                    selectedVideo: video
                  })
                }}
                onError={(ev) => {
                  ev.target.src = videoPlaceholderImg
                  setTimeout((elem) => {
                    elem.src = static_root + video.thumbnail
                  }, 5000, ev.target)
                }}
              />
            ) : (
              <div className='dummy-player-loader'>
                <ImSpinner2 className='spinning' size={70} />
              </div>
            )}
            <label
              title="Archive" className='mb-0 mt-n1 cursor-pointer'
              onClick={() => {
                this.handleArchiveVideo(video._id)
              }}
            >
              <FaArchive />
            </label>
          </div>
        );
      })}
      <div className='dummy-player mb-4'>
        <div className='dummy-player-loader'>
          <ImSpinner2 className='spinning' size={70} />
        </div>
      </div>
      <button
        id="group-video-reload-btn"
        className={`refresh-videos ${videoLoading ? 'spin' : ''}`}
        onClick={this.loadVideos}
      >
        <VscRefresh
          className='text-danger'
        />
      </button>
      {videos.length > 0 && (
        <GroupSorter
          showThumbnail={true}
          groups={videos}
          title="Sort videos"
          btnClass="btn btn-primary btn-sm"
          update={(vs) => {
            const ids = vs.map(vs => vs._id)
            updateVideoSort({ ids, group_id: this.props.groupId })
          }}
          btnContent={<img title="Sort videos" className='sort-icon' src={require('../../assets/sort.png')} />}
        />
      )}

      <Modal
        size="xl"
        show={selectedVideo}
        onHide={() => {
          this.setState({ selectedVideo: null })
        }}
      >
        <Modal.Body>
          {selectedVideo && (
            <ReactPlayer
              key={selectedVideo.uri}
              controls={true}
              url={static_root + selectedVideo.uri}
              id="active-player"
              className="w-100 pb-3"
              height="100%"
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-danger' onClick={() => {
            this.setState({ selectedVideo: null })
          }}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      </div>
    )
  }
}

export default GroupVideos
