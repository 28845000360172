import React, { useEffect, useState } from "react";
import {
  DownArrowIcon,
  TrashIcon,
} from "../../../../assets/icons/session";
import { addStudioRole, deleteStudioRole } from "../../../../services";
import { Modal } from "react-bootstrap";
import { CloseIconLg } from "../../../../assets/icons/session";

import "./AddRoles.scss";

export default ({ rolesArray, setRolesArray, index, session, studio}) => {
  const [showEditRole, setShowEditRole] = useState(false);
  const [role, setRole] = useState(rolesArray[index]);
  const [showRoleRemoveModal, setShowRoleRemoveModal] = useState(false);

  useEffect(() => {
    if (!rolesArray[index]) {
      setShowEditRole(true);
    }
  }, [rolesArray, index]);

  const saveRole = async () => {
    const updatedRolesArray = [...rolesArray];
    updatedRolesArray[index] = role;

    await addStudioRole(session.studio, updatedRolesArray).then((res) => {
      setRolesArray(updatedRolesArray);
      setShowEditRole(false);
      console.log("res", res);
    });
  };

  const removeRole = async () => {
    
    await deleteStudioRole(session.studio, role).then((res) => {
      const updatedRolesArray = [...rolesArray];
      updatedRolesArray.splice(index, 1);
      setRolesArray(updatedRolesArray);
      setRole(updatedRolesArray[index]);
      setShowRoleRemoveModal(false);
      setShowEditRole(false);
      console.log("role removed", res, "role:>", role);
    });
  };

  const onCancel = () => {
    if (!rolesArray[index]) {
      const updatedRolesArray = [...rolesArray];
      updatedRolesArray.splice(index, 1);
      setRolesArray(updatedRolesArray);
    }
    setRole(rolesArray[index]);
    setShowEditRole(false);
  };

  return (
    <div className="add-role-container">
      <div className="role-item">
        <div className="d-flex align-items-center justify-content-between">
          <span className="h-14-600">
            {rolesArray[index].toUpperCase() || "Add New Role"}
          </span>
          <button
            onClick={() => setShowEditRole(!showEditRole)}
            className={!!studio.limelite_project_id ? "d-none" : "arrow-button"}
            disabled={!!studio.limelite_project_id}
          >
            <DownArrowIcon />
          </button>
        </div>
        {showEditRole ? (
          <div className="mt-3">
            <div className="d-flex flex-column">
              <label htmlFor="role" className="h-12-600">
                Name
              </label>
              <input
                value={role}
                onChange={(ev) => setRole(ev.target.value)}
                type="text"
                name="role"
                id="role"
                className="input"
              />
            </div>
            <div className="add-role-item-btn-container mt-3">
              <button
                className="h-14-600 remove-role-button"
                onClick={()=> setShowRoleRemoveModal(true)}
              >
                Remove
              </button>
              <div>
                <button
                  className="h-14-600 cancel-button mr-2"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="h-14-600 save-role-button"
                  onClick={saveRole}
                  disabled={!role}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <RemoveRoleModal
        showRoleRemoveModal={showRoleRemoveModal}
        setShowRoleRemoveModal={setShowRoleRemoveModal}
        removeRole={removeRole}
        title={studio.name}
      />
    </div>
  );
};

const RemoveRoleModal = ({ showRoleRemoveModal, setShowRoleRemoveModal, removeRole, title }) => {
  return (
    <Modal
      key="role-remove-modal"
      className="role-remove-modal"
      size="sm"
      show={showRoleRemoveModal}
      onHide={() => setShowRoleRemoveModal(false)}
      centered
    >
      <Modal.Body>
        <div className="ml-auto d-flex align-items-center">
          <button
            onClick={() => setShowRoleRemoveModal(false)}
            className="close-button"
          >
            <CloseIconLg />
          </button>
        </div>
        <div className="d-flex align-items-center mt-2">
          <TrashIcon />
          <span className="h-20-600 ml-3">Remove Role from {title}?</span>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button className="h-14-600 cancel-button mr-2" onClick={() => setShowRoleRemoveModal(false)}>
          Cancel
        </button>
        <button className="h-14-600 remove-role-button" onClick={removeRole}>
          Remove
        </button>
      </Modal.Footer>
    </Modal>
  );
};
