import React, { useEffect, useReducer, useState } from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { IconContext } from "react-icons";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Modal } from 'react-bootstrap'
import { verityToken, getUser, getNotification, getVersion } from './services'
import ResetPasswordRequest from './views/Auth/ResetPasswordRequest'
import ResetPassword from './views/Auth/ResetPassword'
import CheckinPage from './views/HomePage'
import ClientCheckinPage from './views/HomePage/client'
import ClientHomePage from './views/ClientPage'
import RecordMessagePage from './views/RecordMessagePage'
import Onboard from './views/Onboard'
import VideoPage from './views/VideoReview'
import PostingPage from './views/PostingPage'
import StudioList from './views/Studio/List'
import SessionManagerPage from './views/SessionManagerPage'
import SessionEditPage from './views/Session/SessionEditPage'
import SchedulePage from './views/Session/Schedule/SchedulePage';
import FindFreelancerPage from './views/Session/FindFreelancer/page'
import FreelancerProfilePage from './views/SessionManagerPage/FreelancerProfile'
import AdminView from './views/Admin'
import TalentPage from './views/TalentPage'
import FindFreelancer from './views/Session/FindFreelancer';
import ProjectInvite from './views/Studio/Invite'
import Error404 from './views/Errors/404'
import CalendarView from './views/Calendar'
import Header from './components/Header'
import PrivateRoute from './components/PrivateRoute'
import { USER_TYPES, VERSION } from './constants'
import { TitleContext } from './Context';
import {ShowLoadingContext} from './Context'
import { AuthContext } from './hooks/auth'
import AuthPage from './views/Auth/Page'

import store from './store'
import './App.scss'
import '../node_modules/react-big-calendar/lib/css/react-big-calendar.css'
import Schedule from './views/TalentSchedule';
import DeleteAccount from './views/DeleteAccount';
import BookADemo from './views/BookADemo';
import PostingLinkPage from './views/PostinLinkPage';

const google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID

const state = 0
const reducer = (state, action) => {
  if (action) {
    return state + 1
  }
  else{
    return state -1
  }
}

export const NotificationComponent = ({ notificationField, notificationUpdateAtField }) => {
  const [notification, setNotification] = useState({})
  const [showNotification, setShowNotification] = useState(false)

  const mounted = async () => {
    const version = await getVersion()
    if (version !== VERSION) {
      // Hard refresh!
      window.location.href = window.location.href
    }
    const ignorePaths = [
      "/onboard",
      "/auth",
      "/reset-password-request",
      "/reset-password",
      "/message"
    ]
    const ignore = ignorePaths.find(path => window.location.pathname.startsWith(path))
    if (ignore) { return }
    let n = await getNotification()
    n = n || {}
    setNotification(n)
    setShowNotification(n[notificationField] && `${n[notificationUpdateAtField]}` !== window.localStorage.getItem('n_updated_at'))
  }

  useEffect(() => {
    mounted()
  }, [])

  return (
    <Modal
      show={!!showNotification}
      onHide = {() => { setShowNotification(false) }}
      className="notification-modal"
    >
      <Modal.Header closeButton>
        <h5 className="mb-0">
          Feature Update
        </h5>
      </Modal.Header>
      <Modal.Body>
        <div className="notification-content" dangerouslySetInnerHTML={{__html: notification[notificationField]}} />
        <div className="mt-2">
          <button className="btn btn-primary" onClick={() => {
            window.localStorage.setItem('n_updated_at', notification[notificationUpdateAtField])
            setShowNotification(false)
          }}>
            Ok, Got it.
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

function App() {
  const [logo, setLogo] = useState('')
  const [email, setEmail] = useState({})
  const [title, setTitle] = useState('')
  const [showLoadingSemaphore, dispatch] = useReducer(reducer, state)

  useEffect(() => {
    if (window.location.pathname.indexOf('/onboard') !== -1 || window.location.pathname.indexOf('/schedule') !== -1) {
      window.localStorage.setItem('prev_url', window.location.href)
      return
    }
    if (window.location.pathname.indexOf('/message') !== -1) {
      return
    }
    verityToken().then(async (email) => {
      setEmail(email)
      const user = getUser()
      window.localStorage.setItem('email', email)
      if (['/login', '/auth'].includes(window.location.pathname)) {
        let pUrl = window.localStorage.getItem('prev_url') || '/'
        if (user.user_type === USER_TYPES.TALENT) {
          if (/heyjoe\.io\/studio\/\w+\/\w{24}$/.test(pUrl)) {
            pUrl = pUrl.replace('.io/studio/', '.io/onboard/')
          }
        }
        window.localStorage.removeItem('prev_url')
        window.location.href = pUrl
        return
      }
      if (user.logo) {
        setLogo(user.logo)
      }
      let chatScriptDom = document.createElement('script')
      chatScriptDom.src = '//fast.cometondemand.net/54561x_x782c3x_xcorex_xembedcode.js?v=7.48.6.1'
      document.body.appendChild(chatScriptDom)
    }, () => {
      if ([
        '/reset-password-request',
        '/reset-password',
        '/auth',
        '/book-a-demo'
      ].includes(window.location.pathname)) {
        return
      }

      window.localStorage.setItem('prev_url', window.location.href)
      window.localStorage.removeItem('email')
      window.location.href = '/auth'
    })
  }, [])

  useEffect(() => {
    if (showLoadingSemaphore > 0) {
      document.querySelector('.loading').classList.add('show')
    } else {
      document.querySelector('.loading').classList.remove('show')
    }
  }, [showLoadingSemaphore])

  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY
  const user = getUser() || {}

  const HP = user.user_type === USER_TYPES.CLIENT ? ClientCheckinPage : CheckinPage
  const toggleLoadingState = (state) => {
    dispatch(state)
  }

  return (
    <GoogleOAuthProvider clientId={google_client_id}>
      <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
        <Provider store={store}>
          <ShowLoadingContext.Provider value={toggleLoadingState}>
            <IconContext.Provider value={{ className: "global-class-name" }}>
              <div className={`loading`}>
                <button className='btn btn-secondary btn-sm' onClick={() => {
                  const event = new Event('soft-refresh')
                  document.body.dispatchEvent(event)
                  document.querySelector('.loading').classList.remove('show')
                }}>
                  Processing... <small>(click to refresh)</small>
                </button>
              </div>
              
              <AuthContext.Provider value={user}>
                <TitleContext.Provider value={{title, setTitle}}>
                  <Router>
                    <Header logo={logo} />
                    <Switch>
                      <Route path="/auth" component={(props) => <AuthPage {...props} />} exact />
                      <Route path="/reset-password-request" component={(props) => <ResetPasswordRequest {...props} />} exact />
                      <Route path="/reset-password" component={(props) => <ResetPassword {...props} />} exact />
                      <PrivateRoute
                        path="/heyjoe-admin"
                        component={AdminView}
                        accessTypes={[USER_TYPES.SUPER_ADMIN]}
                      />
                      <Route path="/message/:record_id" component={RecordMessagePage} />
                      <PrivateRoute
                        path="/studio/:uri/:session_id"
                        component={HP}
                        accessTypes={[USER_TYPES.CLIENT, USER_TYPES.SESSION_MANAGER, USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR]}
                      />
                      <PrivateRoute
                        path="/calendar"
                        component={CalendarView}
                        accessTypes={[USER_TYPES.SESSION_MANAGER, USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR]}
                      />
                      <PrivateRoute
                        path="/studios/:studio_uri/sessions/:session_id/find-freelancer"
                        component={FindFreelancer}
                        accessTypes={[USER_TYPES.SESSION_MANAGER, USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR]}
                      />
                      <Route path="/onboard/:uri/:session_id" component={Onboard} />
                      <Route path="/schedule/:uri/:session_id/:day_id/:availability_id?" component={Schedule} />

                      <PrivateRoute
                        path="/video/:uri/:session_id"
                        component={props => <VideoPage {...props} />}
                        accessTypes={[USER_TYPES.SESSION_MANAGER, USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR]}
                      />
                      <PrivateRoute
                        path="/freelancer-profile"
                        component={props => <FreelancerProfilePage {...props} />}
                        accessTypes={[USER_TYPES.SESSION_MANAGER, USER_TYPES.SUPER_ADMIN]}
                      />
                      <PrivateRoute
                        path="/freelancer-requests/:id"
                        component={ProjectInvite}
                        accessTypes={[USER_TYPES.SUPER_ADMIN, USER_TYPES.SESSION_MANAGER]}
                      />
                      <PrivateRoute
                        exact
                        path="/session/:uri/edit/:session_id"
                        component={SessionEditPage}
                        accessTypes={[USER_TYPES.SESSION_MANAGER, USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR]}
                      />
                      <PrivateRoute
                        exact
                        path="/session/:uri/edit/:session_id/schedule/:day_id"
                        component={SchedulePage}
                        accessTypes={[USER_TYPES.SESSION_MANAGER, USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR, USER_TYPES.CLIENT]}
                      />
                      <PrivateRoute
                        path="/session/:uri/:session_id/find-freelancer"
                        component={FindFreelancerPage}
                        accessTypes={[USER_TYPES.SESSION_MANAGER, USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR]}
                      />
                      <PrivateRoute
                        path="/delete-account"
                        component={DeleteAccount}
                        accessTypes={[USER_TYPES.CLIENT, USER_TYPES.SESSION_MANAGER, USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR, USER_TYPES.TALENT]}
                      />
                      <Route
                        path="/book-a-demo"
                        component={BookADemo}
                      />
                      <Route path="/posting-page/:uri/:postingpage_id" component={props => <PostingPage {...props} />} />
                      <Route path="/posting-page-via-link/:uri/:postinglink_id/:postingpage_id" component={props => <PostingPage {...props} />} />
                      <Route path="/posting-link-page/:uri/:postinglink_id" component={props => <PostingLinkPage {...props} />} />
                      <Route path="/" component={HomeComponent} exact />
                      <Route path="/profile" component={props => <TalentPage {...props}/>} />
                      <Route path="*" component={Error404} />
                    </Switch>
                  </Router>
                </TitleContext.Provider>
              </AuthContext.Provider>
              
              { user && user.user_type !== USER_TYPES.CLIENT && (
                <NotificationComponent
                  notificationField="notification"
                  notificationUpdateAtField="notification_updated_at"
                />
              )}
            </IconContext.Provider>
          </ShowLoadingContext.Provider>
        </Provider>
        <input type="text" style={{ display: 'none' }} id="urlInput" />
        <div style={{ display: 'none' }} id="copyHtml" />
      </GoogleReCaptchaProvider>
    </GoogleOAuthProvider>
  );
}

const HomeComponent = (props) => {
  const user = getUser()
  if (!user) {
    return null
  }
  switch (user.user_type) {
    case USER_TYPES.CLIENT:
      return <ClientHomePage />
    case USER_TYPES.TALENT:
      return <TalentPage {...props} />
    default:
      return <StudioList {...props} />
  }
}

export default App;
