import { api_host, token } from './consts'
import { obj2Query } from '../utils'

export const listRequests = async (filter) => {
  return await fetch(`${api_host}/freelancer/request/?${obj2Query(filter)}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const createRequest = async (formdata) => {
  return await fetch(`${api_host}/freelancer/request`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    body: formdata
  }).then(res => res.json())
}

export const apiUpdateRequest = async (id, data) => {
  return await fetch(`${api_host}/freelancer/request/${id}`, {
    method: 'PATCH',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const apiGetRequestInfo = async (id) => {
  return await fetch(`${api_host}/freelancer/request/info/${id}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const apiBookFreelancer = async function (req_id, data){
  return await fetch(`${api_host}/freelancer/book/${req_id}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const apiSetFreelancerResponse = async (id, formdata) => {
  return await fetch(`${api_host}/freelancer/request/set-freelancer-response/${id}`, {
    method: 'POST',
    headers: {'Authorization': `Bearer ${token}`},
    body: formdata
  }).then(res => res.json())
}

export const addCommentToRequest = async (id, content) => {
  return await fetch(`${api_host}/freelancer/request/${id}/comment`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ content })
  }).then(res => res.json())
}

export const setCommentRead = async (id) => {
  return await fetch(`${api_host}/freelancer/request/${id}/comment-read`, {
    method: 'PUT',
    headers: {'Authorization': `Bearer ${token}`},
  }).then(res => res.json())
}

export const unBookFreelancer = async (req_id) => {
  return await fetch(`${api_host}/freelancer/book/${req_id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
  }).then(res => res.json())
}
