import React from 'react'
import { useDrop } from 'react-dnd';

const DragAndDropAppointment = ({ onDrop, children, targetTimeSlot, targetTalentsWithRole, isAppointmentFull, lengthTimeSlot, date, isAdmin }) => {
    const [{ isOver }, drop] = useDrop({
      accept: 'SCHEDULED_APPOINTMENT_ITEM',
      drop: (item, monitor) => {
        const dropResult = { targetTimeSlot, targetTalentsWithRole, lengthTimeSlot, date };
        onDrop(item, monitor, dropResult);
      },
      canDrop: (item, monitor) => {
        return !isAppointmentFull && isAdmin
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    });
  
    return (
      <div ref={drop} style={{ backgroundColor: isOver ? ((isAppointmentFull || !isAdmin) ? "red" : 'lightgreen') : 'white' }}>
        {children}
      </div>
    );
  };

export default DragAndDropAppointment
