/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Button, Modal, Tooltip, Overlay } from 'react-bootstrap'
import { FaListAlt, FaCopy } from 'react-icons/fa'
import { BiCalendarPlus } from 'react-icons/bi'
import { AiOutlineOrderedList } from 'react-icons/ai'

import moment from 'moment'
import { deleteSession } from '../../services'
import { copyHtml } from '../../utils'
import './style.scss'

import { SESSION_TIME_TYPE, USER_TYPE, STUDIO_LIST_PERMISSIONS, SESSION_LOCATION_TYPE } from '../../constants'


import { update as updateStudioInStore } from '../../store/studios'

import SessionCrupdateModal from './SessionCrupdateModal'
import SendClientEmailModal from './SendClientEmailModal'
import SendTalentEmailModal from './SendTalentEmailModal'
import { useHistory } from 'react-router-dom'
import { CinemaIcon, InviteIcon, KioskIcon, LinkIcon, MailIcon, PeopleIcon, SmallTrashIcon, VideoIcon } from '../../assets/icons/session'

const host = window.location.origin

export const SessionActionBts = ({ studio, session, showInvoiceBtn = false, styling = '' }) => {
  const [invoiceCopied, setInvoiceCopied] = useState(false)
  const invoiceCopyRef = useRef(null)

  const handleCopyInvoice = () => {
    const castingDirectors = studio.casting_directors.map(cd => {
      return `${cd.first_name || ''} ${cd.last_name || ''}`
    }).join(', ')
    const content = `Casting Director: ${castingDirectors}\n` +
      `Project Name: ${studio.name}\n` +
      `Session Date: ${(studio.sessions || [session]).map(session => {
        return session.dates.map(st => {
          return moment(new Date(st.start_time)).format('MM-DD-YYYY')
        }).join(', ')
      }).join(', ')}\n`
    copyHtml(content)
    setInvoiceCopied(true)
    setTimeout(() => { setInvoiceCopied(false) }, 1000)
  }
  
  if(!session){
    return null
  }

  return (
    <div className={`session-action-bts ${styling}`}>
      <Link
        title="Session Video Chat"
        className='action-button h-12-600'
        to={`/studio/${studio.uri}/${session._id}`}
        target="_blank"><VideoIcon /><span>Video Chat</span></Link>
      <Link
          to={`/studio/${studio.uri}/${session._id}?test=true`}
          className='action-button h-12-600'
          target="_blank"
          title="Virtual Lobby"
        >
          <PeopleIcon /> <span>Virtual Lobby</span>
      </Link>
      {false && STUDIO_LIST_PERMISSIONS.CAN_VIEW_ONBOARD() &&
        <Link
          to={`/onboard/${studio.uri}/${session._id}`}
          className='action-button'
          target="_blank"
          title="Session Checkin"
        >
          <AiOutlineOrderedList size={17} />
        </Link>
      }
      {STUDIO_LIST_PERMISSIONS.CAN_VIEW_VIDEO_REVIEW() &&
        <Link
          to={`/video/${studio.uri}/${session._id}`}
          className='action-button h-12-600'
          target="_blank"
          title="Video Review"
        >
          <CinemaIcon /> <span>Video Review</span> 
        </Link>
      }
      {USER_TYPE.IS_SUPER_ADMIN() && showInvoiceBtn && (
        <button
          className="action-button h-12-600"
          ref={invoiceCopyRef}
          title="Copy Invoice"
          onClick={() => {
            handleCopyInvoice()
          }}
        >
          <Overlay target={invoiceCopyRef.current} show={invoiceCopied} placement="bottom">
            <Tooltip>
              Invoice Copied
            </Tooltip>
          </Overlay>
          <FaCopy
            size={14}
            className="cursor-pointer"
          /><span>Copy Invoice</span>
        </button>
      )}
    </div>
  )
}

export default ({
  session,
  studio,
  findFreelancer = () => { }
}) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const [emailCheckinLink, setEmailCheckinLink] = useState('')
  const [emailSessionLink, setEmailSessionLink] = useState('')
  const [emailSessionParams, setEmailSessionParams] = useState(null)
  const [kioskCopied, setKioskCopied] = useState(false)
  const dispatch = useDispatch()
  const path = useLocation()
  const history = useHistory()
  const kioskCopyRef = useRef(null)

  const mounted = useCallback(() => {
    const focused_session_id = new URLSearchParams(path.search).get('focused_session')
    setShowEditModal(focused_session_id == session._id)
  }, [])

  useEffect(() => {
    mounted()
  }, [mounted])
  const onDeleteClick = () => {
    setShowConfirmModal(true)
  }
  const onDeleteConfirmYesClick = async () => {
    const res = await deleteSession(session._id)
    const idx = studio.sessions.findIndex(it => it._id == res._id)
    const sessions = [...studio.sessions]
    sessions.splice(idx, 1)
    const temp = { ...studio }
    temp.sessions = sessions
    dispatch(updateStudioInStore(temp))
    setShowConfirmModal(false)
  }

  let inviteManager = true

  const handleOpenSendEmail = (dt) => {
    const onboardInTime = session.dates.find(d => {
      return d.start_time === dt
    })
    const st = onboardInTime || session.dates[0]
    if (st) {
      setEmailSessionParams(st)
      setEmailSessionLink(`${host}/studio/${studio.uri}/${session._id}`)
    }
  }

  const copyText = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setKioskCopied(true)
      setTimeout(() => { setKioskCopied(false)}, 1000)
    }).catch((err) => {
      window.alert('Failed to copy link')
    })
  }

  return (
    <div className="session-item">
      <div className="d-flex justify-content-between w-100 flex-column flex-sm-row">
        <div className='session-item-left d-flex align-items-center'>
          <div className='d-flex align-items-center mr-1'>
            <span className='h-14-600 ls-2 mr-1'>{session.name}</span>
            {session.twr && (
              <FaListAlt
                size="11"
                className="mx-2"
                title={`TWR - ${session.twr}`}
              />
            )}
            <span className='text-secondary'>·</span>
          </div>
          <div className='d-flex flex-wrap'>
            {session.dates.map((st, idx) => {
              const stt = st.start_time_type
              let sttClsName = ''
              switch (stt) {
                case SESSION_TIME_TYPE[1]:
                  sttClsName = 'text-danger'
                  break
                default:
                  sttClsName = ''
                  break
              }
              if(st.location_type === SESSION_LOCATION_TYPE[3]){
                sttClsName = 'text-green'
              }
              return (
                <a
                  key={idx}
                  className='mr-2 d-flex align-items-center cursor-pointer h-14-400 ls-2 text-secondary underline'
                  title="Send Client Email"
                  onClick={() => {
                    setEmailSessionParams(st)
                    setEmailSessionLink(`${host}/studio/${studio.uri}/${session._id}`)
                  }}
                >
                  <span className={'mr-0 ' + sttClsName}>
                    {moment(new Date(st.start_time)).format('MM/DD')}
                    {idx < session.dates.length - 1}
                  </span>
                </a>
              )
            })}
          </div>
        </div>
        <div className='session-item-right'>
          
          <SessionActionBts studio={studio} session={session} />
          <button
           title="Send Talent Email"
            className='action-button h-12-600'
            onClick={() => {
                  setEmailCheckinLink(`${host}/onboard/${studio.uri}/${session._id}`)
            }}><MailIcon /> <span>Send Talent Email</span> </button>

          
          {inviteManager && (
            <button
              className='action-button h-12-600'
              title="View Project Invites"
              onClick={() => {
                history.push(`/session/${studio.uri}/${session._id}/find-freelancer`)
              }}
            >
              <InviteIcon /> <span>Project Invites</span>
            </button>
          )}

          <button
            title="Schedule"
            className='action-button h-12-600'
            onClick={() => {
              history.push(`/session/${studio.uri}/edit/${session._id}`)
            }}>
            <BiCalendarPlus 
              size="15" 
              title='Schedule'/><span>Schedule</span>
          </button>
          <button
            ref={kioskCopyRef}
            title="Send Talent Kiosk Link"
            className='action-button h-12-600'
            onClick={() => {
              copyText(`${host}/onboard/${studio.uri}/${session._id}?kiosk=true`)
            }}>
              <Overlay target={kioskCopyRef.current} show={kioskCopied} placement="bottom">
                <Tooltip>
                  Link Copied
                </Tooltip>
              </Overlay>
              <KioskIcon />
          </button>
          <div className="action-wrap">

            {USER_TYPE.IS_SUPER_ADMIN() && (
              <button
                title="Delete Session"
                className='action-button'
                onClick={onDeleteClick}>
                  <SmallTrashIcon />
              </button>
            )}
          </div>
        </div>
      </div>
      
      <SessionCrupdateModal
        show={showEditModal}
        showSendEmail={true}
        onHide={(_, openSendEmail) => {
          setShowEditModal(false)
          if (openSendEmail) {
            handleOpenSendEmail(openSendEmail)
          }
        }}
        session={session}
        studio={studio}
      />
      <Modal
        show={showConfirmModal}
        onHide={() => { setShowConfirmModal(false) }}
      >
        <Modal.Header closeButton>
          <h5 className="mb-0">
            {`Want to delete?`}
          </h5>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="danger"
            className="btn-w-md"
            onClick={onDeleteConfirmYesClick}
          >
            Yes
          </Button>
          <Button
            variant="light"
            className="btn-w-md"
            onClick={() => { setShowConfirmModal(false) }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <SendTalentEmailModal
        show={!!emailCheckinLink}
        onHide={() => {
          setEmailCheckinLink(null)
        }}
        emailCheckinLink={emailCheckinLink}
        studio={studio}
      />

      <SendClientEmailModal
        show={!!emailSessionParams && !!emailSessionLink}
        onHide={() => {
          setEmailSessionLink(null)
          setEmailSessionParams(null)
        }}
        studio={studio}
        session={session}
        emailSessionParams={emailSessionParams}
        emailSessionLink={emailSessionLink}
      />
    </div>
  )
}