import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { listRequests } from '../../../services'
import {
  FaComment,
  FaEye,
  FaMinus,
  FaQuestion,
  FaRegFrown,
  FaRegGrinBeam,
  FaThumbsDown,
  FaThumbsUp
} from 'react-icons/fa'
import Pagination from '../../../components/Pagination'
import { Link } from 'react-router-dom'

const RequestTable = ({ user }) => {
  const [requests, setRequests] = useState([])
  const [page, setPage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [perPage, setPerPage] = useState(20)

  const loadData = async () => {
    const { count, requests } =  await listRequests({
      requested_person: user._id,
      skip: page * perPage,
      take: perPage
    })
    setRequests(requests)
    setPageCount(Math.ceil(count / perPage))
  }

  useEffect(() => {
    if (user && user._id) {
      loadData()
    }
  }, [user, page, perPage])

  const isFutureDateUnavailable = (session) => {
    const currentDate = moment.tz(new Date(), 'America/Los_Angeles').startOf('day')
    
    return session.dates.every(date =>  {
      const sessionDate = moment.tz(new Date(date.start_time), 'America/Los_Angeles').startOf('day')
      return sessionDate.isBefore(currentDate)
    })
  }

  return (
    <div>
      <label className='h5 mb-3'>Booking Requests</label>
      <table className='w-100 table table-striped'>
        <thead>
          <tr>
            <th>Session Date(s)</th>
            <th>Project</th>
            <th>Response</th>
            <th>Book Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {requests.map(req => {
            return (
              <tr key={req._id}>
                <td>{ req.session.dates.map(d => {
                  return moment(new Date(d.start_time)).format('MM/DD/YY')
                }).join(', ') }</td>
                <td>{ req.session.studio.name } { req.session.name }</td>
                <td>
                  <div
                    className="text-center freelancer-response-col mr-4"
                    title={req.response || 'Not responded yet'}
                  >
                    {(() => {
                      if (req.response === 'yes') {
                        return (
                          <FaThumbsUp
                            size={20}
                            color="#fe0923"

                          />
                        )
                      } else if (req.response === 'no') {
                        return (
                          <FaThumbsDown
                            size={20}
                            color="#fe0923"

                          />
                        )
                      } else if (req.response === 'second-hold') {
                        return (
                          <FaMinus
                            size={20}
                            color="#fe0923"
                            title="Second Hold"
                          />
                        )
                      } else {
                        return (
                          <FaQuestion
                            size={20}
                            color="#fe0923"
                          />
                        )
                      }
                    })()}
                  </div>
                </td>
                <td>
                <div className="text-center book-status-col">
                  {(() => {
                    if (req.status === 'book') {
                      return (
                        <FaRegGrinBeam
                          size={20}
                          color="#fe0923"
                          title="Booked"
                        />
                      )
                    } else if (req.status === 'reject') {
                      return (
                        <FaRegFrown
                          size={20}
                          color="#fe0923"
                          title="Reject"
                        />
                      )
                    } else if(isFutureDateUnavailable(req.session) || req.session.studio.is_archived){
                        return (
                          <FaThumbsDown
                            size={20}
                            color="#fe0923"
                            title="Not booked"
                          />
                        )
                    }
                    else {
                      return (
                        <FaQuestion
                          size={20}
                          color="#fe0923"
                          title="Not determined"
                        />
                      )
                    }
                  })()}
                </div>
                </td>
                <td>
                  <Link to={`/freelancer-requests/${req._id}`}>
                    <FaEye />
                  </Link>
                </td>
              </tr>
            )
          })}
          {requests.length === 0 && (
            <tr>
              <td colSpan={4}>
                No requests yet
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {requests.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          pageCount={pageCount}
        />
      )}
    </div>
  )
}

export default RequestTable
