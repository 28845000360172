import React from 'react'

const AuthFooter = ({ child }) => {
  return (
    <div className="vw-100 p-3 row border-top">
      <a className='col-md-4 text-left my-1' >@{new Date().getFullYear()} North Shore Media, LLC. All rights reserved</a>
      <a href="https://heyjoe.io/privacy-policy/" target="_blank" className='col-md-4 text-md-center my-1' >Privacy Policy</a>
      <a href="https://heyjoe.io/terms-and-conditions/" target="_blank" className='col-md-4 text-md-right my-1' >Terms & Conditions</a>
    </div>
  )
}

export default AuthFooter
