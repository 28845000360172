import { api_host, token } from "./consts";

export const scheduleAppointment = async ({
  session_id,
  day_id,
  availability_id,
  body: {
    talent_id,
    date,
    start_time,
    end_time,
    length_time_slot,
    time_zone,
    role,
    location_type,
    in_person_location
  },
}) => {
  try {
    const resp = await fetch(
      `${api_host}/appointment/${session_id}/${day_id}/${availability_id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          talent_id,
          date,
          start_time,
          end_time,
          length_time_slot,
          time_zone,
          role,
          location_type,
          in_person_location
        }),
      }
    );
    if (!resp.ok) {
        const errorData = await resp.json();
        throw new Error(errorData?.error?.message); // or whatever property the backend sends the error message in
    }
    return await resp.json();
  } catch (e) {
    throw e;
  }
};

export const cancelAppointment = async ({ session_id,
  day_id,
  id}) => {
  console.log("canceling time slot");
  try {
    const resp = await fetch(`${api_host}/appointment/${session_id}/${day_id}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!resp.ok) {
        const errorData = await resp.json();
        throw new Error(errorData?.error?.message); // or whatever property the backend sends the error message in
    }
    return await resp.json();
  } catch (e) {
    throw e;
  }
}

export const updateAppointment = async ({ 
  session_id,
  day_id,
  availability_id,
  appointment_id,
  body: {
    talent_id,
    date,
    start_time,
    end_time,
    length_time_slot,
    time_zone,
    role,
  }}) => {
  console.log("updating time slot");
  try {
    const resp = await fetch(`${api_host}/appointment/${session_id}/${day_id}/${availability_id}/${appointment_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        talent_id,
        date,
        start_time,
        end_time,
        length_time_slot,
        time_zone,
        role,
      }),
    });
    if (!resp.ok) {
        const errorData = await resp.json();
        throw new Error(errorData?.error?.message); // or whatever property the backend sends the error message in
    }
    return await resp.json();
  } catch (e) {
    throw e;
  }
}
