import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import moment from "moment-timezone";
import { useAuthedUser } from "../../../../hooks/auth";

import {
  getOneSession,
  getStudioByUri,
  addTimeFrames,
  getTimeFrames,
  getSessionRoles,
  getTimeFrame,
  updateTimeFrame,
  updateAppointment,
  deleteTimeFrame,
} from "../../../../services";
import { TitleContext } from "../../../../Context";
import { sendClientEmail } from "../../../../services";

const useSchedule = ({ match }) => {
  const studio_uri = match.params.uri;
  const session_id = match.params.session_id;
  const day_id = match.params.day_id;
  const time_zone = moment.tz.guess();
  const authUser = useAuthedUser();

  const context = useContext(TitleContext);
  const [studio, setStudio] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [currentDate, setCurrentDate] = useState(null);
  const[numberOfTimeFrames, setNumberOfTimeFrames] = useState(0)
  const [dayIndex, setDayIndex] = useState(0);
  const [showEmptyState, setShowEmptyState] = useState(true);
  const [isEnableShare, setIsEnableShare] = useState(false);

  const [availableTimeFrames, setAvailableTimeFrames] = useState({});
  const [rolesArray, setRolesArray] = useState([
    { role: "", participants_count: 1 },
  ]);
  const [timeFramesStartEndDates, setTimeFramesStartEndDates] = useState([]);
  const [scheduleAppointmentRolesDetail, setScheduleAppointmentRolesDetail] =
    useState({});

  const [hiringRoles, setHiringRoles] = useState([]);

  const [addOrEditTimeFrame, setAddOrEditTimeFrame] = useState("add");
  const [newlyCreatedTimeFrameId, setNewlyCreatedTimeFrameId] = useState(null);

  const now = useMemo(() => {
    if (timeFramesStartEndDates.length > 0 && addOrEditTimeFrame === "add") {
      const frame = timeFramesStartEndDates[timeFramesStartEndDates.length - 1];
      return moment(frame.end_time).add(-15, "minutes");
    }
    return moment();
  }, [timeFramesStartEndDates, addOrEditTimeFrame]);

  const remainder = useMemo(() => 15 - (now.minute() % 15), [now]);
  const roundedStartTime = useMemo(
    () => moment(now).add(remainder, "minutes").startOf("minute"),
    [now, remainder]
  );
  const roundedEndTime = useMemo(
    () =>
      moment(now)
        .add(remainder, "minutes")
        .add(60, "minutes")
        .startOf("minute"),
    [now, remainder]
  );

  const [defaultValues, setDefaultValues] = useState({
    name: `Time Frame ${numberOfTimeFrames +1}`,
    startTime: roundedStartTime.toDate(),
    endTime: roundedEndTime.toDate(),
    timeSlot: { label: "15 minutes", value: 15 },
    roles: [{ roleName: { value: "", label: "" }, roleNumber: 1 }],
    // Add other fields here...
  });
  const resetDefaultValues = useCallback(() => {
    setDefaultValues({
      name: `Time Frame ${numberOfTimeFrames +1}`,
      startTime: roundedStartTime.toDate(),
      endTime: roundedEndTime.toDate(),
      timeSlot: { label: "15 minutes", value: 15 },
      roles: [{ roleName: { value: "", label: "" }, roleNumber: 1 }],
      // Add other fields here...
    });
  },[numberOfTimeFrames])
  useEffect(() => {
    resetDefaultValues()
  },[numberOfTimeFrames])
  useEffect(() => {
    if (addOrEditTimeFrame === "add") {
      setDefaultValues((prev) => {
        return {
          ...prev,
          startTime: roundedStartTime.toDate(),
          endTime: roundedEndTime.toDate(),
        };
      });
    }
  }, [roundedEndTime, roundedStartTime, addOrEditTimeFrame]);
  const [editTimeFrameId, setEditTimeFrameId] = useState(null);

  const [dragAndDropAppointment, setDragAndDropAppointment] = useState(null);
  const [appointmentScheduledTimeFrame, setAppointmentScheduledTimeFrame] =
    useState({
      start: null,
      end: null,
      duration: null,
      isAppointmentAvailable: false,
    });

  const [emailData, setEmailData] = useState({});
  const [showEmailModal, setShowEmailModal] = useState(false);

  const [deleteTimeFrameAvailabilityId, setDeleteTimeFrameAvailabilityId] =
    useState(null);

  useEffect(() => {

  }, [addOrEditTimeFrame]);  

  useEffect(() => {
    const loadData = async () => {
      try {
        const session = await getOneSession(session_id);
        const studio = await getStudioByUri(studio_uri);
        setStudio(studio);

        session.dates.map((date, index) => {
          if (date._id === day_id) {
            setCurrentDate(date);
            setDayIndex(index);
          }
        });
      } catch (e) {
        console.log("error:>", e);
      }
    };
    loadData();
  }, [session_id, day_id, studio_uri]);
  const cleanStateBeforeLoadTimeFrames = useCallback(() => {
    setTimeFramesStartEndDates([]);
    setScheduleAppointmentRolesDetail({});
  },[]);
  const loadTimeFrames = useCallback(async () => {
    const _timeFrames = await getTimeFrames({ session_id, day_id, time_zone });
    cleanStateBeforeLoadTimeFrames();
    setAvailableTimeFrames(_timeFrames.data);


    if (Object.keys(_timeFrames.data).length > 0) {
      setShowEmptyState(false);
    } else {
      setShowEmptyState(true);
    }
  }, [session_id, day_id, time_zone]);

  useEffect(() => {
    if (Object.keys(availableTimeFrames).length > 0) {
      setIsEnableShare(true);
      resetTimeFramesStartEndDates(availableTimeFrames);

      
    } else {
      setIsEnableShare(false);
    }
  }, [availableTimeFrames]);
  const resetTimeFramesStartEndDates = useCallback((availableTimeFrames) => {
    if (Object.keys(availableTimeFrames).length > 0) {
      const _timeFramesStartEndDates = [];
      Object.keys(availableTimeFrames).map((date) => {
        setNumberOfTimeFrames(Object.keys(availableTimeFrames[date]).length)
        Object.keys(availableTimeFrames[date]).map((timeFrame) => {
          const timeFrameData = availableTimeFrames[date][timeFrame];

          _timeFramesStartEndDates.push({
            availability_id: timeFrame,
            start_time: timeFrameData.start_time,
            end_time: timeFrameData.end_time,
            duration: timeFrameData.length_time_slot,
          });

        });
      });
      setTimeFramesStartEndDates(_timeFramesStartEndDates);
    }
  },[]);
  

  const loadHiringRole = useCallback(async () => {
    const hiringRoles = await getSessionRoles(session_id);
    setHiringRoles(hiringRoles);
  }, [session_id]);
  useEffect(() => {
    if (session_id) {
      loadHiringRole();
    }
  }, [session_id, loadHiringRole]);

  useEffect(() => {
    if (session_id && day_id) {
      loadTimeFrames();
    }
  }, [session_id, day_id, time_zone]);
  useEffect(() => {
    const title = studio ? studio.name : "";
    document.title = title;
    context.setTitle(title);
  }, [studio]);

  const onAddOrEditTimeFrame = async (data) => {
    const start_time = moment(data.startTime);
    let end_time = moment(data.endTime);
    const length_time_slot = Number(`${data.timeSlot.value}`);
    const result = end_time.diff(start_time, "minutes")%length_time_slot;
    if (result < length_time_slot) {
      end_time = moment(end_time).add(-Number(result), "minutes");
    }

    let timeFrameData = {
      name: data.name,
      date: moment(currentDate.start_time).format("YYYY-MM-DD"),
      start_time: start_time.format("HH:mm"),
      end_time: end_time.format("HH:mm"),
      length_time_slot: `${data.timeSlot.value}`,
      time_zone: time_zone,
      roles: data.roles.map((role) => {
        return {
          role: role.roleName.value,
          participants_count: role.roleNumber,
        };
      }),
      studio_id: studio._id,
    };
    if (addOrEditTimeFrame === "add") {
      const response = await addTimeFrames(session_id, day_id, timeFrameData);
      setNewlyCreatedTimeFrameId(response?.data?._id);
      setAddOrEditTimeFrame("add");
    } else {
      await updateTimeFrame({
        session_id,
        day_id,
        availability_id: editTimeFrameId,
        timeFrame: timeFrameData,
      });
      setAddOrEditTimeFrame("add");
      setEditTimeFrameId(null);
    }

    await loadTimeFrames();
  };
  const refreshTimeFrames = async () => {
    await loadTimeFrames();
  };
  const onEditTimeFrame = (timeFrame, date) => {
    setScheduleAppointmentRolesDetail({});
    setEditTimeFrameId(timeFrame);
    loadTimeFrame(timeFrame, date);
  };
  const loadTimeFrame = async (availability_id, date) => {
    const timeFrame = await getTimeFrame({
      session_id,
      day_id,
      availability_id,
    });

    if (timeFrame?.data) {
      setAddOrEditTimeFrame("edit");
      setShowModal(true);
      setDefaultValues({
        name: timeFrame.data.name,
        startTime: moment(timeFrame.data.date_rules.start_time, "HH:mm")
          .tz(timeFrame.data.date_rules.time_zone)
          .toDate(),
        endTime: moment(timeFrame.data.date_rules.end_time, "HH:mm")
          .tz(timeFrame.data.date_rules.time_zone)
          .toDate(),
        timeSlot: {
          label: `${timeFrame.data.date_rules.length_time_slot} minutes`,
          value: timeFrame.data.date_rules.length_time_slot,
        },
        roles: timeFrame.data.roles.map((role) => {
          return {
            roleName: { value: role.role, label: role.role },
            roleNumber: role.participants_count,
            lock: true,
          };
        }),
        // Add other fields here...
      });

      let isSlotStartSet = false;
      const timeFrameData = availableTimeFrames[date][availability_id];
      timeFrameData.slots.map((slot) => {
        Object.keys(slot?.talents_with_roles).map((role) => {
          if (slot.talents_with_roles[role]?.talents?.length > 0) {
            if (
              slot.talents_with_roles[role].talents.length ===
              slot.talents_with_roles[role].participants_count
            ) {
              setScheduleAppointmentRolesDetail((prev) => {
                return {
                  ...prev,
                  [role]: {
                    isAnyAppointmentAvailable: true,
                    appointment: "filled",
                    count: slot.talents_with_roles[role].talents.length,
                  },
                };
              });
            } else if (
              0 <
              slot.talents_with_roles[role].talents.length <
              slot.talents_with_roles[role].participants_count
            ) {
              setScheduleAppointmentRolesDetail((prev) => {
                return {
                  ...prev,
                  [role]: {
                    isAnyAppointmentAvailable: true,
                    appointment: "partiallyFilled",
                    count: slot.talents_with_roles[role].talents.length,
                  },
                };
              });
            }
            if (!isSlotStartSet) {
              isSlotStartSet = true;
              setAppointmentScheduledTimeFrame((prev) => {
                return {
                  ...prev,
                  start: slot.start,
                  duration: timeFrameData.length_time_slot,
                  isAppointmentAvailable: true,
                };
              });
            }
            setAppointmentScheduledTimeFrame((prev) => {
              return { ...prev, end: slot.end };
            });
          }
        });
      });
    }
    const _timeFramesStartEndDate= timeFramesStartEndDates.filter((frameStartEndDate) => {
      return frameStartEndDate.availability_id !== availability_id;
    });
    setTimeFramesStartEndDates(_timeFramesStartEndDate);
  };
  useEffect(() => {
    if (!showModal) {
      setAddOrEditTimeFrame("add");
      resetDefaultValues();
      setScheduleAppointmentRolesDetail({});
      setAppointmentScheduledTimeFrame({
        start: null,
        end: null,
        duration: null,
        isAppointmentAvailable: false,
      })
      resetTimeFramesStartEndDates(availableTimeFrames)
    } else{
      resetDefaultValues();
    }
  }, [showModal]);
  const onUpdateAppointment = async (
    {
      appointment_id,
      talent_id,
      date,
      start_time,
      end_time,
      length_time_slot,
      time_zone,
      role,
      availability_id,
    },
    data
  ) => {
    try {
      const response = await updateAppointment({
        appointment_id,
        session_id,
        day_id,
        availability_id: availability_id,
        body: {
          talent_id: talent_id,
          date,
          start_time,
          end_time,
          length_time_slot,
          time_zone,
          role,
        },
      });
      await sendClientEmail(data);
      onEmailModalHide();
      await loadTimeFrames();
    } catch (error) {}
  };
  const onDragAndDropAppointment = (item, monitor, dropResult) => {
    const { talent } = item;
    const { targetTalentsWithRole, targetTimeSlot, lengthTimeSlot, date } =
      dropResult;
    const startDate = moment.tz(
      `${date} ${targetTimeSlot.start}`,
      "YYYY-MM-DD hh:mm A",
      time_zone
    );
    const endDate = moment.tz(
      `${date} ${targetTimeSlot.end}`,
      "YYYY-MM-DD hh:mm A",
      time_zone
    );

    setDragAndDropAppointment({
      appointment_id: talent.appointmentId,
      talent_id: talent.talent._id,
      date: moment(startDate).tz(talent.time_zone).format("YYYY-MM-DD"),
      start_time: moment(startDate).tz(talent.time_zone).format("hh:mm A"),
      end_time: moment(endDate).tz(talent.time_zone).format("hh:mm A"),
      length_time_slot: lengthTimeSlot,
      time_zone: talent.time_zone,
      role: targetTalentsWithRole,
      availability_id: targetTimeSlot.availability_id,
      talent: talent.talent,
    });
    sendUpdateAppointmentUpdateEmail(targetTimeSlot.availability_id);
  };

  const sendUpdateAppointmentUpdateEmail = async (availability_id) => {
    setEmailData((prev) => {
      return {
        ...prev,
        action: "dragAndDropAppointment",
        onlyEmail: false,
        emailModalName: "Send Talent Email",
        template: "TALENT_RESCHEDULE_APPOINTMENT",
        emailCheckinLink: `${window.location.origin}/schedule/${studio.uri}/${session_id}/${day_id}/${availability_id}`,
      };
    });
    setShowEmailModal(true);
  };
  useEffect(() => {
    if (dragAndDropAppointment) {
      setEmailData((prev) => {
        return {
          ...prev,
          recipients: [dragAndDropAppointment?.talent?.email],
          action: "dragAndDropAppointment",
          onlyEmail: false,
          appointment: {
            date: dragAndDropAppointment.date,
            time: dragAndDropAppointment.start_time,
            name: `${dragAndDropAppointment?.talent?.first_name} ${dragAndDropAppointment?.talent?.last_name}`,
            role: dragAndDropAppointment.role,
          },
        };
      });
      setShowEmailModal(true);
    }
  }, [dragAndDropAppointment]);

  const onEmailModalHide = () => {
    setShowEmailModal(false);
    setEmailData(null);
  };
  const onEmailSend = (data, action) => {
    if (action === "dragAndDropAppointment") {
      onUpdateAppointment(dragAndDropAppointment, data);
    } else if (action === "deleteTimeFrame") {
      onDeleteTimeFrameAndSendEmail(
        { session_id, day_id, availability_id: deleteTimeFrameAvailabilityId },
        data
      );
    }
  };
  const getTimeFrameTalentsEmailList = useCallback((availability_id, availableTimeFrames) => {
    let talentsList = [];
    if (Object.keys(availableTimeFrames).length > 0) {
      
      Object.keys(availableTimeFrames).map((date) => {
        Object.keys(availableTimeFrames[date]).map((timeFrame) => {
          const timeFrameData = availableTimeFrames[date][timeFrame];

          if (timeFrame === availability_id) {
            timeFrameData.slots.map((slot) => {
              Object.keys(slot?.talents_with_roles).map((role) => {
                if (slot.talents_with_roles[role]?.talents?.length > 0) {
                  talentsList= [...talentsList, ...slot.talents_with_roles[role].talents];
                }
              });
            });
          }

        });
      });
    }
    return talentsList;
  },[])
  const onDeleteTimeFrame = async (availability_id, date, start,end) => {
    console.log(availability_id)
    setDeleteTimeFrameAvailabilityId(availability_id);
    const talentsEmailList = getTimeFrameTalentsEmailList(availability_id,availableTimeFrames).map(
      (talent) => talent.talent.email
    );
    if (talentsEmailList.length === 0) {
      onDeleteTimeFrameAndSendEmail({
        session_id,
        day_id,
        availability_id: availability_id,
      });
    } else {
      setEmailData((prev) => {
        return {
          ...prev,
          action: "deleteTimeFrame",
          emailModalName: "Send Talent Email",
          onlyEmail: false,
          template: "DELETE_TIME_FRAME",
          emailCheckinLink: `${window.location.origin}/schedule/${studio.uri}/${session_id}/${day_id}/${availability_id}`,
          recipients: talentsEmailList,
          appointment:{
            date: date,
            time: `${start} - ${end}`,
          }
        };
      });
      setShowEmailModal(true);
    }
  };

  const onDeleteTimeFrameAndSendEmail = async (
    { session_id, day_id, availability_id },
    data
  ) => {
    const response = await deleteTimeFrame({
      session_id,
      day_id,
      availability_id,
    });
    if (data) {
      await sendClientEmail(data);
    }

    onEmailModalHide();
    await loadTimeFrames();
  };
  const onShareDayAppointment = () => {
    setEmailData((prev) => {
      return {
        ...prev,
        action: "shareTimeFrame",
        onlyEmail: true,
        emailModalName: "Sending a link to schedule",
        template: "SHARE_SCHEDULE_LINK",
        emailCheckinLink: `${window.location.origin}/schedule/${studio.uri}/${session_id}/${day_id}`,
        shareScheduler: {
          sessionDate: moment(currentDate?.start_time).format("YYYY-MM-DD"),
          castingDirectorEmail: authUser?.email,
        },
        recipients: [],
      };
    });
    setShowEmailModal(true);
  };
  const onShareTimeFrameAppointment = (availability_id) => {
    setEmailData((prev) => {
      return {
        ...prev,
        action: "shareTimeFrame",
        emailModalName: "Sending a link to schedule",
        onlyEmail: true,
        template: "SHARE_SCHEDULE_LINK",
        emailCheckinLink: `${window.location.origin}/schedule/${studio.uri}/${session_id}/${day_id}/${availability_id}`,
        recipients: [],
        shareScheduler: {
          sessionDate: moment(currentDate?.start_time).format("YYYY-MM-DD"),
          castingDirectorEmail: authUser?.email,
        },
      };
    });
    setShowEmailModal(true);
  };

  return {
    studio,
    context,
    currentDate,
    studio_uri,
    session_id,
    day_id,
    dayIndex,
    showEmptyState,
    availableTimeFrames,
    showModal,
    rolesArray,
    hiringRoles,
    timeFramesStartEndDates,
    defaultValues,
    addOrEditTimeFrame,
    appointmentScheduledTimeFrame,
    emailData,
    showEmailModal,
    scheduleAppointmentRolesDetail,
    isEnableShare,
    newlyCreatedTimeFrameId,
    setRolesArray,
    onAddOrEditTimeFrame,
    setShowModal,
    refreshTimeFrames,
    loadHiringRole,
    onEditTimeFrame,
    onDragAndDropAppointment,
    onEmailModalHide,
    onEmailSend,
    onDeleteTimeFrame,
    onShareDayAppointment,
    onShareTimeFrameAppointment,
  };
};
export default useSchedule;
