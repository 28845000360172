import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  getAvailability,
  getUserAppointment,
} from "../../../services";
import { useAuthedUser } from "../../../hooks/auth";
import momentTz from "moment-timezone";
const useTalentSchedule = ({match}) => {
  const studio_uri = match?.params?.uri;
  const session_id = match?.params?.session_id;
  const availability_id = match?.params?.availability_id;
  const day_id = match?.params?.day_id;
  const time_zone = momentTz.tz.guess();
  const { id: talent_id } = useAuthedUser();

  const [availableTimeFrames, setAvailableTimeFrames] = useState([]);
  const [sessionName, setSessionName] = useState("Session Name");
  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(null);
  const [selectedTimeFrameDates, setSelectedTimeFrameDates] = useState([])
  const [formattedSelectedTimeFrameDates, setFormattedSelectedTimeFrameDates] = useState([]); 
  const [createdBy, setCreatedBy] = useState(null)
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [currentDate, setCurrentDate] = useState(null)

  const [selectTimeSlot, setSelectTimeSlot] = useState(null);

  const [auditionData, setAuditionData] = useState({
        session_id: null,
        day_id: null,
        slot:null,
        role:null,
        date:null,
  });
  const [userAppointment, setUserAppointment] = useState(null)

  const createRoleOptions = useCallback((availabilities) => {
    let _roles = [];
    Object.keys(availabilities).map((key) => {
        _roles.push({
            label: key,
            value: key,
        });
    })
    setRoleOptions(_roles);
  }, []);
  const loadAppointment = useCallback(async ({day_id,session_id, talent_id})=>{
    const resp = await getUserAppointment({day_id,session_id, talent_id})
    setUserAppointment(resp?.data)
  },[])
  useEffect(()=>{
    loadAppointment({day_id,session_id, talent_id})
  },[day_id,session_id, talent_id])

  const loadData = useCallback(async ({session_id, day_id, time_zone}) => {
    const _timeFrames = await getAvailability({
      session_id,
      day_id,
      time_zone,
      availability_id,
    });
    setAvailableTimeFrames(_timeFrames.data);
    setCurrentDate(_timeFrames.data?.session?.currentDate)
  }, []);
  useEffect(() => {
    if (session_id && day_id && time_zone) {
      loadData({session_id, day_id, time_zone});
    }
  }, [session_id, day_id, time_zone]);

  useEffect(()=>{
    if(availableTimeFrames){
                if(availableTimeFrames.session?.name){
            setSessionName(availableTimeFrames.session.name)
        }
        if(availableTimeFrames.availabilities){
            createRoleOptions(availableTimeFrames.availabilities)
        }
        if(availableTimeFrames?.createdBy){
            setCreatedBy(availableTimeFrames.createdBy)
        }
        if(availableTimeFrames.availabilities){
          const roles = Object.keys(availableTimeFrames.availabilities)
          const lastRole = roles[roles.length - 1]
          const allDates = Object.keys(availableTimeFrames.availabilities[lastRole])
          const lastDate = allDates[allDates.length - 1]
          const lastTimeSlots = availableTimeFrames.availabilities[lastRole][lastDate]
          const lastTimeSlot = lastTimeSlots[lastTimeSlots.length - 1]
          const lastTimeSlotDate = momentTz.tz(
            `${lastDate} ${lastTimeSlot.end}`,
            "YYYY-MM-DD hh:mm A",
            time_zone
          );
          const now = momentTz.tz();
          if (now.isAfter(lastTimeSlotDate)) {
            setIsSessionExpired(true);
          }
        }
        
       
       
    }
  },[availableTimeFrames])

  const selectRole = useCallback((role) => {
    setSelectedRole(role);
  },[])

  useEffect(()=>{
    if(selectedRole){
        setSelectTimeSlot(null);
        setSelectedTimeFrame(availableTimeFrames.availabilities[selectedRole])
    }
  },[selectedRole])

  useEffect(()=>{

    if(selectedTimeFrame){
        const dates = Object.keys(selectedTimeFrame)
        const formattedDates = []
        dates.map((date)=>{
            formattedDates.push(momentTz(date).format('MMMM D, YYYY'))
        })
        setSelectedTimeFrameDates(dates)
        setFormattedSelectedTimeFrameDates(formattedDates)
        
    }
  },[selectedTimeFrame])
  
  const onSelectTimeSlot = useCallback((timeSlot) => {
    setSelectTimeSlot(timeSlot);
  },[])
  useEffect(()=>{
    if(selectTimeSlot){
        setAuditionData({
            session_id: session_id,
            day_id: day_id,
            slot:selectTimeSlot.slot,
            role:selectedRole,
            date:selectTimeSlot.date,
        })
    }
  },[selectTimeSlot])

  const clearAuditionData = useCallback(() => {
    setSessionName("Session Name")
    setAvailableTimeFrames([])
    setSelectedTimeFrame(null)
    setSelectedTimeFrameDates([])
    setRoleOptions([])
    setSelectedRole(null)
    setFormattedSelectedTimeFrameDates([])
    setSelectTimeSlot(null)
    setAuditionData({
        session_id: null,
        day_id: null,
        slot:null,
        role:null,
        date:null,
    })
    setCurrentDate(null)
    loadData({session_id, day_id, time_zone});
    loadAppointment({day_id,session_id, talent_id})
    
  },[session_id, day_id, time_zone])
  return {
    isSessionExpired,
    selectTimeSlot,
    sessionName,
    roleOptions,
    selectedTimeFrame,
    selectedTimeFrameDates,
    formattedSelectedTimeFrameDates,
    auditionData,
    createdBy,
    userAppointment,
    selectRole,
    onSelectTimeSlot,
    clearAuditionData,
    currentDate
  }
};
export default useTalentSchedule;
