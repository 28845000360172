import React from "react";
import { useDrag } from "react-dnd";
import Avatar from "react-avatar";
import { RemoveIcon } from "../../../../assets/icons/session";
import { static_root } from "../../../../services";

const ScheduledAppointmentItem = ({
  talent,
  onCancelAppointment,
  date,
  setSelectedTalent,
  setShowTalentInfoModal,
  role,
  isAdmin
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "SCHEDULED_APPOINTMENT_ITEM",
    item: {
      talent,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <div className="participant-item">
        <div
          className="participant-item-left  cursor-pointer"
          onClick={() => {
            setSelectedTalent({ ...talent?.talent, role });
            setShowTalentInfoModal(true);
          }}
        >
          {talent?.talent?.logo ? (
            <img
              className="participantAvatar"
              src={`${static_root}${talent?.talent?.logo}`}
              alt="avatar"
            />
          ) : (
            <Avatar
              className="participantAvatar"
              name="Test Client"
              size="18px"
              round="18px"
              textSizeRatio={2}
              // @ts-ignore
              title={false}
            />
          )}
          <span className="h-12-400">
            {talent?.talent?.first_name} {talent?.talent?.last_name}
          </span>
        </div>
        {isAdmin && (
          <div className="participant-item-right">
            <span
              className="h-12-400 cursor-pointer"
              onClick={() =>
                onCancelAppointment({
                  appointmentId: talent.appointmentId,
                  talent,
                  appointmentDate: date,
                })
              }
            >
              <RemoveIcon />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScheduledAppointmentItem;
