import React, { useState } from 'react'
import {
  Tabs,
  Tab,
} from 'react-bootstrap'

import UsersTab  from './Tabs/UsersTab'
import NoticeTab from './Tabs/NoticeTab'
import ServiceTab from './Tabs/ServiceTab'
import EmailTemplatesTab from './Tabs/EmailTemplatesTab'
import CredentialTab from './Tabs/Credentials'

import './style.scss'

const Admin = () => {
  const [tabKey, setTabKey] = useState('users')
  return (
    <div className="p-5 page-content">
      <Tabs
        activeKey={tabKey}
        onSelect={(key)=>{setTabKey(key)}}
      >
        <Tab
          eventKey = "users"
          title = "Users"
          className="py-3"
        >
          <UsersTab />
        </Tab>
        <Tab
          eventKey = "notices"
          title = "Notices"
          className="py-3"
        >
          <NoticeTab />
        </Tab>
        <Tab
          eventKey = "services"
          title = "Services"
          className="py-3"
        >
          <ServiceTab />
        </Tab>
        <Tab
          eventKey="email-templates"
          title="Email Templates"
          className="py-3"
        >
          <EmailTemplatesTab />
        </Tab>
        <Tab
          eventKey="credentials"
          title="Credentials"
          className="py-3"
        >
          <CredentialTab />
        </Tab>
      </Tabs>
    </div>
  )
}

export default Admin
