import React, { useState, useRef, useEffect } from 'react'
import { Form, Container, Row, Col, Modal, Button } from 'react-bootstrap'
import { Editor } from '@tinymce/tinymce-react'
import { sendClientEmail }from '../../services'
import { FaTimes } from 'react-icons/fa'
import { TINYMCE_KEY } from '../../constants'
import { validateEmail, copyHtml } from '../../utils'

const SendPostingLinkEmailModal = ({
  show,
  onHide,
  studio,
  session,
  emailSessionParams,
  emailSessionLink,
}) => {
  const [toAdditional, setToAdditional] = useState([])
  const editorRef = useRef(null)
  const [subject, setSubject] = useState('')
  const [initialEmail, setInitialEmail] = useState('')
  const [isCopied, setIsCopied] = useState(false)
  const [apiError, setApiError] = useState('')

  useEffect(() => {
    if(show){
      setSubject(`Posting Link: ${emailSessionParams.name}`)

      setInitialEmail(`<div id="client-email-text">
      <p><strong>Studio:</strong> ${studio.name}</p>
      <p><strong>Posting Link Name:</strong> ${emailSessionParams.name}</p>
      <p><strong>Posting Link:</strong> <a href="${emailSessionLink}">${emailSessionLink}</a></p>
      </div>`)
    }
    
  }, [show])

  if (!show) return null
  
  const sendEmail= async ()=>{
    const data = {
      from: 'hello@heyjoe.io',
      to: [],
      content: editorRef.current.targetElm.value,
      subject,
    }
    if (studio.casting_directors.length > 0) {
      data.to = data.to.concat((studio.casting_directors).map(it => it.email))
    } else {
      data.to = ['hello@heyjoe.io']
    }
    data.to = data.to.concat(toAdditional)
    
    const res = await sendClientEmail(data).then(res => res.json())

    if (res.ok) {
      onHide()
    } else {
      setApiError('Failed to send email.')
    }
  }
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
    >
      <Modal.Header closeButton className="align-items-baseline">
        <h4 className="mb-0 mr-3">
          Send Posting Link Email
        </h4>
      </Modal.Header>
        <div>
          <Modal.Body>
            <Container>
              <Row>
                <Col md={6}>
                  <fieldset className="border rounded-lg px-3">
                    <legend className="d-inline-block w-auto px-2">To</legend>
                    <Form.Group className='d-flex mb-0 justify-content-between'>
                      <Form.Label className='mb-0 mr-3'>Casting Director</Form.Label>
                      {studio && studio.casting_directors.length === 0 && (
                        <p className="pr-2 mb-1">hello@heyjoe.io</p>
                      )}
                      {studio && studio.casting_directors.map((it, i)=>(
                        <p className='mb-1 mr-2' key={i}>{it.email}</p>
                      ))}
                    </Form.Group>
                    <Form.Group className='mt-2 d-flex justify-content-between mb-1'>
                      <Form.Label className='mb-0'>Additional Emails</Form.Label>
                      <div className='text-right'>
                        {toAdditional?.map((to, idx) => {
                          return <div key={to + idx}>
                            <span>{to}</span>
                            <label className='ml-2 cursor-pointer' onClick={() => {
                              const updatedTos = Object.assign([], toAdditional)
                              updatedTos.splice(idx, 1)
                              setToAdditional(updatedTos)
                            }}><FaTimes /></label>
                          </div>
                        })}
                      </div>
                    </Form.Group>
                    <input id='additional-email-input' placeholder='Type email and hit Enter'
                      type='text' className='form-control mb-3' onKeyPress={ev => {
                      const v = document.querySelector('#additional-email-input').value
                      if (ev.key === 'Enter') {
                        if (validateEmail(v)) {
                          setToAdditional(toAdditional.concat(v))
                          document.querySelector('#additional-email-input').value = ''
                        }
                      }
                    }} />
                  </fieldset>
                </Col>
                <Col md={6}>
                  <fieldset className="border rounded-lg px-3 mb-2">
                    <legend className="d-inline-block w-auto px-2">From</legend>
                    <p className="px-2">hello@heyjoe.io</p>
                  </fieldset>
                </Col>
              </Row>
              <Form.Group className="mt-3">
                <Form.Label>Subject *</Form.Label>
                <Form.Control
                  value={subject}
                  onChange={(e)=>{setSubject(e.target.value)}}
                  required={true}
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label>
                  Email *
                </Form.Label>
                <Editor
                  key={initialEmail}
                  apiKey={TINYMCE_KEY}
                  onInit={(evt, editor) => editorRef.current = editor}
                  initialValue={initialEmail}
                  init={{
                    height: '300px',
                    menubar: false,
                    relative_urls: false,
                    remove_script_host : false,
                    convert_urls : true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}
                />
              </Form.Group>
            </Container>
            {apiError && (
              <p className='text-danger'>
                {apiError}
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="default"
              className="mx-3 px-5"
              onClick={() => {
                copyHtml(editorRef.current.targetElm.value)
                setIsCopied(true)
                setTimeout(() => setIsCopied(false), 3000)
              }}
            >
              {isCopied? 'Copied!': 'Copy Email'}
            </Button>
            <Button
              type="submit"
              variant="primary"
              className="mx-3 px-5"
              onClick={sendEmail}
            >
              Send
            </Button>
          </Modal.Footer>
        </div>
    </Modal>
  )
}

export default SendPostingLinkEmailModal