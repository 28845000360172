import React, { useEffect, useState, useContext } from 'react'
import FindFreelancer from './index'
import { getOneSession, getStudioByUri } from '../../../services'
import { useHistory } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { RightCaret } from "../../../assets/icons/session";
import { TitleContext } from "../../../Context";

export default ({ match }) => {
  const [session, setSession] = useState(null)
  const [studio, setStudio] = useState(null)
  const history = useHistory()
  const context = useContext(TitleContext);

  const studio_uri = match.params.uri
  const session_id = match.params.session_id

  useEffect(() => {
    const loadData = async () => {
      try {
        const studio = await getStudioByUri(studio_uri)
        const session = await getOneSession(session_id)
        setSession(session)
        setStudio(studio)
      } catch (e) {
        history.push('/')
      }
    }
    loadData()
  }, [])

  if (!studio || !session) {
    return <div className='py-3 page-content'>Loading...</div>
  }

  return (
    <Container fluid className='py-3 page-content'>
      <div className="flex align-center gap-2">
        <span
          onClick={() => {
            history.push("/");
          }}
          className="bread-crumb"
        >
          Projects
        </span>
        <RightCaret />
        <span  
          className="bread-crumb"
          onClick={() => {
          history.push(`/session/${studio_uri}/edit/${session_id}`)
        }}>
          {" "}
           {context.title}
        </span>
        <RightCaret />
        <span >Find Freelancer</span>
      </div>
      <FindFreelancer
        sessionId={match.params.session_id}
        studioUri={match.params.uri}
      />
    </Container>
  )
}
