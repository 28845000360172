import React from "react";
import { ExclamationIcon } from "../../../assets/icons/session";
import "./style.scss";

const Account = ({
  oldPassword,
  setOldPassword,
  password,
  setPassword,
  passwordConfirm,
  setPasswordConfirm,
  updateUser,
  saving,
  formError,
}) => {

  

  return (
    <div className="Account">
      <div className="account-top white-bg">

        <div className="input-item">
          <label htmlFor="old_password">Old Password</label>
          <input
            type="password"
            className="form-control"
            id="old_password"
            value={oldPassword}
            onChange={(ev) => setOldPassword(ev.target.value)}
          />
        </div>

        <div className="input-item">
          <label htmlFor="new_password">New Password</label>
          <input
            type="password"
            className="form-control"
            id="new_password"
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
          />
        </div>

        <div className="input-item">
          <label htmlFor="confirm_password">Confirm Password</label>
          <input
            type="password"
            className="form-control"
            id="confirm_password"
            value={passwordConfirm}
            onChange={(ev) => setPasswordConfirm(ev.target.value)}
          />
        </div>
        <div className="d-flex align-items-center">
          {formError && <div className="error-indicator">{formError}</div>}
          <button
            type="submit"
            className="primary-solid-button h-14-700 ml-auto"
            onClick={updateUser}
          >
            {saving ? 'Saving' :'Save'}
          </button>
        </div>
      </div>
      <div className="danger-area h-14-600">
        <ExclamationIcon /> Danger Area
      </div>

      <div className="account-top white-bg">
        <div>
          <h3 className="h-16-600">Delete Account</h3>
          <div className="h-14-400 secondary-color">
            Deleting your account means erasing all your data and withdrawing your participation from upcoming casting sessions. This action cannot be reversed.
          </div>
        </div>
        <button
          type="button"
          className="delete-button br-8 h-14-700 ml-auto"
          onClick={() => {
            window.open('/delete-account', '_self')
          }}>
            Delete Account</button>
      </div>

      {/* <button
        className="btn btn-secondary mt-3"
        onClick={() => {
          setShowPwdFields(!showPwdFields);
          setPassword("");
          setPasswordConfirm("");
        }}
      >
        {showPwdFields ? "Cancel" : "Update Password"}
      </button> */}
    </div>
  );
};

export default Account;
