import React, {
  useContext,
  useEffect,
  useState,
} from 'react'

import {
  useLocation
} from 'react-router-dom'
import {
  useSelector,
  useDispatch,
} from 'react-redux'
import {
  Button,
  Form,
  Modal,
} from 'react-bootstrap'
import { FaSpinner } from 'react-icons/fa'
import {
  getManyStudios,
  generateNewJitsiKey,
  generateNewProjectUri,
  getUser,
  createSession,
  getUserById,
  studioListByManager
} from '../../services'
import './style.scss'
import Footer from '../../components/Footer'
import {
  STUDIO_LIST_PERMISSIONS, USER_TYPES,
} from '../../constants'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Pagination from '../../components/Pagination'
import StudioCrupdateModal from './StudioCrupdateModal'
import SessionCrupdateModal from './SessionCrupdateModal'
import Studio from './Studio'

import { set as setStudios } from '../../store/studios'
import { useHistory } from 'react-router-dom'
import { TitleContext } from '../../Context'

const host = window.location.origin

const PAGE_SIZE = 10
const StudioList = () => {
  const [searchKey, setSearchKey] = useState('')
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [archive, setArvhice] = useState(false)
  const [newStudioInfo, setNewStudioInfo] = useState(null)
  const [showSessionEditModal, setShowSessionEditModal] = useState(false)
  const [user, setUser] = useState(null)
  const [findFreelancerFor, setFindFreelancerFor] = useState(null)
  const [showStarterVideo, setShowStarterVideo] = useState(false)
  const [isChecked, setIsChecked] = useState(false);

  const [studio, setStudio] = useState(null)
  const [session, setSession] = useState(null)
  const history = useHistory()
  const context = useContext(TitleContext)

  const studios = useSelector(state => state.studios)
  const dispatch = useDispatch()
  const path = useLocation()

  const fetchManyStudios = () => {
    if(user && user.user_type === USER_TYPES.SESSION_MANAGER){
      studioListByManager(page, PAGE_SIZE, searchKey, archive).then(res => {
        dispatch(setStudios(res))
        setLoading(false)
      })
    } else{
      setLoading(true)
      const studioId = new URLSearchParams(path.search).get('studio')
      getManyStudios({
        page,
        pageSize: PAGE_SIZE,
        searchKey,
        archived: archive,
        studioId,
      }).then(res => {
        dispatch(setStudios(res))
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    document.title = `Hey Joe - Virtual Casting Studio and Auditioning Platform`;
    context.setTitle('')
    const u = getUser()
    try {
      getUserById(u.id).then((res) => {
        setUser(res)
      })
    } catch(e){
      console.log('getUserById e:>', e)
    }
  }, [])

  useEffect(() => {
    fetchManyStudios()
  }, [page, searchKey, archive, user])

  const onCreateProjectBtnClick = async () => {
    const { jitsi_meeting_id } = await generateNewJitsiKey()
    const { jitsi_meeting_id: test_meeting_id } = await generateNewJitsiKey()
    const { jitsi_meeting_id: back_office_meeting_id } = await generateNewJitsiKey()
    const { project_uri } = await generateNewProjectUri()
    const newStudioInfo = {
      jitsi_meeting_id,
      test_meeting_id,
      uri: project_uri,
      back_office_meeting_id
    }
    if (user && user.user_type === USER_TYPES.CASTING_DIRECTOR) {
      newStudioInfo.casting_directors = [user]
    }
    setNewStudioInfo(newStudioInfo)
  }

  const postStudioCreate = async (studio) => {
    setNewStudioInfo(null)
    if (studio) {
      const formData = new FormData()
      formData.append('name', '1st call')
      formData.append('studio', studio._id)
      const createdSession = await createSession(formData)

      history.push(`/session/${studio.uri}/edit/${createdSession._id}`, '_blank')
      fetchManyStudios()
    }
  }

  return (
    <div className="studios-list-container">

      <div className="studios-list">

        <div className={`loading ${loading ? 'show' : ''}`}>
          <div className='d-flex flex-column align-items-center text-danger bg-white px-4 pt-3 pb-1 border-danger border'>
            <FaSpinner size='32px' className='spinning' />
            <span className='h4'>Processing...</span>
          </div>
        </div>

        <div className="project-page-header d-flex align-items-center justify-content-between mb-3 flex-wrap">
          <h1 className="h-32-400 mb-0">
            Projects
          </h1>
          <div className="checkbox-container d-flex align-items-center">
            <input 
              id="create-role"
              type="checkbox"
              checked={isChecked}
              onChange={() => {
                setArvhice(!isChecked)
                setIsChecked((prev) => !prev)
              }}
              className={isChecked ? "checked" : ""}
            />
              <label className="h-14-400 m-0 ls-2" htmlFor="create-role">Show Archive</label>
          </div>
          <div className="header-right d-flex align-items-center justify-content-end flex-fill">
            <Form.Control
              className="h-16-400 br-8 search-input"
              placeholder="Search for a Project"
              value={searchKey}
              onChange={ev => setSearchKey(ev.target.value)}
            />
            {STUDIO_LIST_PERMISSIONS.CAN_CREATE_STUDIO() && (
              <>
                <Button
                  variant='flat' size='sm'
                  className='action-button'
                  onClick={() => {
                    setShowStarterVideo(true)
                  }}
                >
                  Quick Start
                </Button>
                <button
                  type="button"
                  onClick={onCreateProjectBtnClick}
                  className="primary-solid-button h-16-700 br-8"
                >
                  + Create Project
                </button>
              </>
            )}
          </div>
        </div>

        <div className="studio-list-container mb-4">
          {studios.studios && studios.studios?.map(studio => (
            <div key={studio._id}>
              <Studio
                key={studio._id}
                studio={studio}
                refreshList={() => { fetchManyStudios() }}
                setFindFreelancerFor={setFindFreelancerFor}
              />
            </div>
          ))}
        </div>
        {studios.studios && studios.studios.length === 0 && user && user.user_type === USER_TYPES.CASTING_DIRECTOR && (
          <div>
            <h3 className='text-center mb-3'>Hey Joe Quick Start</h3>
            <div style={{ maxWidth: '600px', margin: 'auto' }} className='mb-5' dangerouslySetInnerHTML={{
              __html: `
              <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/900673087?h=9b26645479&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Hey Joe Add Project"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
            `}} />
          </div>
        )}
        {studios.studios && studios.studios.length > 0 && (
          <Pagination
            page={page}
            setPage={setPage}
            pageCount={Math.ceil(+studios.count / PAGE_SIZE)}
          />
        )}
        <StudioCrupdateModal
          show={!!newStudioInfo}
          studio={newStudioInfo}
          onHide={postStudioCreate}
        />
        <SessionCrupdateModal
          show={showSessionEditModal}
          onHide={() => {
            fetchManyStudios()
            setShowSessionEditModal(false)
          }}
          session={session}
          studio={studio}
        />

        <Modal
          size='xl'
          show={showStarterVideo}
          onHide={() => { setShowStarterVideo(false) }}
        >
          <Modal.Header closeButton>
            <h5 className="mb-0">
              Hey Joe Quick Start
            </h5>
          </Modal.Header>
          <Modal.Body>
            <div style={{ margin: 'auto' }} className='mb-5' dangerouslySetInnerHTML={{
              __html: `
              <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/900673087?h=9b26645479&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Hey Joe Add Project"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
            `}} />
          </Modal.Body>
        </Modal>
        <Footer />
      </div>
    </div>
  )
}

export default StudioList
