import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useDispatch } from 'react-redux'
import { addPostingLink, updatePostingLink } from "../../services";
import {
  update as updateStudioInStore,
} from '../../store/studios'

export default ({ studio, show, onHide, postingLink = null }) => {
  const [selectedIds, setSelectedIds] = useState([]);

  const dispatch = useDispatch()

  useEffect(() => {
    if(postingLink){
      setSelectedIds(postingLink.posting_pages.map((page) => page._id));
    }
  }, [postingLink, show])

  const onSubmit = (e) => {
    e.preventDefault();

    const selectedPages = studio.postingPages.filter((page) => selectedIds.includes(page._id));

    const formData = new FormData(e.target);
    const name = formData.get("name");

    const _studio = { ...studio }

    let postinLinkObject = {
      name,
      posting_pages: selectedPages
    }
    if(postingLink){
      updatePostingLink(postinLinkObject, studio._id, postingLink._id).then(res=>{
        _studio.posting_links = res.posting_links
        dispatch(updateStudioInStore(_studio))
      })
    } else{
      addPostingLink(postinLinkObject, studio._id).then(res=>{
        _studio.posting_links = res.posting_links
        dispatch(updateStudioInStore(_studio))
      })
    }
    
    onHide();
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    if (value === "all-posting-pages") {
      if (checked) {
        let selectedPages = []; 
        studio.postingPages.map((postingPage) => {
          selectedPages.push(postingPage._id);
        });
        setSelectedIds([...selectedPages]);
      } else {
        setSelectedIds([]);
      }
    } else {
      if (checked) {
        setSelectedIds([...selectedIds, value]);
      } else {
        setSelectedIds(
          selectedIds.filter((it) => it !== value)
        );
      }
    }
  }

  return (
    <Modal show={show} onHide={onHide} className="posting-link-modal">
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton className="align-items-baseline">
          <h4 className="mb-0 mr-3">{postingLink ? 'Edit Posting Link': 'Add Posting Link'}</h4>
        </Modal.Header>

        <Modal.Body>
          <span className="h-14-600 mb-2">Name</span>
          <Form.Control
            defaultValue={postingLink ? postingLink.name : ""}
            type="text"
            className="form-control mb-3"
            name="name"
            required
          />
          <div>
            <span className="h-14-600">Choose posting Pages</span>
            <div className="d-flex align-item-center my-3">
              <input
                id="all"
                type="checkbox"
                value="all-posting-pages"
                onChange={handleCheckboxChange}
                checked={studio.postingPages.every(page => selectedIds.includes(page._id))}/>
              <label className="ml-2 mb-0" htmlFor="all">All Posting Pages</label>
            </div>
            <div className="horizontal-line"></div>
            <div className="my-3 posting-page-checkbox-container">
              {studio.postingPages.map((postingPage) => {
                return (
                  <div
                    key={postingPage._id}
                    className="d-flex align-item-center"
                  >
                    <input
                      id={postingPage._id}
                      type="checkbox"
                      value={postingPage._id}
                      onChange={handleCheckboxChange}
                      checked={selectedIds.includes(postingPage._id)}/>
                    <label className="ml-2 mb-0" htmlFor={postingPage._id}>{postingPage.name}</label>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className="primary-solid-button h-14-600 text-white"
            disabled={selectedIds.length === 0}>{postingLink ? 'Edit Posting Link' : 'Add Posting Link'}</button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
