import React from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'
import { static_root } from '../../../services'
import _ from 'lodash'
import { futureOnlyDates } from '../../../utils'
import { FaPenAlt } from 'react-icons/fa'

export default ({ user }) => {
  if (!user.freelancer_profile) {
    return <div>
      <span>You don't have freelancer profile yet.</span>
      <Link
        className='text-danger ml-2 cursor-pointer'
        to="?edit=true"
      > Create Profile </Link>
    </div>
  }
  const { logo } = user || {}

  return (
    <div className='profile-view d-flex flex-column'>
      <img
        className="mb-4 profile-picture"
        src={logo ? static_root+logo : require('../../../assets/camera.png')}
      />
      <div className='d-flex'>
        <label className='h3 mr-3 mb-4'>
          {user.first_name} {user.last_name}
        </label>
        <Link
          className="ml-3 text-danger cursor-pointer"
          to="?edit=true"
        >
          <FaPenAlt className='mr-2'/>
          Edit
        </Link>
      </div>
      <div className='d-flex'>
        <div className='mb-3 d-flex flex-column flex-1'>
          <label className='label'>Will Work As</label>
          <span>{_.get(user, 'freelancer_profile.will_work_as', []).join(', ')}</span>
        </div>
        <div className='mb-3 d-flex flex-column flex-1 mx-5'>
          <label className='label'>Time Zone</label>
          <span>{_.get(user, 'freelancer_profile.timezone', '')}</span>
        </div>
        <div className='mb-3 d-flex flex-column flex-1 mr-auto'>
          <label className='label'>Phone</label>
          <span>{_.get(user, 'freelancer_profile.phone', '')}</span>
        </div>
      </div>
      <div className='mb-3 d-flex flex-column'>
        <label className='label'>Experience</label>
        <span>{ _.get(user, 'freelancer_profile.experience' , '')}</span>
      </div>
      <div className='mb-3 d-flex flex-column'>
        <label className='label'>Available Dates</label>
        <div className='w-75 d-flex flex-wrap'>
          {futureOnlyDates(_.get(user, 'freelancer_profile.available_dates', [])).map((dt, idx) => {
            return<span className='mr-2 mb-0' key={idx}>{moment(new Date(dt)).format('MM/DD/YY')}</span>
          })}
        </div>
      </div>
      <div className='mb-3 d-flex flex-column'>
        <label className='label'>Availability Notes</label>
        <span>{_.get(user, 'freelancer_profile.availability_notes', '')}</span>
      </div>
      <div className='mb-3 d-none'>
        <label className='label'>Receive Email</label>
        <span>{_.get(user, 'freelancer_profile.receive_email', false) ? 'Yes': 'No'}</span>
      </div>
    </div>
  )
}

