import React from "react";

const NoIcon = (props) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5Z"
      fill="url(#paint0_radial_880_632)"
    />
    <path
      d="M6.39062 11.9174C6.39062 11.9174 6.76262 12.1667 8.00063 12.1667C9.14729 12.1667 9.60962 11.9174 9.60962 11.9174C9.60962 11.9174 9.32196 11.4224 8.00063 11.4224C6.67929 11.4224 6.39062 11.9174 6.39062 11.9174Z"
      fill="url(#paint1_radial_880_632)"
    />
    <path
      d="M7.99917 11.8333C9.12983 11.8333 9.43583 12.0363 9.43583 12.0363C9.43583 12.0363 9.1425 12.4999 7.99917 12.4999C6.85583 12.4999 6.5625 12.0363 6.5625 12.0363C6.5625 12.0363 6.8685 11.8333 7.99917 11.8333Z"
      fill="url(#paint2_radial_880_632)"
    />
    <path
      d="M9.57041 11.8983C9.73741 11.9903 9.90207 11.7789 9.77307 11.6386C9.39841 11.2313 8.82274 10.8333 8.00341 10.8333C7.18407 10.8333 6.60841 11.2313 6.23374 11.6386C6.10474 11.7789 6.26941 11.9903 6.43641 11.8983C6.82007 11.6863 7.34474 11.4999 8.00341 11.4999C8.66207 11.4999 9.18674 11.6863 9.57041 11.8983Z"
      fill="url(#paint3_radial_880_632)"
    />
    <path
      d="M9.16667 8.50008C9.25871 8.50008 9.33333 8.42546 9.33333 8.33341C9.33333 8.24137 9.25871 8.16675 9.16667 8.16675C9.07462 8.16675 9 8.24137 9 8.33341C9 8.42546 9.07462 8.50008 9.16667 8.50008Z"
      fill="#212121"
    />
    <path
      d="M11.5034 8.83334C11.5034 8.83334 11.2118 8.83501 10.9514 8.83501C10.1704 8.83501 9.25077 8.18701 9.25077 8.18701L9.02344 8.40968C9.02344 8.40968 9.3801 9.50001 10.3368 9.50001C11.0448 9.50001 11.5944 9.14001 11.5944 9.14001L11.5034 8.83334Z"
      fill="#212121"
    />
    <path
      d="M11.5026 9.16659C11.5947 9.16659 11.6693 9.09197 11.6693 8.99992C11.6693 8.90787 11.5947 8.83325 11.5026 8.83325C11.4106 8.83325 11.3359 8.90787 11.3359 8.99992C11.3359 9.09197 11.4106 9.16659 11.5026 9.16659Z"
      fill="#212121"
    />
    <path
      d="M6.83854 8.50008C6.93059 8.50008 7.00521 8.42546 7.00521 8.33341C7.00521 8.24137 6.93059 8.16675 6.83854 8.16675C6.74649 8.16675 6.67188 8.24137 6.67188 8.33341C6.67188 8.42546 6.74649 8.50008 6.83854 8.50008Z"
      fill="#212121"
    />
    <path
      d="M4.50506 8.83334C4.50506 8.83334 4.79673 8.83501 5.05706 8.83501C5.83806 8.83501 6.75773 8.18701 6.75773 8.18701L6.98506 8.40968C6.98506 8.40968 6.6284 9.50001 5.67173 9.50001C4.96373 9.50001 4.41406 9.14001 4.41406 9.14001L4.50506 8.83334Z"
      fill="#212121"
    />
    <path
      d="M4.5026 9.16683C4.59465 9.16683 4.66927 9.09221 4.66927 9.00016C4.66927 8.90812 4.59465 8.8335 4.5026 8.8335C4.41056 8.8335 4.33594 8.90812 4.33594 9.00016C4.33594 9.09221 4.41056 9.16683 4.5026 9.16683Z"
      fill="#212121"
    />
    <defs>
      <radialGradient
        id="paint0_radial_880_632"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8 8.5) scale(6)"
      >
        <stop stopColor="#FFE16E" />
        <stop offset="0.629" stopColor="#FFD426" />
        <stop offset="0.72" stopColor="#FDCD23" />
        <stop offset="0.854" stopColor="#F8B919" />
        <stop offset="1" stopColor="#F09C0C" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_880_632"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.9984 11.0705) rotate(89.194) scale(1.2374 3.65481)"
      >
        <stop offset="0.629" stopColor="#FFE16E" />
        <stop offset="0.871" stopColor="#FFE16E" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_880_632"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.99357 11.4275) rotate(180) scale(1.70506 1.0418)"
      >
        <stop offset="0.284" stopColor="#CC9000" />
        <stop offset="1" stopColor="#CC9000" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_880_632"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.99604 11.6859) scale(1.8263 0.979419)"
      >
        <stop stopColor="#1C1911" />
        <stop offset="0.999" stopColor="#171714" />
      </radialGradient>
    </defs>
  </svg>
);

export default NoIcon;
