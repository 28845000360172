import React from 'react'
 
import {
  Modal,
  Button,
} from 'react-bootstrap'
 

export default function ({
  confirmMessage,
  confirmCancel,
  confirmYes
}) {
   

  return (
    <Modal
        show={!!confirmMessage}
        onHide={confirmCancel}
      >
        <Modal.Header closeButton>
          <h5 className="mb-0">
            {confirmMessage}
          </h5>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="danger"
            className="btn-w-md"
            onClick={confirmYes}
          >
            Yes
          </Button>
          <Button
            variant="light"
            className="btn-w-md"
            onClick={confirmCancel}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
  );
}