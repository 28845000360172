import React from 'react'
import { withRouter } from 'react-router-dom'
import './Footer.scss'

const excludePaths = [
  '/studio',
  '/onboard'
]

const Footer = (props) => {
  
  if (!props.force && excludePaths.find(path => props.location.pathname.startsWith(path))) {
    return null
  }

  return (
    <div className="global-footer no-print text-center text-primary mt-auto app-footer d-flex justify-content-center">
      <img src={require('../assets/heyjoe.png')} className="heyjoe-logo br"/>
      <div className="d-inline-flex flex-column">
        <label>© 2024 North Shore Media, LLC. All Rights Reserved</label>
        <div className="d-flex">
          <a href="https://heyjoe.io/privacy-policy/" target="_blank" className="mr-2">
            Privacy Policy
          </a> | 
          <a href="https://heyjoe.io/terms-and-conditions/" target="_blank">
            &nbsp;Terms And Conditions.
          </a>
        </div>
      </div>
      
    </div>
  )
}

export default withRouter(Footer)
