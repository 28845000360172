import moment from "moment-timezone";
import { useCallback, useEffect, useState } from "react";
import { useAuthedUser } from "../../../hooks/auth";
import { scheduleAppointment } from "../../../services/appointment";
import { SESSION_LOCATION_TYPE } from "../../../constants";

const useConfirmAudition = ({ session_id, day_id, slot, role, date, clearAuditionData, currentDate }) => {
  const [auditionDate, setAuditionDate] = useState(null);
  const { id: talent_id } = useAuthedUser();
  const time_zone = moment.tz.guess();
  const [showConfirmedMessage, setShowConfirmedMessage] = useState(false);
  const [appointmentStatus, setAppointmentStatus] = useState({type:"",message:""});
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  useEffect(() => {
    if (date) {
      setAuditionDate(moment(date).format("ddd MMM D, YYYY"));
    }
  }, [date]);
  const confirmAudition = useCallback(async () => {
    
    const submitAudition = async () => {
      try {
        let inPersonaLocation = 'None'
        if([SESSION_LOCATION_TYPE[1], SESSION_LOCATION_TYPE[2]].includes(currentDate.location_type)){ //INPERSON AND BOTH
          inPersonaLocation = currentDate?.in_person_location
        }
        setLoadingConfirm(true);
        const response = await scheduleAppointment({
          session_id,
          day_id,
          availability_id: slot.availability_id,
          body: {
            talent_id,
            date,
            start_time: slot.start,
            end_time: slot.end,
            length_time_slot: slot.length_time_slot,
            time_zone,
            role,
            location_type: currentDate.location_type,
            in_person_location: inPersonaLocation
          },
        });
        setLoadingConfirm(false);
        if (response.data) {
          setAppointmentStatus({type:"success",message:"Appointment Confirmed"})
          setShowConfirmedMessage(true)
          clearAuditionData()
        } else {
          setAppointmentStatus({type:"error",message:"Something went wrong"})
        }
      } catch (error) {
        console.log(error);
        setAppointmentStatus({type:"error",message: error.message})
      }
    };
    if(session_id && day_id && slot && role && date && talent_id && time_zone)
      submitAudition();
  }, [session_id, day_id, slot, role, date, talent_id, time_zone]);

  const cleanData = () => {
    setAuditionDate(null);
    setShowConfirmedMessage(false);
    setAppointmentStatus({type:"",message:""});
  }
  
  return {
    auditionDate,
    showConfirmedMessage,
    appointmentStatus,
    confirmAudition,
    setShowConfirmedMessage,
    cleanData,
    loadingConfirm
    
  };
};

export default useConfirmAudition;
