/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Switch from "react-switch"
import { AsyncTypeahead, MenuItem, Menu } from 'react-bootstrap-typeahead'
import classnames from 'classnames'
import { FaDownload, FaFilm, FaListOl, FaTimes, FaSpinner, FaFileDownload, FaUsers, FaPlusCircle, FaUpload } from 'react-icons/fa'
import { Modal } from 'react-bootstrap'
import Papa from 'papaparse'
import moment from 'moment-timezone'
import {
  sendMessage,
  updateRecordField,
  removeCheckinRecord,
  clearSessionRecords,
  getLastVideosTime,
  addRecordToCurentGroup,
  removeRecordFromCurrentGroup,
  finishCurrentGroup,
  scheduleSendMessage,
  twr_host,
  getNotification,
  uploadFile,
  onboardUser,
  updateLimeLiteFolder
} from '../../services'
import AvatarModal from '../../components/avatar-modal'
import './style.scss'
import { formatHour, formatTime } from '../../utils'
import PersonCard from './PersonCard'
import { ON_DECK_STATUS, USER_TYPE, ON_DECK_ORDER, TALENTS_UPLOAD_CSV_HEADERS, TALENTS_UPLOAD_CSV_SAMPLE_DATA } from '../../constants'
import TwrList from './twr'
import TalentQuickAdd from './TalentQuickAdd'
import { CinemaIcon, PeopleIcon } from '../../assets/icons/session'
import ScheduledList from './ScheduledList'

const messages = [
  "It's now your turn to audition, please enter 'MEETING_ID' into the app and click 'create/join",
  "You are on deck! We'll text you shortly to join the casting.",
  "Please head to Southpaw Studios and wait on the patio. You are 2nd in line",
  "Be prepared, you are next in line to head to Southpaw Studios. We will contact you shortly",
]

const deletedMessageText = 'You arrived at the wrong time. Please come back at the correct call time and check in again.'

let noticeField = ''
let noticeUpdatedAtField = ''
let noticeTitle = ''

class List extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      message: {
        to: '',
        body: ''
      },
      submitting: false,
      error: false,
      selectedRecord: null,
      confirmClearSession: false,
      timeOptions: [],
      csvLoading: false,
      optInCsvLoading: false,
      listTab: 'heyjoe',
      showNotification: '',
      notification: {},
      hideAuditionedTalents: true,
      sortByOnDeck: true,
      showQuickAddModal: false,
      showRecordCSVUploadModal: false,
      recordsFromCSV: [],
      badRecordsCSV: false,
      sendCSVOnboardEmail: false,
      bulkUploading: false,
      selectedOpportunityId: ''
    }
    this.messages = this.props.messages || messages
    this.deletedMessageText = this.props.delete_message || deletedMessageText
    this.positionBackMessage = this.props.studio.position_back_message
  }

  componentDidMount() {
    this.mounted()

    document.body.addEventListener('soft-refresh', this.mounted)

  }

  componentWillUnmount() {
    document.body.removeEventListener('soft-refresh', this.mounted)
  }

  componentDidUpdate(prevProps) {
    if (this.state.loading) {
      document.querySelector('.loading').classList.add('show')
    } else {
      document.querySelector('.loading').classList.remove('show')
    }
    if (JSON.stringify(prevProps.candidates) !== JSON.stringify(this.props.candidates)) {
      const { candidates } = this.props
      this.setState({
        candidates,
        loading: false
      })
    }
    if (JSON.stringify(prevProps.groupCandidates) !== JSON.stringify(this.props.groupCandidates)) {
      this.setState({
        loading: false
      })
    }
  }

  mounted = async () => {
    let timeOptions = []
    let time = moment().startOf('day')
    const endDayTime = moment().endOf('day')
    while (true) {
      time = time.add(5, 'minutes')
      if (endDayTime.diff(time) < 0) {
        break
      }
      timeOptions.push({
        value: time.toDate(),
        text: time.format('hh:mm a')
      })
    }

    let n = await getNotification()
    n = n || {}
    if (USER_TYPE.CASTING_DIRECTOR()) {
      noticeField = 'casting_director_notice'
    }
    if (USER_TYPE.SESSION_MANAGER()) {
      noticeField = 'session_manager_notice'
    }
    noticeTitle = noticeField && noticeField.split('_').map(n => n[0].toUpperCase() + n.slice(1)).join(' ')
    noticeUpdatedAtField = `${noticeField}_updated_at`
    let showNotification = ''
    if (window.localStorage.getItem(noticeUpdatedAtField) !== n[noticeUpdatedAtField]) {
      showNotification = noticeField
    }

    this.setState({
      timeOptions,
      showNotification,
      notification: n,
      loading: false
    })
  }

  setTwrRef = (elem) => {
    this.twrRef = elem
  }

  setSkipped = (id) => {
    const vm = this
    const { studio } = this.props
    this.setState({
      loading: true
    })
    updateRecordField(id, {
      skipped: true
    }).then(data => {
      let idx = vm.props.candidates.findIndex(p => p._id === id) + 1
      for (let i = 1; i < 4 && vm.props.candidates[idx] && idx < vm.props.candidates.length; i++, idx++) {
        if (!vm.props.candidates[idx].skipped && !!this.messages[i]) {
          sendMessage({
            to: vm.props.candidates[idx].phone,
            body: this.messages[i]
          }, studio._id, vm.props.candidates[idx]._id)
        }
      }
    }).catch(err => {
      console.log("App -> setSkipped -> err", err)
    })
  }

  setUnSeen = (id) => {
    const vm = this
    const { studio } = this.props
    this.setState({
      loading: true
    })
    updateRecordField(id, {
      seen: false,
      on_deck: ''
    }).then(() => {
      if (this.positionBackMessage) {
        let idx = vm.props.candidates.findIndex(p => p._id === id)
        sendMessage({
          to: vm.props.candidates[idx].phone,
          body: this.positionBackMessage
        }, studio._id, id)
      }
    })
  }

  setSeen = (id, again = false, on_deck) => {
    const vm = this
    const { studio } = this.props
    this.setState({
      loading: true
    })
    if (on_deck !== ON_DECK_STATUS.IN_CASTING) {
      updateRecordField(id, {
        on_deck: on_deck
      })
      this.setState({
        loading: false
      })
      return
    }
    updateRecordField(id, {
      seen: true,
      on_deck,
      call_in_time: new Date().toISOString()
    }).then(data => {
      let idx = vm.props.candidates.findIndex(p => p._id === id)
      if (again) {
        sendMessage({
          to: vm.props.candidates[idx].phone,
          body: this.messages[0]
        }, studio._id, id)
        return
      }
      for (let i = 0; i < 4 && vm.props.candidates[idx] && idx < vm.props.candidates.length; i++, idx++) {
        if (!vm.props.candidates[idx].seen
          && (!vm.props.candidates[idx].skipped || i === 0)
          && !!this.messages[i]
        ) {
          sendMessage({
            to: vm.props.candidates[idx].phone,
            body: this.messages[i]
          }, studio._id, vm.props.candidates[idx]._id)
          if (i === 0 && studio.good_bye_message) {
            scheduleSendMessage({
              to: vm.props.candidates[idx].phone,
              body: studio.good_bye_message,
            }, studio._id, vm.props.candidates[idx]._id, 10)
          }
        }
      }
    }).catch(err => {
      console.log("App -> updateSeen -> err", err)
    })
  }

  removeRecord = (id, Phone, removedIdx) => {
    const result = window.confirm("Want to delete?")
    if (!result) {
      return
    }
    const vm = this
    const { studio } = this.props
    this.setState({
      loading: true
    })
    removeCheckinRecord(id).then(data => {
      if (!data.seen) {
        sendMessage({
          to: Phone,
          body: this.deletedMessageText
        }, studio._id, id)
      }
      let idx = vm.props.candidates.findIndex(p => (!p.seen && !p.skipped)) || vm.props.candidates.length
      for (let i = 1;
        i < 4 && vm.props.candidates[idx] && idx < vm.props.candidates.length
        && removedIdx <= idx;
        i++, idx++) {
        if (!vm.props.candidates[idx].skipped && !!this.messages[i]) {
          sendMessage({
            to: vm.props.candidates[idx].phone,
            body: this.messages[i]
          }, studio._id, vm.props.candidates[idx]._id)
        }
      }
    }).catch(err => {
      console.log("App -> removeRecode -> err", err)
    })
  }

  messageFieldChange = (event) => {
    const name = event.target.getAttribute('name')
    this.setState({
      message: { ...this.state.message, [name]: event.target.value }
    })
  }

  onMessageSend = (event) => {
    event.preventDefault()
    this.setState({ submitting: true })
    const { studio } = this.props
    sendMessage(this.state.message, studio._id)
      .then(data => {
        if (data.success) {
          this.setState({
            error: false,
            submitting: false,
            message: {
              to: '',
              body: ''
            }
          })
        } else {
          this.setState({
            error: true,
            submitting: false
          })
        }
      })
  }

  toggleClearConfirm = () => {
    if (this.state.listTab === 'twr') {
      this.twrRef.toggleClearConfirm()
    } else {
      this.setState({
        confirmClearSession: !this.state.confirmClearSession
      })
    }
  }

  clearRecords = async () => {
    this.setState({ loading: true })
    const { session } = this.props
    await clearSessionRecords(session._id)
    this.toggleClearConfirm()
  }

  signOut = (id) => {
    this.setState({ loading: true })
    updateRecordField(id, {
      signed_out: true,
      signed_out_time: new Date().toISOString()
    })
  }

  addToGroup = async (_id) => {
    this.setState({ loading: true })
    return await addRecordToCurentGroup(_id)
  }

  leaveFromGroup = async (_id) => {
    const { session } = this.props
    if (this.state.listTab === 'twr') {
      this.twrRef.leaveFromGroup(_id, session._id)
      return
    }
    this.setState({ loading: true })
    await removeRecordFromCurrentGroup(_id)
  }

  finishCurrentGroup = async () => {
    if (this.state.listTab === 'twr') {
      this.twrRef.finishCurrentGroup()
      return
    }
    const { session } = this.props
    this.setState({ loading: true })
    await finishCurrentGroup(session._id)
  }

  selectRecord = (record) => {
    this.setState({
      selectedRecord: { ...record }
    })
  }

  setSelectedOpportunityId = (id) => {
    this.setState({
      selectedOpportunityId: id
    })
  }

  updateRecord = async () => {
    this.setState({
      loading: true
    })
    const { selectedRecord } = this.state
    await updateRecordField(selectedRecord._id, {
      actual_call: selectedRecord.actual_call,
      role: selectedRecord.role,
      limelite_role_id: this.state.selectedOpportunityId
    })
    this.setState({
      selectedRecord: null
    })
  }

  downloadOptinCSV = async () => {
    const { studio } = this.props
    this.setState({ optInCsvLoading: true })
    const row_headers = [
      'first_name',
      'last_name',
      'Consent',
      'email',
      'Phone',
      'Timestamp',
      'Country'
    ]
    let csvContent = this.props.candidates.filter(c => c.opt_in).map(c => (
      row_headers.map(key => {
        switch (key) {
          case 'first_name':
            return c.first_name
            break
          case 'last_name':
            return c.last_name
            break
          case 'Consent':
            return "['sms']"
            break
          case 'email':
            return c.email
            break
          case 'Phone':
            return c.phone
            break
          case 'Timestamp':
            return c.checked_in_time
            break
          case 'Country':
            return 'USA'
            break
        }
      })
    ))
    csvContent.unshift(row_headers)
    const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(Papa.unparse(csvContent))

    var link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", `${studio.name}-${(new Date()).toISOString()}-optins.csv`)
    document.body.appendChild(link)

    link.click()
    document.body.removeChild(link)

    this.setState({ optInCsvLoading: false })
  }

  downloadCSV = async () => {
    this.setState({ csvLoading: true })
    const { studio, session } = this.props
    const cids = this.props.candidates.map(c => c._id)
    const lastVideoTimes = await getLastVideosTime(cids)

    const castingDirectors = studio.casting_directors.map(cd => {
      return `${cd.first_name || ''} ${cd.last_name || ''}`
    }).join(', ')

    const projectName = studio.name;

    const row_headers = [
      'first_name',
      'last_name',
      'agent',
      'actual_call',
      'checked_in_time',
      'last_record_time',
      'interview_no',
      'role',
      'sagnumber',
    ]

    let csvContent = this.props.candidates
      .map(candidate => (
        row_headers.map(key => {
          switch (key) {
            case 'studio':
              return studio.name.replace(/,/g, ' ')
            case 'session':
              return session.name.replace(/,/g, ' ')
            case 'call_in_time':
            case 'signed_out_time':
            case 'checked_in_time':
              const dateString = formatTime(candidate[key])
              return dateString
            case 'actual_call':
              return formatHour(candidate[key])
            case 'last_record_time':
              const timeItem = lastVideoTimes.find(l => l.id === candidate._id)
              return timeItem.time ? formatTime(timeItem.time) : ''
            default:
              return `${(candidate[key] || '')}`
          }
        })
      ))

    csvContent.unshift(row_headers.map(h => {
      return h === 'last_record_time'
        ? 'signed_out_time'
        : h
    }))

    // Find the last 'checked_in_time'
    const lastCheckedInTime = this.props.candidates[this.props.candidates.length - 1].checked_in_time;

    // Check if 'checked_in_time' is a 'Date' object and format it as YYYY-MM-DD
    const lastCheckedInISO = lastCheckedInTime instanceof Date ? lastCheckedInTime.toISOString().split('T')[0] : new Date(lastCheckedInTime).toISOString().split('T')[0];

    csvContent.unshift(['Casting Representative:', castingDirectors, 'Commercial Title:', '', 'Audition Date:', lastCheckedInISO, '', '', '']);
    csvContent.unshift(['Advertiser:', '', 'Product:', projectName, 'Performance Type:', '', '', '', '']);

    const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(Papa.unparse(csvContent))

    var link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    // Use the 'lastCheckedInISO' in your download filename
    link.setAttribute("download", `${studio.name}-${lastCheckedInISO}.csv`)
    document.body.appendChild(link)

    link.click()
    document.body.removeChild(link)
    this.setState({ csvLoading: false })
  }

  handleQuickAddTalent = async (ev, onboardInTime) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const data = {}
    for (let pair of formData.entries()) {
      if (pair[1]) {
        if (pair[0] === 'avatar') {
          if (pair[1].size > 0) {
            const file = await uploadFile(pair[1])
            data[pair[0]] = file.Key
          }
        } else {
          data[pair[0]] = pair[1]
        }
      }
    }

    if (!data.role) {
      window.alert('Please add a role!')
      return
    }
    await onboardUser({
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      session: this.props.session._id,
      agent: data.agent,
      role: data.role || "",
      limelite_role_id: this.state.selectedOpportunityId,
      avatar: data.avatar,
      is_avatar_s3: true,
      location_type: onboardInTime.location_type
    })
    this.setState({ showQuickAddModal: false })
  }

  downloadSampleTalentsCSV = () => {
    const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(Papa.unparse([
      TALENTS_UPLOAD_CSV_HEADERS.map(h => h.label),
      TALENTS_UPLOAD_CSV_SAMPLE_DATA
    ]))

    var link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    // Use the 'lastCheckedInISO' in your download filename
    link.setAttribute("download", `sample.csv`)
    document.body.appendChild(link)

    link.click()
    document.body.removeChild(link)
  }

  handleTalentsCSVFileSelect = (ev) => {
    const file = ev.target.files[0];
    if (!file) { return; }
    this.setState({ badRecordsCSV: false })
    const reader = new FileReader();
    const self = this

    reader.onload = function (e) {
      const contents = e.target.result;
      const lines = contents.split('\n');
      const fieldNames = lines[0].trim().split(',');
      if (fieldNames.join(',') !== TALENTS_UPLOAD_CSV_HEADERS.map(h => h.label).join(',')) {
        self.setState({ badRecordsCSV: true })
        return
      }
      const data = [];
      for (let i = 1; i < lines.length; i++) {
        const line = lines[i].trim();
        const values = line.split(',');
        if (values.length !== TALENTS_UPLOAD_CSV_HEADERS.length) { continue }
        const obj = {};
        for (let j = 0; j < fieldNames.length; j++) {
          obj[TALENTS_UPLOAD_CSV_HEADERS[j].key] = values[j];
        }
        const sanObj = {}
        for (let j = 0; j < TALENTS_UPLOAD_CSV_HEADERS.length; j++) {
          switch (TALENTS_UPLOAD_CSV_HEADERS[j].key) {
            case 'call_time':
              sanObj['actual_call'] = new Date(obj[TALENTS_UPLOAD_CSV_HEADERS[j].key])
              if (isNaN(sanObj['actual_call'].getTime())) {
                sanObj['actual_call'] = undefined
              } else {
                sanObj[TALENTS_UPLOAD_CSV_HEADERS[j].key] = sanObj['actual_call'].toLocaleString()
              }
              break
            default:
              sanObj[TALENTS_UPLOAD_CSV_HEADERS[j].key] = obj[TALENTS_UPLOAD_CSV_HEADERS[j].key].replace(/;/g, ',')
          }
        }
        if (sanObj.first_name && sanObj.last_name && sanObj.email && sanObj.phone) {
          data.push(sanObj)
        }
      }
      self.setState({ recordsFromCSV: data })
    };
    reader.readAsText(file);
  }

  handleUploadCSVRecords = async (onboardInTime) => {
    this.setState({ showRecordCSVUploadModal: false, bulkUploading: true })
    for (let data of this.state.recordsFromCSV) {
      try {
        await onboardUser({
          ...data,
          session: this.props.session._id,
          location_type: onboardInTime.location_type,
          is_bulk_onboard: true,
          send_email: this.state.sendCSVOnboardEmail
        })
      } catch (err) {
        console.log(err)
      }
    }
    this.setState({
      recordsFromCSV: [],
      bulkUploading: false,
      showRecordCSVUploadModal: false
    })
  }

  sendSms = (phone) => {
    this.setState({
      message: {
        to: phone
      }
    })
    setTimeout(() => {
      document.querySelector('#body').focus()
    }, 200)
  }

  render() {
    const { studio, session, testMode, reloadSession, roles,
      dates, selectedDate, setSelectedDate, reload } = this.props
    const {
      timeOptions,
      selectedRecord,
      confirmClearSession,
      optInCsvLoading,
      csvLoading,
      bulkUploading,
      listTab,
      showNotification,
      notification,
      showQuickAddModal,
      showRecordCSVUploadModal,
      recordsFromCSV,
      badRecordsCSV,
      sendCSVOnboardEmail
    } = this.state

    let twrOnboardLink = (session.twr || '').split('/')
    twrOnboardLink.splice(1, 0, 'onboard')
    twrOnboardLink = twrOnboardLink.join('/')
    let candidates = [...(this.props.candidates || [])]
    if (this.state.hideAuditionedTalents) {
      candidates = candidates.filter(it => it.groups.length === 0)
    }
    if (this.state.sortByOnDeck) {
      candidates.sort((a, b) => {
        return (ON_DECK_ORDER[a.on_deck] || +new Date(a.checked_in_time)) - (ON_DECK_ORDER[b.on_deck] || +new Date(b.checked_in_time))
      })
    }
    const onboardInTime = session.dates.find(d => {
      if (d.location_type === "Video Submission") {
        const currentDate = moment.tz(new Date(), 'America/Los_Angeles').startOf('day');
        const sessionDate = moment.tz(new Date(d.start_time), 'America/Los_Angeles').startOf('day');
        return currentDate.isSameOrBefore(sessionDate);
      }
      return moment.tz(new Date(), 'America/Los_Angeles').format('YYYY-MM-DD') === moment.tz(new Date(d.start_time), 'America/Los_Angeles').format('YYYY-MM-DD')
    })
    return (
      <div className={"list-view " + (testMode ? 'test' : '')}>
        <div className="d-flex flex-column">
          <div className="studio-header">
            <h4 className="my-3 text-center">
              {testMode ? (
                <div className="d-flex justify-content-center">
                  <span className="icon-style h5 mb-0 mt-2">Virtual Lobby</span>
                </div>
              ) : (
                <div className="d-flex justify-content-around position-relative action-row">
                  {listTab === 'twr' ?
                    <a
                      title="Session Check-In"
                      href={`${twr_host}${twrOnboardLink}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mx-1"
                    >
                      <FaListOl size="14" className="icon-style" />
                    </a>
                    : (
                      <Link
                        title="Session Check-In"
                        to={`/onboard/${studio.uri}/${session._id}`}
                        target="_blank"
                        className="mx-1"
                      >
                        <FaListOl size="14" className="icon-style" />
                      </Link>
                    )}
                  <Link
                    to="?test=true"
                    target="_blank"
                    title="Virtual Lobby"
                    className="mx-1"
                  >
                    <PeopleIcon />
                  </Link>
                  <Link
                    title="Video Review"
                    to={`/video/${studio.uri}/${session._id}`}
                    target="_blank"
                    className="mx-1"
                  >
                    <CinemaIcon />
                  </Link>
                  <a
                    title="Download CSV"
                    className="mx-1"
                  >
                    {csvLoading ?
                      <FaSpinner
                        size="14"
                        className="icon-style cursor-pointer spinning"
                      />
                      :
                      <FaDownload
                        size="14"
                        className="icon-style cursor-pointer"
                        onClick={() => {
                          if (listTab === 'twr') {
                            this.twrRef.downloadCSV()
                          } else {
                            this.downloadCSV()
                          }
                        }}
                      />
                    }
                  </a>
                  {listTab !== 'twr' && USER_TYPE.IS_SUPER_ADMIN() && (
                    <a
                      title="Download Opt Ins CSV"
                      className="mx-1"
                    >
                      {optInCsvLoading ?
                        <FaSpinner
                          size="14"
                          className="icon-style cursor-pointer spinning"
                        />
                        :
                        <FaFileDownload
                          size="14"
                          className="icon-style cursor-pointer"
                          onClick={this.downloadOptinCSV}
                        />
                      }
                    </a>
                  )}
                  {onboardInTime && (
                    <a
                      title="Quick Add"
                      className="mx-1"
                      onClick={() => { this.setState({ showQuickAddModal: true }) }}
                    >
                      <FaPlusCircle
                        size="14"
                        className="icon-style cursor-pointer"
                      />
                    </a>
                  )}
                  {onboardInTime && (
                    <a
                      title="Upload Records CSV"
                      className="mx-1"
                      onClick={() => { !bulkUploading && this.setState({ showRecordCSVUploadModal: true }) }}
                    >
                      {!bulkUploading ? (
                        <FaUpload
                          size="14"
                          className="icon-style cursor-pointer"
                        />
                      ) : (
                        <FaSpinner
                          size="14"
                          className="icon-style cursor-pointer spinning"
                        />
                      )}
                    </a>
                  )}
                  <a
                    title="Clear Records"
                    className="mx-1"
                  >
                    <FaTimes
                      size="14"
                      className="icon-style cursor-pointer"
                      onClick={this.toggleClearConfirm}
                    />
                  </a>
                </div>
              )}
            </h4>
            {dates.length > 1 && (
              <div className="d-flex px-2 mb-2 align-items-center">
                <div className="flex-fill ml-2">
                  <select value={selectedDate} onChange={ev => {
                    setSelectedDate(ev.target.value)
                  }} className="form-control form-control-sm">
                    <option value={""}>All Dates</option>
                    {dates.map(d => {
                      return (
                        <option key={d} value={d}> {d} </option>
                      )
                    })}
                  </select>
                </div>
              </div>
            )}
            <div className="my-2 px-2 d-flex align-items-center">
              <Switch
                checkedIcon={null} uncheckedIcon={null}
                onColor="#ee514f"
                width={36}
                height={16}
                checked={this.state.hideAuditionedTalents}
                onChange={(state) => { this.setState({ hideAuditionedTalents: state }) }}
              />
              <span className="ml-2">Hide Auditioned Talent</span>
            </div>
            <div className="my-2 px-2 d-flex align-items-center">
              <Switch
                checked={this.state.sortByOnDeck}
                checkedIcon={null} uncheckedIcon={null}
                onColor="#ee514f"
                width={36}
                height={16}
                onChange={state => {
                  this.setState({
                    sortByOnDeck: state
                  })
                }}
              />
              <span className='ml-2'>On Deck</span>
            </div>
            {!USER_TYPE.IS_TALENT() && !USER_TYPE.IS_CLIENT() && !testMode &&(
              <>
                <div className="my-2 px-2 d-flex align-items-center">
                  <Switch
                    checked={this.props.remoteFrame}
                    checkedIcon={null} uncheckedIcon={null}
                    onColor="#ee514f"
                    width={36}
                    height={16}
                    onChange={state => {
                      this.props.setRemoteFrame(state)
                    }}
                  />
                  <span className='ml-2'>Remote Frame</span>
                </div>
                {!this.props.backOfficeFrame && (
                  <div className="my-2 px-2 d-flex align-items-center">
                    <Switch
                      checked={this.props.inPersonFrame}
                      checkedIcon={null} uncheckedIcon={null}
                      onColor="#ee514f"
                      width={36}
                      height={16}
                      onChange={state => {
                        this.props.setInPersonFrame(state)
                      }}
                    />
                    <span className='ml-2'>In Person Frame</span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="tab-header d-flex">
            <label className={classnames("btn btn-sm flex-fill mb-0", { 'tab-selected': listTab === 'heyjoe' })} onClick={() => {
              this.setState({ listTab: 'heyjoe' })
              this.props.setListTab('heyjoe')
            }}>Checked In</label>
            <label className={classnames("btn btn-sm flex-fill mb-0", { 'tab-selected': listTab === 'scheduled' })} onClick={() => {
              this.setState({ listTab: 'scheduled' })
              this.props.setListTab('scheduled')
            }}>Scheduled</label>
            {session.twr && false && <label className={classnames("btn btn-sm flex-fill mb-0", { 'tab-selected': listTab === 'twr' })} onClick={() => {
              this.setState({ listTab: 'twr' })
              this.props.setListTab('twr')
            }}>TWR</label>}
          </div>

          <ul className={classnames("list-group", { 'd-none': listTab !== 'heyjoe' })}>
            {candidates.map((person, idx) => {
              const showCallIn = !this.props.candidates[idx].seen &&
                (idx === 0 ||
                  (this.props.candidates[idx - 1] &&
                    (this.props.candidates[idx - 1].seen ||
                      this.props.candidates[idx - 1].skipped)))
              return (
                <PersonCard
                  key={person._id + idx}
                  id={person._id}
                  idx={idx}
                  testMode={testMode}
                  showCallIn={showCallIn}
                  {...person}
                  setSeen={this.setSeen}
                  setSkipped={this.setSkipped}
                  setUnSeen={this.setUnSeen}
                  removeRecord={this.removeRecord}
                  signOut={this.signOut}
                  addToGroup={this.addToGroup}
                  leaveFromGroup={this.leaveFromGroup}
                  updateRecord={this.selectRecord}
                  sendSms={() => this.sendSms(person.phone)}
                  session_id={session._id}
                />
              )
            })}
          </ul>
          {session.twr && false && <div className={classnames({ 'd-none': listTab !== 'twr' })}>
            <TwrList
              ref={this.setTwrRef}
              twr={session.twr}
              session={session}
              reloadSession={reloadSession}
              testMode={testMode}
              setTwrGroupCandidates={this.props.setTwrGroupCandidates}
              setTwrCandidates={this.props.setTwrCandidates}
            />
          </div>}
          <div className={classnames({ 'd-none': listTab !== 'scheduled' })}>
            <ScheduledList 
              session_id={session._id}
              day_id={session.dates[0]._id}
              addToGroup={this.addToGroup}
              roles={roles}
              opportunityIdList={this.props.opportunityIdList}
              onboardInTime={onboardInTime}
              reload={reload}
              sendSms={this.sendSms}/>
          </div>
          <form
            onSubmit={this.onMessageSend}
            className={classnames({
              'error': this.state.error,
              'active': this.state.message.to,
              'sms-form': true
            })}
          >
            <div>
              <div className="d-flex justify-content-lg-between">
                <label htmlFor="to">To:</label>
                {this.state.message.to && (
                  <button className="btn ml-auto mr-2" onClick={(ev) => {
                    ev.preventDefault()
                    this.setState({ message: { to: '', body: '' } })
                  }}>
                    Cancel
                  </button>
                )}
                <button className="btn px-2 py-0" type="submit" disabled={this.state.submitting}>
                  Send message
                </button>
              </div>
              <input
                type="tel"
                name="to"
                id="to"
                value={this.state.message.to}
                onChange={this.messageFieldChange}
              />
            </div>
            <div>
              <label htmlFor="body">Body:</label>
              <textarea name="body" id="body"
                value={this.state.message.body}
                onChange={this.messageFieldChange}
              />
            </div>
          </form>
        </div>
        <Modal
          show={!!selectedRecord && selectedRecord.actual_call}
          onHide={() => {
            this.setState({
              selectedRecord: null
            })
          }}
        >
          <Modal.Header closeButton>
            <h5 className="mb-0">
              Update Actuall Call Time
            </h5>
          </Modal.Header>
          <Modal.Body>
            {selectedRecord && (
              <div>
                <select
                  value={selectedRecord.actual_call}
                  onChange={ev => {
                    this.setState({
                      selectedRecord: {
                        ...selectedRecord,
                        actual_call: ev.target.value
                      }
                    })
                  }}
                  className="form-control"
                  name="actualCall"
                  id="actualCall"
                >
                  {timeOptions.map(time => (
                    <option
                      key={time.value}
                      value={time.value}
                    >{time.text}</option>
                  ))}
                </select>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger"
              onClick={this.updateRecord}
            >Update</button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={!!selectedRecord && typeof (selectedRecord.role) === 'string'}
          onHide={() => {
            this.setState({
              selectedRecord: null
            })
          }}
        >
          <Modal.Header closeButton>
            <h5 className="mb-0">
              Update Role
            </h5>
          </Modal.Header>
          <Modal.Body>
            <RoleEditor
              selectedRecord={selectedRecord}
              roles={roles}
              setRole={text => {
                this.setState({
                  selectedRecord: {
                    ...selectedRecord,
                    role: text
                  }
                })
              }}
              talentIsAllowedToCreateRoles={!studio?.limelite_project_id}
              opportunityIdList={this.props.opportunityIdList}
              setSelectedOpportunityId={this.setSelectedOpportunityId}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger"
              onClick={this.updateRecord}
            >Update.</button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={!!confirmClearSession}
          onHide={this.toggleClearConfirm}
        >
          <Modal.Header closeButton>
            <h5 className="mb-0">
              Are you sure?
            </h5>
          </Modal.Header>
          <Modal.Body>
            You are about to delete all session records.
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn"
              onClick={this.toggleClearConfirm}
            >Cancel.</button>
            <button
              className="btn btn-danger"
              onClick={this.clearRecords}
            >Clear.</button>
          </Modal.Footer>
        </Modal>
        {selectedRecord &&
          <AvatarModal
            key={selectedRecord._id}
            show={selectedRecord.avatar}
            record={selectedRecord}
            onClose={() => {
              this.setState({
                selectedRecord: null
              })
            }}
          />}

        <Modal
          show={!!showNotification}
          onHide={() => {
            this.setState({
              showNotification: ''
            })
          }}
          className="notification-modal"
        >
          <Modal.Header closeButton>
            <h5 className="mb-0">
              {noticeTitle}
            </h5>
          </Modal.Header>
          <Modal.Body>
            <div className="notification-content" dangerouslySetInnerHTML={{ __html: notification[noticeField] }} />
            <div className="mt-2">
              <button className="btn btn-primary" onClick={() => {
                window.localStorage.setItem(noticeUpdatedAtField, notification[noticeUpdatedAtField])
                this.setState({
                  showNotification: ''
                })
              }}>
                Ok, Got it.
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showQuickAddModal}
          onHide={() => { this.setState({ showQuickAddModal: false }) }}
        >
          <Modal.Header closeButton>
            <h5 className='mb-0'>
              Quick Add Talent
            </h5>
          </Modal.Header>
          <form onSubmit={(ev) => this.handleQuickAddTalent(ev, onboardInTime)}>
            <Modal.Body>
              <TalentQuickAdd
                roles={roles}
                talentIsAllowedToCreateRoles={!studio?.limelite_project_id}
                opportunityIdList={this.props.opportunityIdList}
                setSelectedOpportunityId={this.setSelectedOpportunityId} />
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn"
                onClick={(ev) => { ev.preventDefault(); this.setState({ showQuickAddModal: false }) }}
              >Cancel</button>
              <button
                className="btn btn-danger"
                submit
              >Create</button>
            </Modal.Footer>
          </form>
        </Modal>

        <Modal
          show={showRecordCSVUploadModal}
          onHide={() => { this.setState({ showRecordCSVUploadModal: false }) }}
        >
          <Modal.Header closeButton>
            <h5 className='mb-0'>
              Upload Talents CSV
            </h5>
          </Modal.Header>
          <Modal.Body>
            <a className='text-sm cursor-pointer' onClick={this.downloadSampleTalentsCSV}>Download sample CSV</a>
            <div className='form-group'>
              <label htmlFor='talents-csv'>Choose your talents csv file</label>
              <input type="file" accept='.csv' id="talents-csv" className='pl-2' onChange={this.handleTalentsCSVFileSelect} />
              <p>
                <small>Avoid using ,(comma). Use ; instead of , if you really need it.</small>
              </p>
              {badRecordsCSV && (
                <div>
                  You've choosen wrong CSV. Please select the correct csv file, or download sample csv for reference.
                </div>
              )}
              {recordsFromCSV.length > 0 && (
                <div>
                  <label>Talents to upload ({recordsFromCSV.length} talents)</label>
                  <table className="table table-sm table-striped table-bordered table-responsive csv-records-table">
                    <tr>
                      <th>No</th>
                      {TALENTS_UPLOAD_CSV_HEADERS.map(h => <th key={h.key}>{h.label}</th>)}
                    </tr>
                    {recordsFromCSV.map((r, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          {TALENTS_UPLOAD_CSV_HEADERS.map(h => <td key={h.key + idx}>{r[h.key]}</td>)}
                        </tr>
                      )
                    })}
                  </table>
                </div>
              )}
              <div className='d-flex'>
                <input id="send-csv-onboard-email" type="checkbox" checked={sendCSVOnboardEmail} onChange={ev => {
                  this.setState({ sendCSVOnboardEmail: ev.target.checked })
                }} />
                <label htmlFor="send-csv-onboard-email" className='ml-2 mb-0'>Send onboard email?</label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn"
              onClick={() => { this.setState({ showRecordCSVUploadModal: false }) }}
            >Cancel</button>
            <button
              className="btn btn-danger"
              onClick={() => this.handleUploadCSVRecords(onboardInTime)}
            >Upload</button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export const RoleEditor = ({
  selectedRecord,
  roles,
  setRole,
  talentIsAllowedToCreateRoles = true,
  role,
  opportunityIdList = [],
  setSelectedOpportunityId
}) => {

  if (!selectedRecord) {
    return null
  }

  const onSelect = (ev) => {
    const selectedIndex = ev.target.selectedIndex - 1;
    setSelectedOpportunityId(opportunityIdList[selectedIndex])
    setRole(ev.target.value)
  }

  return (
    <div>
      {talentIsAllowedToCreateRoles ?
        <AsyncTypeahead
          id="role"
          isLoading={false}
          key="role-select"
          className="mb-3"
          defaultSelected={[selectedRecord.role]}
          onChange={value => {
            if (value[0]) {
              setRole(value[0])
            }
          }}
          onSearch={text => {
            // setRole(text)
          }}
          onBlur={ev => {
            setRole(ev.target.value)
          }}
          minLength={0}
          options={roles}
          paginate={false}
          maxResults={50}
          placeholder="Add a Role"

          renderMenu={(results, menuProps) => {
            if (results.length === 0) {
              return null
            }
            return <Menu {...menuProps}>
              {results.map((result, index) => (
                <MenuItem option={result} position={index}>
                  {result}
                </MenuItem>
              ))}
            </Menu>
          }}
        /> :
        <select
          value={role}
          onChange={ev => onSelect(ev)}
          className="form-control">
          <option value="" disabled selected hidden>Choose a Role</option>
          {roles.map((role, index) => {
            return (
              <option value={role} key={index} id={index}>{role}</option>
            )
          })}

        </select>

      }
    </div>
  )
}

export default List
