import { api_host, token } from './consts'

export * from './consts'
export * from './auth'
export * from './record'
export * from './session'
export * from './studio'
export * from './video'
export * from './user'
export * from './postingpage'
export * from './twr'
export * from './heyjoe-twr'
export * from './admin'
export * from './sync'
export * from './freelancer'
export * from './availability'
export * from './appointment'
export * from './postinglink'
export * from './limeLite'

export const getQrCode = async (data) => {
  const resp = await fetch(`${api_host}/qr-code?q=${encodeURIComponent(data)}`)
  return await resp.json()
}

export const uploadFile = async (file) => {
  const formData = new FormData()
  formData.append('file', file)
  const resp = await fetch(`${api_host}/upload-file`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`
    },
    body: formData
  })
  return await resp.json()
}
