import React, { useEffect, useState } from 'react'
import AuthHeader from './AuthHeader'
import { checkUserExists, googleLogin, googleRegister, loginApi, register, resetPasswordRequest, verifyCaptcha } from '../../services'
import { useGoogleLogin } from '@react-oauth/google';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { FaGoogle, FaRegEnvelope } from 'react-icons/fa';
import AuthFooter from './AuthFooter';
import { Button } from 'react-bootstrap'
import { USER_TYPES } from '../../constants';

const StepWrapper = ({ children }) => {
  return (
    <div className="text-primary auth-wrap bg-lightgray d-flex flex-column px-3 justify-content-center">
      {children}
    </div>
  )
}

const AuthPage = ({
  talentOnly = false,
  afterSuccess = null,
  projectInfo = null
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  const [step, setStep] = useState(1)
  const [password, setPassword] = useState('')
  const [userType, setUserType] = useState(talentOnly ? USER_TYPES.TALENT : USER_TYPES.CLIENT)
  const [accountExists, setAccountExists] = useState(false)
  const [gResponse, setGResponse] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setError("")
  }, [step])

  useEffect(() => {
    if (step === 3 && talentOnly && gResponse && gResponse.email) {
      registerAccount()
    }
  }, [step, talentOnly, gResponse])

  const afterLoginSuccess = () => {
    const pUrl = window.localStorage.getItem('prev_url') || '/'
    window.localStorage.removeItem('prev_url')
    afterSuccess ? afterSuccess() : window.location.href = pUrl
  }

  const googleRegisterSuccess = async (response) => {
    const data = await fetch (`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`, {
      headers: {
        Authorization: `Bearer ${response.access_token}`,
        Accept: 'application/json'
      }
    }).then(res => res.json())
    console.log(data)
    const googleUser = data
    const userCheck = await checkUserExists(googleUser.email)
    setAccountExists(userCheck.exists)
    if (userCheck.exists) {
      googleLogin(googleUser.email, response.access_token)
        .then(afterLoginSuccess, (error) => {
          setError(error)
        })
    } else {
      setGResponse({
        ...googleUser,
        token: response.access_token
      })
      setStep(3)
    }
  }

  const emailSubmit = async () => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setError("Valid Email is required")
      return
    }
    setLoading(true)
    setError("")
    const userCheck = await checkUserExists(email)
    setAccountExists(userCheck.exists)
    setStep(3)
    setLoading(false)
  }

  const passwordSubmit = async () => {
    setError("")
    if (!password) {
      setError("Password is required")
      return
    }
    setLoading(true)
    try {
      await loginApi(email.toLowerCase(), password)
      afterLoginSuccess()
    } catch (err) {
      setError(err)
    }
    setLoading(false)
  }

  const forgotPassword = async () => {
    setLoading(true)
    try {
      const res = await resetPasswordRequest(email)
      if (res.success) {
        window.alert('Please check you email for the reset link!')
        window.location.href = '/'
      } else {
        setError(res.error)
      }
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }

  const registerAccount = async () => {
    setLoading(true)
    setError("")
    console.log("Here")
    if (gResponse) {
      const googleUser = gResponse
      await googleRegister({
        email: googleUser.email,
        token: gResponse.token,
        first_name: googleUser.given_name,
        last_name: googleUser.family_name,
        user_type: userType
      })
      await googleLogin(googleUser.email, gResponse.token)
      afterLoginSuccess()
    } else {
      const token = await executeRecaptcha()

      const formData = new FormData()
      formData.append('email', email)
      formData.append('password', password)
      formData.append('user_type', userType)
      if (userType !== USER_TYPES.TALENT) {
        formData.append('first_name', firstName)
        formData.append('last_name', lastName)
      }
      const captchaVerifyResponse = await verifyCaptcha(token)
      if (!captchaVerifyResponse.success) {
        setError('Captcha verfication failed. Refresh your browser and try again!')
      } else {
        const response = await register(formData)
        if (response._id) {
          await loginApi(email.toLowerCase(), password)
          afterLoginSuccess()
        } else {
          setError(response.error)
        }
      }
    }
    setLoading(false)
  }

  const googleRegisterFail = (error) => {
    console.log("Google Sign up error: ", error)
  }

  const googleLoginHook = useGoogleLogin({
    onSuccess: googleRegisterSuccess,
    onFailure: googleRegisterFail
  })

  return (
    <div className="d-flex align-items-center flex-column login-page">
      <AuthHeader />
      {step === 1 && (
        <StepWrapper>
          {projectInfo && projectInfo.studio && <div className='bg-white br-8 p-2 mb-5'>
            <h2 className="h-24-600 text-center"> Welcome to {projectInfo.studio}/{projectInfo.session}</h2>
          </div>}
          <h2 className="text-center mt-4"> Log in or sign up</h2>
          <p className="text-center mb-3 description-text mt-3">
            Use your email or another service to continue with <b>Hey Joe</b>. It's free!
          </p>
          <Button
            className='auth-btn w-100 text-center d-flex justify-content-center mt-4'
            variant='danger'
            onClick={() => { googleLoginHook() }}
            disabled={window.is_react_native}
          >
            <FaGoogle className='auth-btn-icon' />
            <span>Continue with Google</span>
          </Button>
          {window.is_react_native && (
            <div className='mb-3 text-center'>Paste this link in a browser to use Google Sign On</div>
          )}
          <Button
            className='auth-btn'
            variant='danger'
            onClick={() => {
              setStep(2)
            }}
          >
            <FaRegEnvelope className='auth-btn-icon' />
            <span>Continue with Email</span>
          </Button>
          <p className='mt-1'>
            By continuing you agree to Hey Joe's&nbsp;
            <a href='https://heyjoe.io/terms-and-conditions/' target="_blank">Terms of Use</a>.&nbsp;
            Read our <a href='https://heyjoe.io/privacy-policy/' target="_blank" style={{ textDecoration: 'underline' }}>Privacy Policy</a>
          </p>
        </StepWrapper>
      )}
      {step === 2 && (
        <StepWrapper>
          <h2 className=" text-center"> Log in or sign up</h2>
          <p className="text-center mb-3 description-text mt-3">
            User your email or another service to continue with <b>HeyJoe</b> It's free!
          </p>
          <label>Email Address:</label>
          <input
            type="email" value={email} onChange={ev => { setEmail(ev.target.value) }}
            className='form-control mb-1'
            placeholder='Email Address'
          />
          <div className='text-danger'>{error}</div>
          <Button
            className='auth-btn mt-2'
            variant='danger'
            onClick={emailSubmit}
            disabled={loading}
          >
            {loading ? "Processing": "Next"}
          </Button>
          <Button
            className='mt-2'
            variant="flat"
            onClick={() => { setStep(1) }}
          >
            Prev
          </Button>
        </StepWrapper>
      )}
      {step === 3 && (
        <StepWrapper>
          <h2 className=" text-center">
            {accountExists ? "Log In" : "Sign Up"}
          </h2>
          {accountExists ? (
            <div className='d-flex flex-column'>
              <label>Password:</label>
              <input
                type="password" value={password} onChange={ev => { setPassword(ev.target.value) }}
                className='form-control mb-1'
              />
              <div className='text-danger'>{error}</div>
              <div className='my-3 cursor-pointer' onClick={() => { setStep(4) }}>
                Forgot password
              </div>
              <Button
                className='auth-btn mt-2'
                variant='danger'
                onClick={passwordSubmit}
                disabled={loading}
              >
                {loading ? "Processing": "Next"}
              </Button>
              <Button
                className='mt-2'
                variant="flat"
                onClick={() => { setStep(2) }}
              >
                Prev
              </Button>
            </div>
          ) : (
            <div className='d-flex flex-column'>
              {!gResponse && (
                <div className='d-flex flex-column'>
                  <p className="text-center mb-3 description-text mt-3">
                    We couldn't find an account with that email address, please set password
                  </p>
                  {userType !== USER_TYPES.TALENT && (
                    <div className='d-flex mb-2'>
                      <div>
                        <label>First name:</label>
                        <input
                          type="text" value={firstName} onChange={ev => setFirstName(ev.target.value)}
                          name="firstName" className='form-control'
                        />
                      </div>
                      <div>
                        <label>Last name:</label>
                        <input
                          type="text" value={lastName} onChange={ev => setLastName(ev.target.value)}
                          name="lastName" className='form-control'
                        />
                      </div>
                    </div>
                  )}
                  <label>Password:</label>
                  <input
                    type="password" value={password} onChange={ev => { setPassword(ev.target.value) }}
                    className='form-control'
                  />
                </div>
              )}
              {!talentOnly && (
                <div className='d-flex flex-column mt-3 mb-1'>
                  <h6 className=" text-center mb-4"> Tell us what type of account<br /> you need?</h6>
                  <div
                    className={"w-100 auth-user-type-item " + (userType === USER_TYPES.CLIENT ? "selected" : "")}
                    onClick={() => { setUserType(USER_TYPES.CLIENT) }}
                  >
                    <label className="h6 mr-2">Client</label> <br />
                    <span>To view casting sessions</span>
                  </div>
                  <div
                    className={"w-100 auth-user-type-item " + (userType === USER_TYPES.TALENT ? "selected" : "")}
                    onClick={() => { setUserType(USER_TYPES.TALENT) }}
                  >
                    <label className="h6 mr-2">Talent</label> <br />
                    <span>To audition on our platform</span>
                  </div>
                  <div
                    className={"w-100 auth-user-type-item " + (userType === USER_TYPES.CASTING_DIRECTOR ? "selected" : "")}
                    onClick={() => { setUserType(USER_TYPES.CASTING_DIRECTOR) }}
                  >
                    <label className="h6 mr-2">Casting Professional</label>
                    <br />
                    <span>We will reach out to onboard your account</span>
                  </div>
                </div>
              )}
              <div className='text-danger'>{error}</div>
              <Button
                className='auth-btn mt-2'
                variant='danger'
                onClick={registerAccount}
                disabled={loading}
              >
                {loading ? "Processing": "Next"}
              </Button>
              <Button
                className='mt-2'
                variant="flat"
                onClick={() => { setStep(2) }}
              >
                Prev
              </Button>
            </div>
          )}
        </StepWrapper>
      )}
      {step === 4 && (
        <StepWrapper>
          <h2 className=" text-center">
            Forgot Password
          </h2>
          <label>Email Address:</label>
          <input
            type="email" value={email} onChange={ev => { setEmail(ev.target.value) }}
            className='form-control'
            placeholder='Email Address'
          />
          <Button
            className='auth-btn mt-2'
            variant='danger'
            onClick={forgotPassword}
            disabled={loading}
          >
            {loading ? "Processing": "Submit"}
          </Button>
          <Button
            className='mt-2'
            variant="flat"
            onClick={() => { setStep(3) }}
          >
            Prev
          </Button>
        </StepWrapper>
      )}
      <AuthFooter />
    </div>
  )
}

export default AuthPage
