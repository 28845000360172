import React, { useEffect, useState, useRef } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import {FaTimes } from 'react-icons/fa'
import { Editor } from '@tinymce/tinymce-react'
import { sendClientEmail, apiGetAllEmailTemplates }from '../../services'
import { TINYMCE_KEY } from '../../constants'
import { copyHtml } from '../../utils'

export default ({ show, onHide, studio, emailData,onEmailSend }) => {
  const [emails, setEmails] = useState([])
  const editorRef = useRef(null)
  const emailsRef = useRef(null)
  const cursorRef = useRef(null)
  const [isCopied, setIsCopied] = useState(false)
  const [subject, setSubject] = useState(`${studio?.name} talent info`)
  const [initialEmail, setInitialEmail] = useState('')
  const [emailTemplates, setEmailTemplates] = useState([])
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(null)

  useEffect(() => {
    if(emailTemplates.length > 0 && emailData?.template){
        const template = emailTemplates.find(t => t.name === emailData.template)
        setSelectedEmailTemplate(template)
    }
  },[emailTemplates,emailData?.template])

  useEffect(() => {
    if (show && emailData) {
      const loadData = async () => {
        const res = await apiGetAllEmailTemplates()
        setEmailTemplates(res)
      }
      loadData()
    }
  }, [show, emailData])

  useEffect(()=>{
    if(selectedEmailTemplate && emailData?.template){
        let replaceFunc
        if(emailData?.template==="TALENT_RESCHEDULE_APPOINTMENT"){
          replaceFunc = (str) => {
            return str.replace(/EMAIL_CHECKIN_LINK/g, `${emailData?.emailCheckinLink}`)
              .replace(/PROJECT_NAME/g, `${studio?.name}`)
              .replace(/TALENT_NAME/g, `${emailData?.appointment?.name}`)
              .replace(/SESSION_DATE/g, `${emailData?.appointment?.date}`)
              .replace(/TIME/g, `${emailData?.appointment?.time}`)
              .replace(/ROLE/g, `${emailData?.appointment?.role}`)
          }
         
        } else if(emailData?.template==="DELETE_TIME_FRAME"){
          replaceFunc = (str) => {
            return str.replace(/EMAIL_CHECKIN_LINK/g, `${emailData?.emailCheckinLink}`)
              .replace(/PROJECT_NAME/g, `${studio?.name}`)
              .replace(/APPOINTMENT_DATE/g, `${emailData?.appointment?.date}`)
              .replace(/APPOINTMENT_TIME/g, `${emailData?.appointment?.time}`)
          }
        }
        else if(emailData?.template==="SHARE_SCHEDULE_LINK"){
          replaceFunc = (str) => {
            return str.replace(/LINK_TO_SCHEDULE/g, `${emailData?.emailCheckinLink}`)
              .replace(/PROJECT_NAME/g, `${studio?.name}`)
              .replace(/DATE/g, `${emailData?.shareScheduler?.sessionDate}`)
              .replace(/CASTING_DIRECTOR_EMAIL/g, `${emailData?.shareScheduler?.castingDirectorEmail}`)
          }
        }
        setSubject(replaceFunc(selectedEmailTemplate?.subject))
        setInitialEmail(replaceFunc(selectedEmailTemplate?.html))
        
    }
  },[selectedEmailTemplate, emailData])

  useEffect(() => {
    if(emailData?.recipients){
        setEmails(emailData?.recipients)
    }
  }, [emailData?.recipients])

  const removeEmail = (i) => {
    const temp = [...emails]
    temp.splice(i, 1)
    setEmails(temp)
  }
  const addEmail = (e) => {
    e.preventDefault()
    setEmails([...emails, e.target.email.value])
    e.target.reset()
    e.target.email.size = '1'
  }

  const onLoadBtnClick = (e) => {
    if (e.target.files.length > 0) {
      e.target.files[0].text().then(res=>{
        const rows = res.split('\n').map(it=>it.trim()).filter(it=>/^\S+@\S+\.\S+$/.test(it))
        setEmails(rows)
      })
    }
    
  }

  const onEmailTempKeyDown = (e) => {
    if ( e.key == ' ' || e.key == ',') {
      e.preventDefault()
      e.target.form.requestSubmit()
    }
  }
  
  const onSendBtnClick= (e)=>{
    e.preventDefault()
    const data = {
      to: emails,
      content: editorRef.current.targetElm.value,
      subject,
    }
    if(emailData.onlyEmail){
      sendClientEmail(data).then(res=>{
        res.json().then(t=>{
          onHide()
        })
      })
    } else{
      onEmailSend(data, emailData.action)
    }
    
    
  }

  if (!show) return null
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
      className="send-talent-email-modal"
    >
      <Modal.Header closeButton>
        <div>
          <h4 className="mb-0 mr-3">{emailData?.emailModalName}</h4>
          <div className="ml-1">from hello@heyjoe.io</div>
        </div>
      </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-end mb-2">
            <label className="mb-0">Recipients</label>
            <div className="position-relative">
              <Button
                variant="danger"
                block
                size='sm'
              >
                Load from CSV
              </Button>
              <input
                type="file"
                className="position-absolute w-100 h-100 top-0 transparent"
                onChange={onLoadBtnClick}
                accept=".csv"
              />
            </div>
          </div>
          
          <fieldset
            ref={emailsRef}
            className="border rounded p-2 pt-0 w-100  overflow-auto mb-4"
            onClick={()=>{cursorRef.current.focus()}}
          >
            { emails.map((it, i) => (
              <label
                key={i}
                className="bg-lightgray mx-2 my-1 px-2 py-1 rounded"
              >
                {it}
                <FaTimes
                  className="ml-2"
                  onClick={()=>{removeEmail(i)}}
                />
              </label>
            ))}
            <form
              onSubmit={addEmail}
              className="d-inline"
            >
              <input
                type="email"
                required
                name="email"
                className="input-cursor"
                ref={cursorRef}
                onChange={(e)=>{e.target.size = e.target.value.length + 1}}
                onKeyDown={onEmailTempKeyDown}
              />
            </form>
            
          </fieldset>
          <Form.Group>
            <Form.Label>Subject</Form.Label>
            <Form.Control
              value={subject}
              onChange={(e)=>{setSubject(e.target.value)}}
            />
          </Form.Group>
          <label>Email</label>
          <Editor
            key={initialEmail}
            apiKey={TINYMCE_KEY}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={initialEmail}
            init={{
              height: '300px',
              menubar: false,
              relative_urls: false,
              remove_script_host : false,
              convert_urls : true,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="default"
            className="mx-3 px-5"
            onClick={() => {
              copyHtml(editorRef.current.targetElm.value)
              setIsCopied(true)
              setTimeout(() => setIsCopied(false), 3000)
            }}
          >
            {isCopied? 'Copied!': 'Copy Email'}
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="mx-3 px-5"
            onClick={onSendBtnClick}
          >
            Send
          </Button>
        </Modal.Footer>
        
    </Modal>
  )
}
