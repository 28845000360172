import { WS_HOST } from './constants'

let ws = null
let wstm = null
let wsitv = null
let rooms = ['lobby']
let joinedRooms = []

const initWS = () => {
  clearTimeout(wstm)
  clearInterval(wsitv)
  if (!window.localStorage.getItem('token')) {
    wstm = setTimeout(() => {
      initWS()
    }, 1000)
    return
  }

  if (ws) {
    ws.onclose = () => {}
    ws.onclose()
  }
  ws = new WebSocket(WS_HOST)
  ws.onopen = () => {
    const token = window.localStorage.getItem('token')
    rooms.forEach(room => {
      if (!joinedRooms.includes(room)) {
        joinedRooms.push(room)
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({
            token,
            meta: 'join',
            room: room
          }))
        }
      }
    })
    rooms.forEach(room => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({
          token,
          meta: 'join',
          room
        }))
      }
    })
    wsitv = setInterval(() => {
      console.log('ping')
      const token = window.localStorage.getItem('token')
      if (ws.readyState === WebSocket.OPEN) {
        rooms.forEach(room => {
          ws.send(JSON.stringify({ token, meta: 'ping', room }))
        })
      }
      wstm = setTimeout(() => {
        console.log('WS disconnect detected')
        initWS()
      }, 50000)
    }, 30000)
  }

  ws.onclose = () => {
    console.log('WS onclose')
    initWS()
  }

  ws.onmessage = (event) => {
    const ev = JSON.parse(event.data)
    document.body.dispatchEvent(new CustomEvent('ws-message', {
      detail: ev
    }))
  }
}

const joinRoom = (room) => {
  console.log('joinRoom room: ', room);
  if (!rooms.includes(room)) {
    rooms.push(room)
    if (ws && ws.readyState === WebSocket.OPEN) {
      const token = window.localStorage.getItem('token')
      joinedRooms.push(room)
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({
          token,
          meta: 'join',
          room: room
        }))
      }
    }
  }
}

const leaveRoom = (room) => {
  console.log('leaveRoom room: ', room);
  if (rooms.includes(room)) {
    rooms.splice(rooms.findIndex(r => r === room), 1)
    if (ws) {
      const token = window.localStorage.getItem('token')
      joinedRooms.splice(rooms.findIndex(r => r === room), 1)
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({
          token,
          meta: 'leave',
          room: room
        }))
      }
    }
  }
}

if (!ws) {
  initWS()
}

export {
  ws,
  joinRoom,
  leaveRoom
}
