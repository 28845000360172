import React from 'react'
import { Dropdown, Button } from 'react-bootstrap';
import { FaArrowLeft, FaList } from 'react-icons/fa';
import './AuthHeader.scss'

const AuthHeader = ({ history }) => {
  return (
    <div className="vw-100 p-3 d-flex header border-bottom border-danger bg-white auth-header">
      {window.is_react_native && (
        <button className='btn btn-text btn-sm text-white back-btn' onClick={() => history.goBack()}>
          <FaArrowLeft />
        </button>
      )}
      <img src={require('../../assets/heyjoe-color.png')} className="heyjoe-logo" />
      <div className='top-menu'>
        <Button target="_blank" href="https://heyjoe.io/#about" variant='flat'>About Our Service</Button>
        <Button target="_blank" href="https://heyjoe.io/user-faq/" variant='flat'>Casting FAQ</Button>
        <Dropdown>
          <Dropdown.Toggle variant="flat">
            Actor Support
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item target="_blank" href="https://heyjoe.io/actor-troubleshooting/">Troubleshooting</Dropdown.Item>
            <Dropdown.Item target="_blank" href="https://heyjoe.io/actor-troubleshooting/#fridays">Tech Support Fridays</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
     <Button
        variant='danger' className='ml-auto book-button'
        onClick={() => {
          window.open('https://calendar.app.google/rdT9XZBhrUZiTZmd8', '_blank')
        }}
        style={{ color: 'white' }} 
      >
        Book A Demo
    </Button>
      <div className='top-menu-mobile ml-auto'>
        <Dropdown>
          <Dropdown.Toggle variant="flat">
            <FaList />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item target="_blank" href="https://heyjoe.io/#about" >About Our Service</Dropdown.Item>
            <Dropdown.Item target="_blank" href="https://heyjoe.io/user-faq/">Casting Faq</Dropdown.Item>
            <Dropdown.Item target="_blank" href="https://heyjoe.io/actor-troubleshooting/">Troubleshooting</Dropdown.Item>
            <Dropdown.Item target="_blank" href="https://heyjoe.io/actor-troubleshooting/#fridays">Tech Support Fridays</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => {
              window.open('https://calendar.app.google/rdT9XZBhrUZiTZmd8', '_blank')
            }}>
              Book A Demo
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {window.is_react_native && (
        <button className='btn btn-text btn-sm text-danger ' onClick={() => {
          try {
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'leave' }))
            }
          } catch (err) {
            console.log('IGNORE: react native send info failed.', err)
          }
        }}>
          Leave
        </button>
      )}
    </div>
  )
}

export default AuthHeader
