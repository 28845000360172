import { api_host, token } from './consts'

export const saveFeedback = async (data) => {
  return await fetch(`${api_host}/fbkc/feedback`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const listFeedbacks = async (data) => {
  return await fetch(`${api_host}/fbkc/list-feedbacks`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const listPageFeedbacks = async (data) => {
  return await fetch(`${api_host}/fbkc/list-page-feedbacks`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const saveComment = async (data) => {
  return await fetch(`${api_host}/fbkc/comment`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const listComments = async (data) => {
  return await fetch(`${api_host}/fbkc/list-comments`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const listPageComments = async (data) => {
  return await fetch(`${api_host}/fbkc/list-page-comments`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}
export const deleteComments = async (id) => {
  return await fetch(`${api_host}/fbkc/delete-comment`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({id})
  }).then(res => res.json())
}
