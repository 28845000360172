import React, { useEffect, useState } from 'react'
import { Form, Button, Container, Row, Col, Table, Modal, FormControl } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import { getCredentialInfo, updateCredentialInfo } from '../../../services'
import {toggleLoadingState, getUserText} from '../../../utils'

const fields = [
  'twilio_account_sid',
  'twilio_auth_token',
  'twilio_from_number',
  'comet_chat_appid',
  'comet_chat_auth',
  'comet_api_key'
]

const CredentialTab = () => {
  const [cred, setCred] = useState({})
  const [lastUpdateInfo, setlastUpdateInfo] = useState({})
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const loadData = async () => {
    const data = await getCredentialInfo()
    setCred(data)
    setlastUpdateInfo({
      by: getUserText(data.cred_updated_by),
      at: data.cred_updated,
    })
  }
  useEffect(() => {
    loadData()
  }, [])

  const onUpdateConfirmBtnClick = async () => {
    toggleLoadingState(true)
    await updateCredentialInfo(cred)
    await loadData()
    setShowConfirmModal(false)
    toggleLoadingState(false)
  }

  return (
    <Container fluid>
      <h3>Credentials</h3>
      <Row>
        <Col lg={6}>
          <Table borderless>
            <thead>
              <tr>
                <th>Key Name</th>
                <th className="p-2">Value</th>
              </tr>
            </thead>
            <tbody>
              {fields.map(field => {
                return (
                  <tr key={field}>
                    <td>{field}</td>
                    <td>
                      <FormControl
                        className="form-control-sm"
                        value={cred[field]}
                        onChange={ev => {
                          setCred({
                            ...cred,
                            [field]: ev.target.value
                          })
                        }}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <div className="d-flex">
        <div className='mr-5'>
          Updated by <strong>{lastUpdateInfo.by}</strong> at <strong>{lastUpdateInfo.at}</strong>
        </div>
        <Button
          variant="danger"
          onClick={() => { setShowConfirmModal(true) }}
        >
          <FaCheck className="mr-2" />
          Update
        </Button>
      </div>
      <Modal
        show={showConfirmModal}
        onHide={()=>{setShowConfirmModal(false)}}
      >
        <Modal.Header closeButton>
          <h4 className="my-0">Confirmation</h4>
        </Modal.Header>
        <Modal.Body>
          <p>You are about to update service credentials. Please make sure you are doing the right thing.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="px-4 mr-2"
            onClick={onUpdateConfirmBtnClick}
          >
            OK
          </Button>
          <Button
            variant="default"
            onClick={()=>{setShowConfirmModal(false)}}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default CredentialTab
