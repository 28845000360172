import React, { useEffect, useState } from "react";
import { getUserAppointmentByDate, onboardUser, updateAppointmentOneField } from "../../services";
import moment from "moment";
import './style.scss'
import PersonCard from "./PersonCard";
import { formatTime } from "../../utils";

const ScheduledList = ({ session_id, day_id, addToGroup, roles, opportunityIdList, onboardInTime, reload, sendSms }) => {
  const [scheduledAppointments, setScheduledAppointments] = useState([]);
  const [isEveryOneCheckedIn, setIsEveryOneCheckedIn] = useState(false);

  const getOpportunityId = (role) => {
    const roleIndex = roles.findIndex(item => item.toUpperCase() === role.toUpperCase())
    return opportunityIdList[roleIndex]
  }

  useEffect(() => {
    const loadData = async () => {
      try{
        const response = await getUserAppointmentByDate(session_id, day_id);
        const appointments = response.data
        const todayAppointments = appointments.filter(appointment => {
          return moment(new Date()).format('YYYY-MM-DD') === moment(appointment.start_time).format('YYYY-MM-DD') && !appointment?.is_checked_in
        }).sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime());
        setScheduledAppointments(todayAppointments);
  
        if(appointments.length > 0){
          const _todayAppointments = appointments.filter(appointment => moment(new Date()).format('YYYY-MM-DD') === moment(appointment.start_time).format('YYYY-MM-DD'))
          const _isEveryOneCheckedIn = _todayAppointments.length > 0 && _todayAppointments.every(appointment => appointment.is_checked_in)
          setIsEveryOneCheckedIn(_isEveryOneCheckedIn)
        }

      } catch(error){
        console.log('error', error)
      } 
    };
    loadData();
  }, [session_id, day_id, reload]);

  const addRecord = async(appointment, talent) => {

    const sizeObject = JSON.parse(talent.clothes_size_object)
    const opportunityId = getOpportunityId(appointment.role)

    const data = {
      first_name: talent.first_name,
      last_name: talent.last_name,
      email: talent.email,
      phone: talent.phone,
      session: session_id,
      agent: talent.agent || '',
      agent_email: talent.agent_email || '',
      agent_phone: talent.agent_phone || '',
      instagram_handle: talent.instagram_handle || '',
      tiktok_handle: talent.tiktok_handle || '',
      role: appointment.role || '',
      limelite_role_id: opportunityId || '',
      photos: talent.photos, 
      location_type: onboardInTime.location_type,
      height: talent.height,
      clothes_type: talent.clothes_type || '',
      
    }
    if(sizeObject){
      data.clothes_size_object = sizeObject
    }
    onboardUser(data).then((resp) => {
      addToGroup(resp.record?._id)
      update(appointment._id)
    }).catch(error =>{
      console.log('error', error)
    })
  }

  const update = async(appointmentId) =>{
    await updateAppointmentOneField(session_id, day_id, appointmentId, {is_checked_in: true})
  }

  const onAddToGroup = (id) => {
    const selectedAppointment = scheduledAppointments.find(appointment => {
      return appointment._id === id
    })
    addRecord(selectedAppointment, selectedAppointment.talent)
  }

  const renderEmptyStateMsg = () => {
    if(isEveryOneCheckedIn){
      return <div className="text-center h-12-600 px-2 py-4">Every one is checked in for today</div>
    }else{
      return <div className="text-center h-12-600 px-2 py-4">No scheduled appointments for today</div>
    }
  }

  return (
    <div className="scheduled-list">
      {scheduledAppointments.length > 0 ?
        scheduledAppointments.map((item) => {
          return (
            <PersonCard 
                key={item._id}
                {...item.talent}
                _id= {item._id}
                role={item.role}
                avatar={item.talent.logo}
                scheduledime={formatTime(item.start_time)}
                groups={[]}
                setSeen={() => {}}
                setSkipped={() => {}}
                removeRecord={() => {}}
                signOut={() => {}}
                addToGroup={onAddToGroup}
                updateRecord={() => {}}
                isScheduledList={true}
                sendSms={() => sendSms(item.talent.phone)}/>
          );
        }) : renderEmptyStateMsg()}
    </div>
  );
};

export default ScheduledList;
