import { api_host, token } from './consts'


export const getLimeLiteProjects = async ({ email }) => {
  const encodedEmail = encodeURIComponent(email)

  const resp = await fetch(`${api_host}/integrations/limelite/get-projects?email=${encodedEmail}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  return await resp.json()
}

export const updateLimeLiteFolder = async (object) => {
  const resp = await fetch(`${api_host}/integrations/limelite/post-media`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(object)
  })
  return await resp.json()
}
