import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { deletePage, updatePage } from '../../services'
import Switch from "react-switch"

import { update as updateStudioInStore } from '../../store/studios'

import PostingPageCrupdateModal from './PostingPageCrupdateModal'
import { SmallTrashIcon } from '../../assets/icons/session'
import './style.scss'

export default ({ studio, postingPage }) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
  const [showVideoReviewComments, setShowVideoReviewComments] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setShowVideoReviewComments(postingPage?.showVideoReviewComments)
  }, [postingPage])

  const onShowVideoReviewCommentsChange = async(v) => {
    setShowVideoReviewComments(v)
    await updatePage(postingPage._id, { showVideoReviewComments: v })
      .then(res => {
        console.log('res', res)
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  const onConfirmDelete = () => {
    deletePage(postingPage._id).then(res => {
      const idx = studio.postingPages.findIndex(it => it._id == res._id)
      const postingPages = [...studio.postingPages]
      postingPages.splice(idx, 1)
      const temp = { ...studio }
      temp.postingPages = postingPages
      dispatch(updateStudioInStore(temp))
      setShowDeleteConfirmModal(false)
    })
  }

  return (
    <div key={postingPage._id} className="posting-item d-flex align-items-center justify-content-between">
      <span className='h-14-600 ls-2 mr-1'>{postingPage.name}</span>
      <div className="posting-item-right d-flex align-items-center">
        <div className='d-flex align-items-center mr-4'>
          <Switch
            checkedIcon={null} uncheckedIcon={null}
            onColor="#26354D"
            height={16}
            width={32}
            checked={showVideoReviewComments}
            onChange={v => onShowVideoReviewCommentsChange(v)}
          />
          <span className='ml-2 h-12-600'>Show Video Review Comments</span>
        </div>
        <Link to={`/posting-page/${studio.uri}/${postingPage._id}`} className="action-button h-12-600" target="_blank">
          View
        </Link>
        <div className="d-flex align-items-center action-wrap">
          <button
            className='action-button h-12-600'
            onClick={() => { setShowEditModal(true) }}>Edit</button>
          <button
            className='action-button h-12-600'
            onClick={() => { setShowDeleteConfirmModal(true) }}><SmallTrashIcon /></button>
        </div>
      </div>
      <PostingPageCrupdateModal
        show={showEditModal}
        onHide={() => { setShowEditModal(false) }}
        postingPage={postingPage}
        studio={studio}
      />
      <Modal
        show={showDeleteConfirmModal}
        onHide={() => { setShowDeleteConfirmModal(false) }}
      >
        <Modal.Header>
          <h4>Want to delete Posting Page "{postingPage.name || 'Posting page'}"?</h4>
        </Modal.Header>
        <Modal.Footer>
          <Button
            className="btn-w-md"
            variant="danger"
            onClick={onConfirmDelete}
          >
            Yes
          </Button>
          <Button
            variant="light"
            className="btn-w-md"
            onClick={() => { setShowDeleteConfirmModal(false) }}
          >
            Cancel
          </Button>
        </Modal.Footer>

      </Modal>
    </div>
  )
}