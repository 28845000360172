import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { addCommentToRequest, setCommentRead, getUser } from '../../services'
import { joinRoom, leaveRoom } from '../../ws'
import { formatTime } from '../../utils'
import './style.scss'

const FreelancerComment = ({ request, maxHeight='200px' }) => {
  const [comments, setComments] = useState(request.comments || [])
  const [lastViews, setLastViews] = useState(request.last_views || [])
  const [loading, setLoading] = useState('')
  const [newComment, setNewComment] = useState('')

  useEffect(() => {
    if (request && request._id) {
      joinRoom(`request-${request._id}`)
      document.body.addEventListener('ws-message', wsMessageHandler)
    }
    return () => {
      leaveRoom(`request-${request._id}`)
      document.body.removeEventListener('ws-message', wsMessageHandler)
    }
  }, [request])

  useLayoutEffect(() => {
    setTimeout(() => {
      document.querySelector(`.comments-section.comments-${request._id}`).scrollTo({ top: 9999, behavior: 'smooth' })
    }, 100)
    setCommentRead(request._id)
  }, [comments])

  const wsMessageHandler = (ev) => {
    const event = ev.detail
    switch(event.type) {
      case 'comments':
        if (event.request === request._id) {
          setComments(event.comments)
        }
        setLoading(false)
        break
      case 'comments-read':
        if (event.request === request._id) {
          setLastViews(event.last_views)
        }
        break
    }
  }

  let lastViewIndicators = {}
  const user = getUser()

  return (
    <div className="freelancer-comment">
      <div
        className={`comments-section comments-${request._id}`}
        style={{ maxHeight }}
      >
        {comments.map((comment, cIdx) => {
          let viewers = []
          if (cIdx < comments.length - 1) {
            lastViews.forEach(lastView => {
              if (+new Date(comments[cIdx + 1].at) > +new Date(lastView.at) && !lastViewIndicators[lastView.by._id]) {
                lastViewIndicators[lastView.by._id] = lastView.by
                viewers.push(lastView.by)
              }
            })
          }
          return (
            <div key={comment.at}>
              <label className="mb-0">{comment.by.first_name} {comment.by.last_name} <small>at {formatTime(comment.at)}</small></label>
              <p className="mb-2">{comment.content}</p>
              <div className="viewed-by-wrap">
                {viewers.filter(v => v._id !== user.id).map(viewer => {
                  return <small key={viewer._id}>{viewer.first_name} {viewer.last_name}</small>
                })}
              </div>
            </div>
          )
        })}
        <div className="viewed-by-wrap">
          {lastViews.filter(v => v.by._id !== user.id && !lastViewIndicators[v.by._id]).map(lastView => {
            return <small key={lastView.by._id}>{lastView.by.first_name} {lastView.by.last_name}</small>
          })}
        </div>
      </div>
      <div className="comment-wrapper">
        <textarea
          className="form-control mb-2"
          id={`request-${request._id}`}
          value={newComment}
          placeholder="New comment here"
          onChange={ev => setNewComment(ev.target.value)}
        />
        <button
          disabled={!newComment}
          className="btn btn-danger btn-sm position-absolute"
          style={{
            right: 10,
            bottom: 10
          }}
          onClick={() => {
            addCommentToRequest(request._id, newComment)
            setLoading(true)
            setNewComment('')
          }}
        >Send</button>
      </div>
      {loading && <div className="d-inline-block ml-2">Loading...</div>}
    </div>
  )
}

export default FreelancerComment
