/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import ThumbImage from '../../components/ThumbImage'
import { FaCircle, FaMinus, FaUserSlash, FaRecycle,
  FaPencilAlt, FaTimes, FaSms, FaAddressBook, FaBackward, 
  FaSpinner} from 'react-icons/fa'
import { FiRefreshCcw } from 'react-icons/fi'
import { MdRemoveCircle } from 'react-icons/md'
import { formatHour, formatTime } from '../../utils'
import { ON_DECK_STATUS } from '../../constants'

const query = new URLSearchParams(window.location.search)
const isDebug = query.get('debug') === 'true'

const PersonCard = ({
  idx,
  _id,
  showCallIn,
  group,
  first_name,
  last_name,
  email,
  phone,
  skipped,
  seen,
  signed_out,
  checked_in_time,
  actual_call,
  setSeen,
  setSkipped,
  setUnSeen,
  signOut,
  removeRecord,
  addToGroup,
  leaveFromGroup,
  hideDelete,
  showLeave,
  updateRecord,
  groups,
  avatar,
  role,
  agent,
  testMode,
  isTwr = false,
  twr_deleted,
  sendSms,
  on_deck,
  browser_info,
  isScheduledList= false,
  scheduledime= ''
}) => {
  const [showDetail, setShowDetail] = useState(false)
  const dateString = formatTime(checked_in_time)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(isScheduledList){
      setShowDetail(true)
    }
  },[isScheduledList])

  return (
    <div className="video-chat-person-card card text-primary border-0">
      <div className="card-body pr-1">
        <div className="card-title d-flex justify-content-between mb-0">
          <div>
            <h5 className="mb-1 mr-2 cursor-pointer d-flex align-items-center cursor-pointer" onClick={() => {
              if (addToGroup && !testMode) {
                setLoading(true)
                addToGroup(_id)
              }
            }}>
              {!groups.length && <FaCircle className="text-danger mr-2" />}
              {first_name} {last_name}
              {loading && isScheduledList && (<FaSpinner size='16px' className='spinning ml-2' />)}
            </h5>
            <div className='d-flex'>
              <p className="card-text mb-0">
                <span>Role:</span>
            <strong className="ml-2">{role}</strong>
            {!testMode && !isTwr && !isScheduledList && (
              <FaPencilAlt className="text-danger edit-trigger cursor-pointer" onClick={() => updateRecord({ _id, role })} />
            )}
              </p>
            </div>
          </div>
          <div className='d-flex flex-column align-items-end p-1'>
            {twr_deleted && <div className="ml-auto">
                <small>Deleted</small>
            </div>}
            {skipped &&
                <small className="mr-1">Skipped</small>}
            {signed_out &&
              <small className="float-right mr-1">Signed out</small>}
          </div>
        </div>
        <p className="card-text d-none">
          <small>{_id}</small>
        </p>
        <div className="d-flex my-1">
          <p className="mr-2 mb-0">
            <ThumbImage
              isTwr={isTwr}
              src={avatar}
              className={isScheduledList ? "small-avatar" : "avatar"}
              onClick={() => updateRecord({
                _id,
                avatar: avatar || 'empty'
              })}
            />
          </p>
          <div className='d-flex flex-column flex-1'>
            <div className='m-2'>
              {!isTwr && !isScheduledList && (
                <div className="d-flex ml-2 my-2">
                  {!on_deck && (
                    <img
                      onClick={() => setSeen(_id, false, ON_DECK_STATUS.ON_DECK)}
                      className="callin-icon"
                      title="Call in"
                      src={require('../../assets/initial.png')}
                    />
                  )}
                  {on_deck === ON_DECK_STATUS.ON_DECK && (
                    <img
                      onClick={() => {
                        addToGroup(_id).then(() => {
                          setSeen(_id, seen, ON_DECK_STATUS.IN_CASTING)
                        })
                      }}
                      className="callin-icon"
                      title="Call in again"
                      src={require('../../assets/ondeck.png')}
                    />
                  )}
                  {on_deck === ON_DECK_STATUS.IN_CASTING && (
                    <img
                      onClick={() => setUnSeen(_id)}
                      className="callin-icon pt-2"
                      title="Call to Lobby"
                      src={require('../../assets/incasting.png')}
                    />
                  )}
                  <div className='d-flex flex-column justify-content-end pb-2'>
                    {on_deck === ON_DECK_STATUS.IN_CASTING && (
                      <span className="text-danger cursor-pointer mx-2">
                        <FiRefreshCcw
                          title="Call in again"
                          onClick={() => setSeen(_id, seen, ON_DECK_STATUS.IN_CASTING)}
                          size={20}
                        />
                      </span>
                    )}
                    {on_deck === ON_DECK_STATUS.ON_DECK && (
                      <span className="text-danger cursor-pointer mx-2">
                        <MdRemoveCircle
                          title="Remove from Deck"
                          onClick={() => setUnSeen(_id, seen)}
                          size={20}
                        />
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={classnames("align-items-center", {
              'd-none': isTwr || isScheduledList,
              'd-flex': !isTwr && !isScheduledList
            })}>
              <span className="text-danger cursor-pointer mx-2" onClick={() => {
                setShowDetail(!showDetail)
              }}>
                <FaAddressBook size={20} />
              </span>
              {!signed_out && signOut && !testMode && (
                <span className="text-danger cursor-pointer mx-2">
                  <FaUserSlash
                    title="Sign out this user"
                    onClick={() => signOut(_id)}
                    size={20}
                  />
                </span>
              )}
              {!hideDelete && !testMode && (
                <span className="text-danger cursor-pointer mx-2">
                  <FaTimes
                    title="Remove"
                    onClick={() => removeRecord(_id, phone, idx)}
                    size={20}
                  />
                </span>
              )}
              {showLeave && leaveFromGroup && !testMode && (
                <span className="text-danger cursor-pointer mx-2">
                  <FaMinus
                    title="Leave Group"
                    onClick={() => leaveFromGroup(_id)}
                    size={20}
                  />
                </span>
              )}
            </div>
          </div>
        </div>
        <div className={showDetail ? '': 'd-none'}>
          <p className="card-text mb-0">
            <span>Phone:</span>
            <strong className="ml-2">{phone}</strong>
            <label onClick={sendSms} className="d-inline-flex mb-0 ml-2 cursor-pointer" title="Send SMS">
              <FaSms />
            </label>
          </p>
          <p className="card-text mb-0">
            <span>Email:</span>
            <strong className="ml-2">{email}</strong>
          </p>
          {isScheduledList ? (
            <p className="card-text mb-0">
            <span>Scheduled Time:</span>
                 <strong className="ml-2">{scheduledime}</strong>
           </p>
          ) : (
            <>
              <p className="card-text mb-0 actual-call-section">
                <span>Actual Call:</span>
                <strong className="mx-2">{formatHour(actual_call)}</strong>
                {!testMode && !isTwr && (
                  <FaPencilAlt className="text-danger edit-trigger cursor-pointer" onClick={() => updateRecord({ _id, actual_call })} />
                )}
              </p>
              <p className="card-text mb-0">
              <span>Checked In:</span>
                    <strong className="ml-2">{dateString}</strong>
              </p>
            </>
          )}
          
          <p className="card-text mb-0">
            <span>Agent:</span>
            <strong className="ml-2">{agent}</strong>
          </p>
        </div>
        {isDebug && (
          <div>
            {browser_info || ''}
          </div>
        )}
      </div>
    </div>
  )
}

export default PersonCard
