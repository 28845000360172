import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Modal, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import moment from "moment";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import "react-datepicker/dist/react-datepicker.css";
import "./AddTimeFrameModal.scss";
import { timeSlotStyles } from "./react-select-styles";
import { MinusIcon } from "../../../../assets/icons/session";
import useProfile from "../../../TalentPage/hooks/use-profile";

const AddTimeFrameModal = ({
  showModal,
  currentDate,
  setShowModal,
  onAddOrEditTimeFrame,
  hiringRoles,
  timeFramesStartEndDates,
  defaultValues,
  addOrEditTimeFrame,
  appointmentScheduledTimeFrame,
  scheduleAppointmentRolesDetail,
}) => {

  const { timezoneAbbr } = useProfile()

  const slotOptions = [
    {
      label: "5 minutes",
      value: 5,
    },
    {
      label: "10 minutes",
      value: 10,
    },
    {
      label: "15 minutes",
      value: 15,
    },
    {
      label: "30 minutes",
      value: 30,
    },
    {
      label: "60 minutes",
      value: 60,
    },
  ];

  const createOption = useCallback(
    (label) => ({
      label,
      value: label
    }),
    []
  );

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    hiringRoles && setOptions(hiringRoles.map((role) => createOption(role)));
  }, [hiringRoles, createOption]);

  const handleCreate = (inputValue, index) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading(false);
      setOptions((prev) => [...prev, newOption]);
    }, 1000);
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Time Frame Name is required"),
        roles: yup
          .array()
          .of(
            yup.object().shape({
              roleName: yup.object().shape({
                value: yup.string().required("Role Name is required"),
                label: yup.string().required("Role Name is required"),
              }),
              roleNumber: yup.number().required("Role Number is required"),
            })
          )
          .min(1, "At least one role is required"),
        startTime: yup
          .date()
          .required("Start Time is required")
          .test(
            "is-within-range",
            "Start time conflict with other time frame",
            function (value, context) {
              const validDate = timeFramesStartEndDates.some((range) => {
                //range.start_time utc time need to conver current timezone

                const start = moment(range.start_time);
                const end = moment(range.end_time);
                const startDate = moment(currentDate.start_time).format(
                  "YYYY-MM-DD"
                );
                const startTime = moment(value).format("HH:mm");
                const exactStartDate = moment(`${startDate} ${startTime}`);
                const exactEndDate = moment(`${startDate} ${moment(context.parent.endTime).format("HH:mm")}`);

                if (moment(exactStartDate).isSame(end)) {
                  return false;
                }
                if (moment(exactEndDate).isSame(start)) {
                  return false;
                }
                else if(moment(start).isBetween(exactStartDate,exactEndDate,undefined, "[]")){
                  return true;
                }
                return moment(exactStartDate).isBetween(start, end, undefined, "[]");
              });
              return !validDate;
            }
          )
          .test(
            "is-after-now",
            "Please cancel existing appointments",
            function (value) {
              if (addOrEditTimeFrame === "add") {
                return true;
              }
              if (!appointmentScheduledTimeFrame.start) return true;
              const startTime = moment(value);
              const appointmentStartScheduledTimeFrame = moment(
                appointmentScheduledTimeFrame.start,
                "hh:mm A"
              );
              // start time should be grater or equal than currentDateStartTime
              if (
                moment(startTime, "hh:mm A").isSameOrBefore(
                  moment(appointmentStartScheduledTimeFrame, "hh:mm A")
                )
              ) {
                return true;
              }

              return false;
            }
          ).test('is-after-now', 'Start Time must be after current time', function (value) {
            const startDate = moment(currentDate.start_time).format(
              "YYYY-MM-DD"
            );
            const startTime = moment(value).format("HH:mm");
            const newDate = moment(`${startDate} ${startTime}`);
            if (moment(newDate).isAfter(moment())) {
              return true
            }
            return false
          }),
        endTime: yup
          .date()
          .required("End Time is required")
          .test(
            "is-after-start",
            "End Time must be after Start Time",
            function (value, context) {
              const { startTime } = context.parent;
              return moment(value).isAfter(moment(startTime));
            }
          )
          .test(
            "not-same-as-start",
            "End Time cannot be the same as Start Time",
            function (value, context) {
              const { startTime } = context.parent;
              return !moment(value).isSame(moment(startTime));
            }
          )
          .test(
            "is-within-range",
            "End Time conflict with other time frame",
            function (value, context) {
             
              const validDate = timeFramesStartEndDates.some((range) => {
                //range.start_time utc time need to conver current timezone

                const start = moment(range.start_time);
                const end = moment(range.end_time);

                const startDate = moment(currentDate.start_time).format(
                  "YYYY-MM-DD"
                );
                const endTime = moment(value).format("HH:mm");
                const exactEndDate = moment(`${startDate} ${endTime}`);
                const exactStartDate = moment(`${startDate} ${moment(context.parent.startTime).format("HH:mm")}`);
               
                if (moment(exactEndDate).isSame(start)) {
                  return false;
                }
                if (moment(exactStartDate).isSame(end)) {
                  return false;
                }
                else if(moment(end).isBetween(exactStartDate,exactEndDate,undefined, "[]")){
                  return true;
                }

                return moment(exactEndDate).isBetween(start, end, undefined, "[]");
              });
              

              return !validDate;
            }
          )
          .test(
            "is-after-now",
            "Please cancel existing appointments",
            function (value) {
              if (addOrEditTimeFrame === "add") {
                return true;
              }
              if (!appointmentScheduledTimeFrame.end) return true;
              const endTime = moment(value);
              const appointmentEndScheduledTimeFrame = moment(
                appointmentScheduledTimeFrame.end,
                "hh:mm A"
              );
          
              // end time should be grater or equal than currentDateEndTime
              if (
                moment(endTime, "hh:mm A").isSameOrAfter(
                  moment(appointmentEndScheduledTimeFrame, "hh:mm A")
                )
              ) {
                return true;
              }

              return false;
            }
          ).test('is-after-now', 'End Time must be after current time', function (value) {
   
            const startDate = moment(currentDate.start_time).format(
              "YYYY-MM-DD"
            );
            const startTime = moment(value).format("HH:mm");
            const newDate = moment(`${startDate} ${startTime}`);
            if (moment(newDate).isAfter(moment())) {
              return true
            }
            return false
          }),
        timeSlot: yup.object().shape({
          value: yup
            .number()
            .required("Time Slot is required")
            .test(
              "is-duration",
              "Please cancel existing appointments",
              function (value) {
                if (addOrEditTimeFrame === "add") {
                  return true;
                }
                if (appointmentScheduledTimeFrame.isAppointmentAvailable) {
                  if (appointmentScheduledTimeFrame.duration == value) {
                    return true;
                  }
                  return false;
                }
                return true;
              }
            ),
          label: yup.string().required("Time Slot is required"),
        }),
      })
    ),
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "roles",
  });

  const onRemoveRole = (index) => {
    console.log(index)
    remove(index);
  }
  const onSubmit = (data) => {
    const timeFrameData = { ...data };
    onAddOrEditTimeFrame(timeFrameData);
    setShowModal(false);
    reset();
  };
  useEffect(() => {
    reset();
  }, [showModal]);

  const watchRoles = useWatch({
    control,
    name: "roles",
  });
  const watchStartTime = useWatch({
    control,
    name: "startTime",
  });
  const watchEndTime = useWatch({
    control,
    name: "endTime",
  });
  const watchTimeSlot = useWatch({
    control,
    name: "timeSlot",
  });


  const [windowCountText, setWindowCountText] = useState("");
  useEffect(() => {
    let makeWindowCountText = " You’re creating appointment windows for";
    if (
      watchRoles.length > 0 &&
      watchStartTime &&
      watchEndTime &&
      watchTimeSlot
    ) {
      // watchStartTime and watchEndTime difference minutes divided by watchTimeSlot
      // difference
      const diff = moment(watchEndTime).diff(moment(watchStartTime), "minutes");
      const result = diff / watchTimeSlot.value;

      const windowsCounts = [];

      let isRoleAvailable = false;
      watchRoles
        .filter((role) => !!role?.roleName?.value)
        .map((role, index) => {
          isRoleAvailable = true;
          windowsCounts.push({
            roleName: role.roleName.value,
            roleNumber: role.roleNumber * result,
          });
          if (index === watchRoles.length - 1 && index !== 0) {
            makeWindowCountText += ` and `;
          }
          makeWindowCountText += ` ${role.roleNumber * result} ${
            role.roleName.value
          }`;
        });
      if (isRoleAvailable) {
        setWindowCountText(makeWindowCountText);
      }
    }
  }, [watchRoles, watchStartTime, watchEndTime, watchTimeSlot]);

  const onAppendRole = () => {
    // before append check last role is empty or not
    const lastRole = watchRoles[watchRoles.length - 1];
    if (lastRole.roleName.value === "") {
      return;
    }
    append({ roleName: { value: "", label: "" }, roleNumber: 1, lock: false });
  };
  const validateRoleNumber = (value) => {
    console.log(value);
    // Add your custom validation logic here
    if (value < 1) {
      return "Role number must be between 1 and 100";
    }

    return true;
  };

  const onChangeRoleNumberValidate = useCallback((e, field, index) => {
    if (addOrEditTimeFrame === "edit") {
      if (
        scheduleAppointmentRolesDetail[
          watchRoles[index].roleName.value
        ]?.count > e.target.value
      ) {
        setError(`roles[${index}].roleNumber`, {
          type: "manual",
          message:
            "Please cancel existing appointments",
        });
        return;
      }
    }
    clearErrors(`roles[${index}].roleNumber`);
    field.onChange(e.target.value);
  },[addOrEditTimeFrame,scheduleAppointmentRolesDetail, watchRoles])

  const isDisabledRoleSelection = useCallback((index) => {
    if(addOrEditTimeFrame === "edit"){
      if(watchRoles[index]?.lock){
        return scheduleAppointmentRolesDetail[
          watchRoles[index].roleName.value
        ]?.isAnyAppointmentAvailable
      }
      
    }
    return false
  }, [watchRoles, scheduleAppointmentRolesDetail, addOrEditTimeFrame]);
  const onRoleNameChange = useCallback((value, index, field) => {
    const isAlreadyAddRole = watchRoles.filter((role, roleIndex) => {
      return role.roleName.value === value.value;
    })
    if(isAlreadyAddRole.length >0){
      setError(`roles[${index}].roleName.label`, {
        type: "manual",
        message:
          "Role already added",
      });
    } else{
      field.onChange(value);
      clearErrors(`roles[${index}].roleName.label`);
    } 
   
  }, [watchRoles])
  const onRemoveRoleDisabled = useCallback((index) => {
    if(addOrEditTimeFrame === "edit"){
      if(watchRoles[index]?.lock){
        return scheduleAppointmentRolesDetail[
          watchRoles[index].roleName.value
        ]?.isAnyAppointmentAvailable
      }
    }
    return fields.length === 1

  },[watchRoles, addOrEditTimeFrame,scheduleAppointmentRolesDetail, fields])
  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
      className="timeframe-modal"
      centered
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <h5 className="mb-0 h-20-600">
            {addOrEditTimeFrame === "add" ? "Add" : "Edit"} a Time Frame
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-2">
            <div className="single-input-row">
              <label className="h-12-600">Name</label>
              <Controller
                control={control}
                name="name"
                defaultValue=""
                rules={{ required: "Time Frame Name is required" }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Form.Control
                    type="text"
                    placeholder="Time Frame 1"
                    onChange={onChange}
                    value={value}
                    ref={ref}
                    isInvalid={errors.name}
                  />
                )}
              />
              {errors.name && (
                <Form.Text className="text-danger">
                  {errors?.name?.message}
                </Form.Text>
              )}
            </div>
            <div className="single-input-row">
              <span className="h-12-600">Time Zone:&nbsp;</span> 
              <span className="h-14-400">{timezoneAbbr}</span>
            </div>
            <div className="two-input-row">
              <div className="w-50 timeInputWrap">
                <label className="h-12-600">Start Time</label>
                <Controller
                  control={control}
                  name="startTime"
                  rules={{ required: "Start Time is required" }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DatePicker
                      selected={value}
                      onChange={onChange}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat="h:mm aa"
                    />
                  )}
                />
                {errors.startTime && (
                  <Form.Text className="text-danger">
                    {errors?.startTime?.message}
                  </Form.Text>
                )}
              </div>
              <div className="w-50 timeInputWrap">
                <label className="h-12-600">End Time</label>
                <Controller
                  control={control}
                  name="endTime"
                  rules={{ required: "End Time is required" }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DatePicker
                      selected={value}
                      onChange={onChange}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat="h:mm aa"
                    />
                  )}
                />
                {errors.endTime && (
                  <Form.Text className="text-danger">
                    {errors?.endTime?.message}
                  </Form.Text>
                )}
              </div>
            </div>
            <div className="single-input-row">
              <label className="h-12-600">Length of a Time Slot</label>
              <div>
                <Controller
                  control={control}
                  name="timeSlot"
                  rules={{ required: "time slot is required" }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      isSearchable={false}
                      defaultValue={{
                        label: "15min",
                        value: 15,
                      }}
                      onChange={onChange}
                      options={slotOptions}
                      styles={timeSlotStyles}
                    />
                  )}
                />
                {errors.timeSlot && (
                  <Form.Text className="text-danger">
                    {errors.timeSlot?.value?.message}
                  </Form.Text>
                )}
              </div>
            </div>
            <div className="single-input-row mb-0">
              <label className="h-12-600">
                Role-specific Participant Count for Each Time Slot
              </label>
              <label className="h-12-400 secondary-color">
                Efficiently assign the number of participants needed for each
                role within designated time slots.
              </label>
              <div className="roles-container">
                {fields.map((field, index) => {
                  return (
                    <div key={field.id}>
                      <div className="role-input-row">
                        <Controller
                          name={`roles[${index}].roleName`}
                          control={control}
                          render={({ field }) => (
                            <CreatableSelect
                              {...field}
                              isDisabled={isDisabledRoleSelection(index)}
                              onChange={(value) => {
                                onRoleNameChange(value, index, field);
                                
                              }}
                              onCreateOption={(label) => {
                                const newOption = {
                                  value: label,
                                  label: label,
                                };
                                handleCreate(label, index);
                                field.onChange(newOption);
                              }}
                    
                              ref={field.ref}
                              options={options}
                              value={field.value}
                              styles={timeSlotStyles}
                              placeholder="Enter Role Name"
                            />
                          )}
                        />
                        <Controller
                          name={`roles[${index}].roleNumber`}
                          control={control}
                          defaultValue={1}
                          rules={{
                            validate: validateRoleNumber,
                          }}
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              required
                              type="number"
                              name="role number"
                              placeholder="1"
                              min={1}
                              className="role-number-input"
                              value={field.value}
                              onChange={(e) => {
                                onChangeRoleNumberValidate(e, field, index);
                              }}
                            />
                          )}
                        />
                        <button
                          type="button"
                          className={
                            "minus-button" +
                            (fields.length === 1 ? " disabled" : "")
                          }
                          onClick={(e) => {
                            console.log(e)
                            e.preventDefault(); 
                            onRemoveRole(index)
                          }}
                          disabled={onRemoveRoleDisabled(index)}
                        >
                          <MinusIcon />
                        </button>
                      </div>
                      {errors.roles?.[index] && (
                        <Form.Text className="text-danger mt-1">
                          {errors.roles?.[index]?.roleName?.label?.message}
                        </Form.Text>
                      )}
                      {errors.roles?.[index] && (
                        <Form.Text className="text-danger mt-1">
                          {errors.roles?.[index]?.roleNumber?.message}
                        </Form.Text>
                      )}
                    </div>
                  );
                })}
              </div>
              <button className="add-role-button" onClick={onAppendRole}>
                + Add Role
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <span className="h-12-400 secondary-color">{windowCountText}</span>
          <button className="btn btn-primary br-8 h-14-700" type="submit">
            {addOrEditTimeFrame === "add" ? "Add" : "Edit"} Time Frame
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddTimeFrameModal;
