import React, { useState, useEffect } from "react";
import "./TimeFrames.scss";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  MoreBlackIcon,
  MoreWhiteIcon,
} from "../../../../assets/icons/session";
import { cancelAppointment } from "../../../../services/appointment";

import Dropdown from "react-bootstrap/Dropdown";
import SendTalentRemoveEmailModal from "../../../Studio/SendTalentRemoveEmailModal";
import TalentInfoModal from "../TalentInfoModal/TalentInfoModal";
import ScheduledAppointmentItem from "./ScheduledAppointmentItem";
import DragAndDropAppointment from "./DragAndDropAppointment";
import useProfile from "../../../TalentPage/hooks/use-profile";



const TimeFrames = ({
  availableTimeFrames,
  newlyCreatedTimeFrameId,
  setShowModal,
  studio,
  session_id,
  day_id,
  refreshTimeFrames,
  onEditTimeFrame,
  onDragAndDropAppointment,
  onDeleteTimeFrame,
  onShareTimeFrameAppointment,
  isAdmin
}) => {
  const [selectedTimeFrameId, setSelectedTimeFrameId] = useState(null);
  const [selectedTalentId, setSelectedTalentId] = useState(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [removeAppointment, setRemoveAppointment] = useState(null);
  const [showTalentInfoModal, setShowTalentInfoModal] = useState(false);
  const [selectedTalent, setSelectedTalent] = useState(null);
  const { timezoneAbbr} = useProfile();

  let itemHeight = 68;

  useEffect(() => {
    if (newlyCreatedTimeFrameId) {
      setSelectedTimeFrameId(newlyCreatedTimeFrameId);
    }
  }, [newlyCreatedTimeFrameId]);
  useEffect(() => {
    let isSelectedTimeFrameIdAvailable = false
    let firstTimeFrameId = null
    Object.keys(availableTimeFrames).some((date) => {

      return Object.keys(availableTimeFrames[date]).some((timeFrame, index) => {
        if(index===0){
          firstTimeFrameId = timeFrame
        }
        if (!selectedTimeFrameId) {
          setSelectedTimeFrameId(timeFrame);
          return true;
        }
        else if(selectedTimeFrameId===timeFrame){
          isSelectedTimeFrameIdAvailable = true
          return true
        }
        
      });
    });
    if(!isSelectedTimeFrameIdAvailable){
      setSelectedTimeFrameId(firstTimeFrameId)
    }
  }, [availableTimeFrames]);

  const onCancelAppointment = (appointment) => {
    setSelectedTalentId(appointment.appointmentId);
    setRemoveAppointment(appointment);
    setShowRemoveModal(true);
  };

  const handleConfirmRemove = async () => {
    try {
      await cancelAppointment({ session_id, day_id, id: selectedTalentId });
      refreshTimeFrames();
    } catch (error) {
      console.log("handleConfirmRemove error:>", error);
    }
  };
  const handleDrop = (item, monitor, dropResult) => {
    onDragAndDropAppointment(item, monitor, dropResult);
  };
  return (
    <div className="time-frames">
      <div className="time-frame-header">
        {Object.keys(availableTimeFrames).map((date) => {
          return Object.keys(availableTimeFrames[date]).map((timeFrame) => {

            const timeFrameName = `${availableTimeFrames[date][timeFrame]?.name} ${availableTimeFrames[date][timeFrame]?.time_slot_start} - ${availableTimeFrames[date][timeFrame]?.time_slot_end}`

            return (
              <div key={timeFrame} className="header-btn-container">
                <button
                  className={
                    "h-12-600 time-frame-header-btn" +
                    (selectedTimeFrameId === timeFrame ? " selected" : "") + (isAdmin ? " pr-32" : "")
                  }
                  onClick={() => setSelectedTimeFrameId(timeFrame)}
                  title={timeFrameName}
                >
                  {timeFrameName}
                </button>

                {isAdmin && (
                  <Dropdown className="dropdown-container">
                    <Dropdown.Toggle id="dropdown-basic">
                      <div
                        className={
                          "more-btn" +
                          (selectedTimeFrameId === timeFrame
                            ? " more-selected"
                            : "")
                        }
                      >
                        {selectedTimeFrameId === timeFrame ? (
                          <MoreWhiteIcon />
                        ) : (
                          <MoreBlackIcon />
                        )}
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => {
                        setSelectedTimeFrameId(timeFrame)
                        onEditTimeFrame(timeFrame, date)
                        }}>
                        Edit Time Frame
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => onShareTimeFrameAppointment(timeFrame)}>
                        Share Scheduling Link
                      </Dropdown.Item>
                      <Dropdown.Item onClick={()=>onDeleteTimeFrame(timeFrame, date, availableTimeFrames[date][timeFrame]?.time_slot_start, availableTimeFrames[date][timeFrame]?.time_slot_end)}>Remove</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            );
          });
        })}
        {isAdmin && (
          <button
            className="h-12-600  time-frame-header-btn border-dash"
            onClick={() => setShowModal(true)}
          >
            + Add Time Frame
          </button>
        )}
      </div>
      <div className="time-frame-content">
        <DndProvider backend={HTML5Backend}>
          {Object.keys(availableTimeFrames)?.map((date) => {
            return availableTimeFrames[date][selectedTimeFrameId]?.slots?.map(
              (timeSlot, index) => {
                return (
                  <div className="time-frame-item" key={index}>
                    <div className="h-12-400 secondary-color time-slot-container ">
                      {timeSlot.start} ({timezoneAbbr})
                      <div className="horizontal-line" />
                    </div>
                    {Object.keys(timeSlot?.talents_with_roles)?.map(
                      (role, roleIndex) => {
                        
                        const participants_count = timeSlot.talents_with_roles[role].participants_count
                        const height = ((participants_count * itemHeight) +  (participants_count - 1) * 8 + 16) + 'px' //8 = gap between items, 16 = padding top and bottom
                      
                        return (
                          <div className="role-item" key={roleIndex}>
                            <div className="time-frame-content-header">
                              <span className="h-12-600">{role}</span>
                              <div className="role-count-container">
                                <span className="h-12-600">
                                  {
                                    timeSlot.talents_with_roles[role]?.talents
                                      ?.length
                                  }
                                </span>
                                <span className="h-12-600 secondary-color">
                                  {" "}
                                  /{" "}
                                  {
                                    timeSlot.talents_with_roles[role]
                                      .participants_count
                                  }
                                </span>
                              </div>
                            </div>
                            <DragAndDropAppointment
                              onDrop={handleDrop}
                              targetTimeSlot={timeSlot}
                              targetTalentsWithRole={role}
                              date={date}
                              
                              lengthTimeSlot={availableTimeFrames[date][selectedTimeFrameId].length_time_slot}
                              isAppointmentFull={
                                timeSlot.talents_with_roles[role]?.talents
                                  ?.length ==
                                timeSlot.talents_with_roles[role]
                                  ?.participants_count
                              }
                              isAdmin={isAdmin}
                            >
                              <div className="time-frame-content-body" style={{height: height}}>
                                {timeSlot.talents_with_roles[
                                  role
                                ]?.talents?.map((talent) => {
                                  return (
                                    <ScheduledAppointmentItem
                                      key={talent?.talent?._id}
                                      talent={talent}
                                      setShowTalentInfoModal={
                                        setShowTalentInfoModal
                                      }
                                      setSelectedTalent={setSelectedTalent}
                                      onCancelAppointment={onCancelAppointment}
                                      date={date}
                                      role={role}
                                      isAdmin={isAdmin}
                                    />
                                  );
                                })}
                              </div>
                            </DragAndDropAppointment>
                          </div>
                        );
                      }
                    )}
                  </div>
                );
              }
            );
          })}
        </DndProvider>
      </div>
      <SendTalentRemoveEmailModal
        show={showRemoveModal}
        onHide={() => {
          setShowRemoveModal(false);
        }}
        studio={studio}
        handleConfirmRemove={handleConfirmRemove}
        appointment={removeAppointment}
      />
      <TalentInfoModal
        showTalentInfoModal={showTalentInfoModal}
        setShowTalentInfoModal={setShowTalentInfoModal}
        {...selectedTalent}
        studio={studio}
        hideContact={false}
        hideCommentSection={true}
        hideFeedbackComments={true}
        showEditButton={false}
      />
    </div>
  );
};

export default TimeFrames;
