import { api_host, token } from './consts'

export const getNotification = async () => {
  return await fetch(`${api_host}/admin/notification`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const updateNotification = async (data) => {
  return await fetch(`${api_host}/admin/notification`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const getServices = async () => {
  return await fetch(`${api_host}/admin/autoscaling-info`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const updateAutoScalingGroup = async (data) => {
  return await fetch(`${api_host}/admin/update-autoscaling-capacity`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const getGoogleCalendarEvents = async(data) => {
  return await fetch(`${api_host}/admin/events?start=${data.start}&end=${data.end}`,{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const apiGetAllEmailTemplates = async () => {
  return await fetch(`${api_host}/admin/email-templates`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const apiUpdateEmailTemplate = async (id, formData) => {
  return await fetch(`${api_host}/admin/email-templates/${id}`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`
    },
    body: formData
  }).then(res => res.json())
}

export const getCredentialInfo = async () => {
  return await fetch(`${api_host}/admin/cred`, {
    headers: { 'Authorization': `Bearer ${token}` }
  }).then(res => res.json())
}

export const updateCredentialInfo = async (data) => {
  return await fetch(`${api_host}/admin/cred`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const createEmailTemplate = async (data) => {
  return await fetch(`${api_host}/admin/email-templates`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const deleteEmailTemplate = async (id) => {
  return await fetch(`${api_host}/admin/email-templates/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`
    },
  }).then(res => res.json())
}
