/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react'
import moment from 'moment'
import cometInject from './comet-inject'
import List from '../CheckinList'
import {
  getStudioByUri,
  getOneSession,
  createCometRoom,
  fetchCheckInList,
  getCurrentGroup,
  static_root,
  getUser,
  updateSession,
  directUpload,
  addVideoEntry,
  getLimeLiteProjects
} from '../../services'
import './style.scss'
import { FaCircle, FaExclamationTriangle, FaMinus } from 'react-icons/fa'
import MeetFrame from './MeetFrame'
import SizeCards from './SizeCards'
import GroupVideos from './GroupVideos'
import { formatTime } from '../../utils'
import { TitleContext } from '../../Context'
import Error404 from '../Errors/404'
import { joinRoom, leaveRoom } from '../../ws'
import { Button, Modal } from 'react-bootstrap'
import SessionFilesForm from '../../components/SessionFilesForm'
import { Editor } from '@tinymce/tinymce-react'
import { SESSION_LOCATION_TYPE, TINYMCE_KEY, USER_TYPE, USER_TYPES } from '../../constants'
import ThumbImage from '../../components/ThumbImage'

class HomePage extends Component {
  static contextType = TitleContext
  constructor(props) {
    super(props)
    let search = window.location.search.substring(1);
    let queryParams = {}
    try {
      queryParams = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
    } catch (err) {
      queryParams = {}
    }

    this.state = {
      studio: null,
      session: null,
      showChat: true,
      showList: true,
      jitsiKey: 0,
      groupCandidates: [],
      candidates: [],
      testMode: queryParams.test,
      videoUploading: false,

      twrGroupCandidates: [],
      twrCandidates: [],
      selectedDate: '',
      listTab: 'heyjoe',
      user: null,

      ws_connected: false,
      errorPage: false,
      currentDateIdx: null,
      dateFormData: null,
      showFilesEditModal: false,
      showNotesEditModal: false,
      mediaFiles: [],
      inPersonFrame: false,
      remoteFrame: false,
      backOfficeFrame: false,
      reload: 0
    }

    this.wsMessageHandler = this.wsMessageHandler.bind(this)
  }

  componentWillUnmount() {
    const header = document.querySelector('.global-header')
    if (header) {
      header.classList.remove('bg-success')
      header.classList.add('bg-danger')
    }
    const headerBtns = document.querySelectorAll('.global-header button')
    headerBtns.forEach(btn => {
      btn.classList.remove('btn-success')
      btn.classList.add('btn-danger')
    })
    if (this.cometAuthScript) {
      this.cometAuthScript.parentElement.removeChild(this.cometAuthScript)
    }
    if (this.chatScriptSecondDom) {
      this.chatScriptSecondDom.parentElement.removeChild(this.chatScriptSecondDom)
    }
    const session_id = this.props.match.params.session_id
    leaveRoom(session_id)
    document.body.removeEventListener('ws-message', this.wsMessageHandler)
    document.body.removeEventListener('session-date-edit-files', this.setShowFilesEditModal)
    document.body.removeEventListener('session-date-edit-note', this.setShowNotesEditModal)
  }

  async componentDidMount() {
    const studio_uri = this.props.match.params.uri
    const session_id = this.props.match.params.session_id
    const studio = await getStudioByUri(studio_uri)
    const session = await getOneSession(session_id)
    const candidates = await fetchCheckInList(session._id)
    const currentGroup = await getCurrentGroup(session._id) || {}
    const user = getUser()
    const { setTitle } = this.context

    if (session.is_deleted) {
      this.setState({ errorPage: true })
      return
    }

    try {
      const dt = session.dates.find(st => moment(st.start_time).isSame(moment(), 'day'))
      const dayType = dt && dt.start_time_type || ''

      let ipf = false, rf = false

      const sessionDateData = session ? session.dates.find(date => {
        return new Date().toDateString() === new Date(date.start_time).toDateString()
      }) : null
      switch(sessionDateData?.location_type) {
        case SESSION_LOCATION_TYPE[0]: // Virtual
          rf = true
          ipf = false
          break
        case SESSION_LOCATION_TYPE[1]: //In person
          rf = false
          ipf = true
          break
        case SESSION_LOCATION_TYPE[2]: //Both
          rf = true
          ipf = true
          break
        default:
          rf = false
          ipf = false
      }

      if (USER_TYPE.IS_CLIENT() || USER_TYPE.IS_TALENT()) {
        ipf = false
        rf = true
      }

      this.setState({
        user,
        studio,
        session,
        candidates: candidates.map((c, idx) => ({
          ...c,
          number: idx + 1
        })),
        groupCandidates: currentGroup.records,
        currentDateIdx: dt && session.dates.findIndex(d => dt._id === d._id),
        inPersonFrame: ipf,
        remoteFrame: rf
      })

      setTitle(`${studio.name} ${dayType}`)

      document.body.dispatchEvent(new CustomEvent('current-session', {
        detail: session
      }))
    } catch (error) {
      console.log('verrorerrorerror', error)
      this.setState({ errorPage: true })
      return
    }

    const pageTitle = this.state.testMode ? 'Virtual Lobby' : 'Video Chat'
    document.title = `${studio.name} ${pageTitle}`;
    if (this.state.testMode) {
      document.querySelectorAll('.global-header button').forEach(btn => {
        btn.classList.remove('btn-danger')
      })
      document.querySelectorAll('.global-header button').forEach(btn => {
        btn.classList.add('btn-success')
      })
      document.querySelector('.global-header').classList.remove('bg-danger')
      document.querySelector('.global-header').classList.add('bg-success')
    }
    if (studio.logo) {
      document.querySelector('#header-logo').innerHTML = `<img src="${static_root + studio.logo}" class="header-logo" />`
    }

    await createCometRoom(studio._id, session._id)

    let cometAuthScript = document.createElement('script')
    cometAuthScript.innerHTML = `
      var chat_appid = '${studio.comet_chat_appid}';
      var chat_auth = '${studio.comet_chat_auth}';

      (function() {
        var chat_css = document.createElement('link');
        chat_css.rel = 'stylesheet';
        chat_css.type = 'text/css';
        chat_css.href = 'https://fast.cometondemand.net/'+chat_appid+'x_xchat.css';
        document.getElementsByTagName("head")[0].appendChild(chat_css);
      })();

      ${cometInject}
    `
    document.body.appendChild(cometAuthScript)
    this.cometAuthScript = cometAuthScript

    if (!document.getElementById('chat')) return
    document.getElementById('chat').innerHTML = `
      <div
        id="cometchat_embed_chatrooms_container"
        style="display: inline-block;">
      </div>
    `

    const email = window.localStorage.getItem('email')

    let chatScriptSecondDom = document.createElement('script')
    chatScriptSecondDom.innerHTML = `
      var chat_id = '${email}';
      var chat_name = '${email}';
      var chat_avatar = '';
      var chat_link = '#';
      var chat_role = 'Guest';

      var iframeObj = {};
      iframeObj.module="chatrooms";
      iframeObj.style="min-height:120px;min-width:300px;";
      iframeObj.src="https://54561.cometondemand.net/cometchat_embedded.php?guid=${studio._id}-${session._id}";
      iframeObj.width="100%";
      iframeObj.height="100%";
      if(typeof(addEmbedIframeExternal)=="function") {
        setTimeout(() => {
          addEmbedIframeExternal(iframeObj);
        }, 1000)
      }
    `
    document.body.appendChild(chatScriptSecondDom)
    this.chatScriptSecondDom = chatScriptSecondDom

    joinRoom(session._id)
    document.body.addEventListener('ws-message', this.wsMessageHandler)

    document.body.addEventListener('session-date-edit-files', this.setShowFilesEditModal)
    document.body.addEventListener('session-date-edit-note', this.setShowNotesEditModal)

  }

  setMediafiles = (value) => {
    this.setState({ mediaFiles: value });
  };

  setInPersonFrame = (value) => {
    this.setState({ inPersonFrame: value })
  }

  setRemoteFrame = (value) => {
    this.setState({ remoteFrame: value })
  }

  setBackOfficeFrame = (value) => {
    this.setState({ backOfficeFrame: value })
  }

  wsMessageHandler(event) {
    const user = getUser()
    const session_id = this.props.match.params.session_id
    try {
      const ev = event.detail
      console.log('ev: ', ev);
      switch (ev.type) {
        case 'pong':
          clearTimeout(this.wstm)
          break
        case 'group':
          this.setState({
            groupCandidates: ev.data.records,
            session: {
              ...this.state.session,
              currentGroup: ev.data._id
            }
          })
          break
        case 'record':
          this.setState({
            reload: Math.random()
          })
          const rIdx = this.state.candidates.findIndex(r => r._id === ev.data._id)
          if (rIdx === -1) {
            this.setState({
              candidates: this.state.candidates.concat({
                ...ev.data,
                number: this.state.candidates.length + 1
              })
            })
          } else {
            this.setState({
              candidates: this.state.candidates.map((c, idx) => {
                return idx === rIdx ? {
                  ...ev.data,
                  number: idx + 1
                } : c
              })
            })
          }
          break
        case 'record-remove':
          this.setState({
            candidates: this.state.candidates.filter(c => c._id !== ev.data).map((c, idx) => {
              return {
                ...c,
                number: idx + 1
              }
            })
          })
          break
        case 'set-record-feedback':
          const record = this.state.candidates.find(r => r._id === ev.data._id)
          const rrIdx = this.state.candidates.findIndex(r => r._id === ev.data._id)
          const feedbackUserEmail = ev.data.feedback_data.email
          const feedbackUserId = ev.data.feedback_data.user_id
          const feedbackChoice = ev.data.feedback_data.feedback
          const feedbacks = {
            ...record.feedbacks
          }
          if (feedbackUserId === user.id || !this.state.session.feedbackPrivates[feedbackUserId] ||
            (this.state.session.feedbackPrivates[feedbackUserId] === 'yes-private' && feedbackChoice === 'yes')) {
            feedbacks[feedbackUserEmail] = feedbackChoice
          }
          this.setState({
            candidates: this.state.candidates.map((c, idx) => {
              return idx === rrIdx ? {
                ...c,
                feedbacks,
                number: idx + 1
              } : c
            })
          })
          break
        case 'clear-records':
          this.setState({ candidates: [] })
          break
        case 'feedback-private-update':
          (async () => {
            const session = await getOneSession(session_id)
            const candidates = await fetchCheckInList(session._id)
            this.setState({
              session,
              candidates: candidates.map((c, idx) => ({
                ...c,
                number: idx + 1
              }))
            })
          })()
          break
        default:
          break
      }
    } catch (err) {
      console.log('socket msg handle err: ', err);
    }
  }

  reloadSession = async () => {
    const session_id = this.props.match.params.session_id
    const session = await getOneSession(session_id)

    this.setState({ session })
    document.body.dispatchEvent(new CustomEvent('current-session', {
      detail: session
    }))
  }

  setShowFilesEditModal = () => {
    this.setState({
      showFilesEditModal: true,
      dateFormData: this.state.session.dates[this.state.currentDateIdx]
    })
  }

  setShowNotesEditModal = () => {
    this.setState({
      showNotesEditModal: true,
      dateFormData: this.state.session.dates[this.state.currentDateIdx]
    })
  }

  setDateFormData = (dateFormData) => {
    this.setState({
      dateFormData
    })
  }

  saveDateData = async () => {
    const { dateFormData, session, currentDateIdx } = this.state
    const newDates = [...session.dates]
    newDates[currentDateIdx] = dateFormData
    const formData = new FormData()
    formData.append('dates', JSON.stringify(newDates))
    await updateSession(session._id, formData)
    this.setState({
      showFilesEditModal: false,
      showNotesEditModal: false
    })
    this.reloadSession()
  }

  setshowChat = (v) => {
    this.setState({
      showChat: v
    })
    if (!v) {
      document.querySelector('#comet-chat').style.display = 'none'
      this.chatWindowHandle = window.open(document.querySelector('#comet-chat iframe').src, 'MsgWindow', 'width=800px,height=400px;')
    } else {
      document.querySelector('#comet-chat').style.display = 'block'
      if (this.chatWindowHandle) {
        this.chatWindowHandle.close()
      }
    }
  }

  setShowList = (v) => {
    this.setState({
      showList: v
    })
  }

  reloadJitsi = () => {
    this.setState({
      jitsiKey: this.state.jitsiKey + 1
    })
  }

  leaveCurrentGroup = () => {
    this.listRef.finishCurrentGroup()
  }

  leaveFromGroup = (id) => {
    this.listRef.leaveFromGroup(id)
  }

  setUnSeen = (id) => {
    this.listRef.setUnSeen(id)
  }

  setListRef = (elem) => {
    this.listRef = elem
  }

  handleUploadNewVideo = async (file) => {
    const { session, studio, testMode } = this.state
    if (!session || !session.currentGroup) { return }
    this.setState({ videoUploading: true })

    const meeting_id = testMode ? studio.test_meeting_id : studio.jitsi_meeting_id
    const res = await directUpload(file, meeting_id)
    await addVideoEntry(res.Key, session.currentGroup, session._id, '')

    this.setState({ mediaFiles: [...this.state.mediaFiles, {fileName: res.Key, fileKey: res.Key, mimeType: 'video/mp4'}]})

    document.querySelector('#group-video-reload-btn').click()
    this.setState({ videoUploading: false })
  }

  render() {
    const { studio, session, showChat, showList, candidates: hjCandidates,
      jitsiKey, groupCandidates: hjGroupCandidates, testMode, ws_connected,
      listTab, twrCandidates, twrGroupCandidates, selectedDate, videoUploading,
      user, dateFormData, showFilesEditModal, showNotesEditModal
    } = this.state
    if (this.state.errorPage) {
      return <Error404 />
    }
    if (!studio) {
      return <div>Loading...</div>
    }
    let candidates = listTab === 'heyjoe' ? hjCandidates : twrCandidates
    const groupCandidates = (listTab === 'heyjoe' || listTab === 'scheduled') ? hjGroupCandidates : twrGroupCandidates
    const meeting_id = testMode ? studio.test_meeting_id : studio.jitsi_meeting_id
    const isTwr = listTab === 'twr'

    const candidateRoles = []
    let rs = []
    let opportunityIdList = []

    candidates.forEach(c => {
      if (c.role && !candidateRoles.includes(c.role)) {
        candidateRoles.push(c.role)
      }
    })

    if(studio?.limelite_project_id){
      rs = studio?.project_roles;
      opportunityIdList = studio?.limelite_project_role_ids;
    } else{
      const studioRoles = studio?.project_roles ? studio.project_roles.filter(role => typeof role === 'string').map(role => role.toUpperCase()) : [];
      rs = [...new Set([...studioRoles, ...candidateRoles])];
    }

    const dates = [...new Set([...candidates.map(c => formatTime(c.checked_in_time, 'YYYY-MM-DD'))])]
    if (selectedDate) {
      candidates = candidates.filter(c => formatTime(c.checked_in_time, 'YYYY-MM-DD') === selectedDate)
    }

    return (
      <div className="homepage-wrapper">
        <div className='ws-indicator'>
          <FaCircle className={ws_connected ? 'text-success' : 'text-danger'} />
        </div>
        <div className={"homepage " + (testMode ? 'test' : '')}>
          <div id="checkin-list" className={`no-print ${showList ? 'show' : ''}`}>
            <div
              id="list"
            >
              <List
                ref={this.setListRef}
                testMode={testMode}
                studio={studio}
                session={session}
                reloadSession={this.reloadSession}
                messages={studio.position_messages}
                delete_message={studio.delete_message}
                candidates={candidates}
                groupCandidates={groupCandidates}
                dates={dates}
                selectedDate={selectedDate}
                roles={rs}
                setTwrGroupCandidates={gcs => this.setState({ twrGroupCandidates: gcs })}
                setSelectedDate={d => this.setState({ selectedDate: d })}
                setTwrCandidates={cs => this.setState({ twrCandidates: cs })}
                setListTab={t => this.setState({ listTab: t })}
                opportunityIdList={opportunityIdList}
                inPersonFrame={this.state.inPersonFrame}
                remoteFrame={this.state.remoteFrame}
                setInPersonFrame={this.setInPersonFrame}
                setRemoteFrame={this.setRemoteFrame}
                backOfficeFrame={this.state.backOfficeFrame}
                reload={this.state.reload}
              />
            </div>
            <button className="d-none btn px-1 py-0 border-right-0" onClick={() => this.setShowList(!showList)}>
              {!showList ? '〉' : '〈'}
            </button>
          </div>
          <div className="right-frame">
            <div className="frame-wrapper no-print">
              <MeetFrame
                studio={studio}
                meeting_id={meeting_id}
                session={session}
                inPersonFrame={this.state.inPersonFrame}
                remoteFrame={this.state.remoteFrame}
                backOfficeFrame={this.state.backOfficeFrame}
              />
              <div className={`d-flex bottom-panel ${showChat ? 'show' : ''}`}>
                <button
                  className="btn border-bottom-0 toggle-bottom"
                  onClick={() => this.setshowChat(!showChat)}
                  title="Click to toggle current group and chat window."
                >
                  {!showChat ? '〉' : '〈'}
                </button>
                {!testMode && (
                  <div id="current-group" className="px-2">
                  <h6 className="mx-n2 px-2 text-center">
                  {groupCandidates.length === 0 &&
                    <div className="mt-10-custom d-flex justify-content-center">
                      <FaExclamationTriangle style={{ color: '#FFC107' }} />
                      <span className="ml-2">No talent in current group</span>
                    </div>
                  }
                </h6>
                   <ul className='mb-1'>
                      {groupCandidates.map((person, index) => (
                        <li key={person._id} className="group-item">
                          <div className="d-flex align-items-center">
                            <ThumbImage
                              src={person.avatar}
                              className="mini-logo mr-2 ml-1"
                            />
                            <img
                              className="callin-button cursor-pointer mr-2" // Changed class name for the call-in button
                              title="Call to Lobby"
                              src={require('../../assets/incasting.png')}
                              onClick={() => {
                                this.setUnSeen(person._id);
                                this.leaveFromGroup(person._id);
                              }}
                            />
                            <span>{person.first_name} {person.last_name}</span>
                            {person.twr_deleted && <div className="mr-2">
                              <small>Deleted</small>
                            </div>}
                            <FaMinus className="text-danger cursor-pointer ml-auto mr-2" size="16" onClick={() => {
                              this.leaveFromGroup(person._id);
                            }} />
                          </div>
                        </li>
                      ))}
                    </ul>

                    <div className='group-video-wrapper'>
                      {groupCandidates.length > 0 ? (
                        <GroupVideos groupId={session.currentGroup} setMediafiles={this.setMediafiles}/>
                      ) : (<div className='group-videos'>
                        <div className='dummy-player mb-4' />
                      </div>)}
                      <div className='group-action-btns-wrapper'>
                        <input
                          id="new-video-input"
                          type="file"
                          accept="video/*"
                          className='d-none'
                          onChange={ev => {
                            this.handleUploadNewVideo(ev.target.files[0])
                          }}
                        />
                        <button
                          disabled={groupCandidates.length === 0 || videoUploading}
                          className="btn btn-sm btn-danger add-video-btn"
                          onClick={() => {
                            document.querySelector('#new-video-input').click()
                          }}
                        >
                          {videoUploading ? 'Uploading...' : 'Add Video'}
                          <span className="uploading-percent-indicator" />
                        </button>
                        <button
                          disabled={groupCandidates.length === 0}
                          className="btn btn-sm btn-danger leave-group-btn"
                          onClick={this.leaveCurrentGroup}
                        >
                          Finish Group
                        </button>
                        <button 
                          className="btn btn-sm btn-danger leave-group-btn"
                          onClick={() => {
                            this.setBackOfficeFrame(!this.state.backOfficeFrame)
                            this.setInPersonFrame(false)
                            }}>{this.state.backOfficeFrame ? 'Casting Room' : 'Back Office'}</button>
                      </div>
                    </div>
                  </div>
                )}
                <div id="comet-chat">
                  <div id="chat"></div>
                </div>
              </div>
            </div>
            <SizeCards
              studio={studio}
              session={session}
              isClient={false}
              candidates={candidates}
              roles={rs}

              setTwrGroupCandidates={gcs => this.setState({ twrGroupCandidates: gcs })}
              setTwrCandidates={cs => this.setState({ twrCandidates: cs })}

              isTwr={isTwr}
              listTab={listTab}
              setListTab={t => this.setState({ listTab: t })}
            />
          </div>
        </div>

        <Modal
          show={showFilesEditModal}
          onHide={() => this.setState({ showFilesEditModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Files</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SessionFilesForm
              date={dateFormData}
              onChange={this.setDateFormData}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.saveDateData}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showNotesEditModal}
          onHide={() => this.setState({ showNotesEditModal: false })}
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {dateFormData && (
              <div>
                <div className="d-flex">
                  <label>Casting Notes</label>
                  <label className="d-flex align-items-center ml-4">
                    <input
                      disabled={![USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR].includes(user.user_type)}
                      defaultChecked={!!dateFormData.show_description_to_talent}
                      type="checkbox" name="show_description_to_talent" className="mr-2" onChange={ev => {
                        this.setDateFormData({
                          ...dateFormData,
                          show_description_to_talent: ev.target.checked
                        })
                      }}
                    />
                    <span>Show To Talent</span>
                  </label>
                </div>
                <Editor
                  apiKey={TINYMCE_KEY}
                  init={{
                    height: '50vh',
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}
                  value={dateFormData.description}
                  onEditorChange={content => {
                    this.setDateFormData({
                      ...dateFormData,
                      description: content
                    })
                  }}
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.saveDateData}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default HomePage
