import { api_host, token } from './consts'
 
export const addTimeFrames = async (session_id, day_id, timeFrame) => {
  console.log('running addTimeFrames',session_id )
  try{
    console.table(timeFrame);
    const resp = await fetch(`${api_host}/availability/${session_id}/${day_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(timeFrame)
    })
    return await resp.json()

  }
  catch(e){
    console.log('error::>', e)
  }
}

export const getTimeFrames = async ({session_id, day_id, time_zone}) => {
  const resp = await fetch(`${api_host}/appointment/${session_id}/${day_id}?time_zone=${time_zone}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
  return await resp.json()
}

export const getAvailability = async ({session_id, day_id,availability_id, time_zone}) => {
  let url 
  if(availability_id){
    url = `${api_host}/availability/${session_id}/${day_id}/${availability_id}?time_zone=${time_zone}`
  } else {
    url = `${api_host}/availability/${session_id}/${day_id}?time_zone=${time_zone}`
  }
  const resp = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
  return await resp.json()
}

export const getTimeFrame = async ({session_id, day_id, availability_id}) => {
  const resp = await fetch(`${api_host}/availability/${session_id}/${day_id}/${availability_id}/time_frame`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
  return await resp.json()
}
export const updateTimeFrame = async ({session_id, day_id, availability_id, timeFrame}) => {
  const resp = await fetch(`${api_host}/availability/${session_id}/${day_id}/${availability_id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(timeFrame)
  })
  return await resp.json()
}
export const deleteTimeFrame = async ({session_id, day_id, availability_id}) => {
  const resp = await fetch(`${api_host}/availability/${session_id}/${day_id}/${availability_id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
  return await resp.json()
}
export const getUserAppointment = async ({day_id,session_id, talent_id}) => {
  const resp = await fetch(`${api_host}/appointment/${session_id}/${day_id}/is-user-already-booked/${talent_id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
  return await resp.json()
}
export const getUserAllAppointments = async ({talent_id}) => {
  const resp = await fetch(`${api_host}/auth/users/appointments/${talent_id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
  return await resp.json()
}
export const getUserAppointmentByDate = async (session_id, day_id) => {
  const resp = await fetch(`${api_host}/appointment/${session_id}/${day_id}/get-scheduled-appointments/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
  return await resp.json()
}

export const updateAppointmentOneField = async(session_id,day_id, appointment_id, data) => {
  const resp = await fetch(`${api_host}/appointment/${session_id}/${day_id}/update-appointment/${appointment_id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data)
  })
  return await resp.json()
}
 
