import React, { useState } from "react";
import { AsyncTypeahead, MenuItem, Menu } from "react-bootstrap-typeahead";
import { CorrectIcon, EditIcon } from "../../assets/icons/session";

export default ({
  postingPages,
  newPostingPage,
  setNewPostingPage,
}) => {
  const [editPostingPage, setEditPostingPage] = useState(true);
  return (
    <div className="d-flex align-items-center">
      {!editPostingPage &&
        newPostingPage?.name && (
          <div className="h-16-600 folder-name flex-fill">{newPostingPage.name}</div>
        )}
      {editPostingPage && (
        <div className="flex-fill">
          <AsyncTypeahead
            id="limeliteFolder"
            isLoading={false}
            key="folder-select"
            labelKey="name"
            defaultInputValue={newPostingPage?.name || ""}
            onChange={(value) => {
              if (value[0]) {
                setNewPostingPage(value[0]);
              }
            }}
            onSearch={(text) => {
              // Handle search if needed
            }}
            onBlur={(ev) => {
              const inputValue = ev.target.value;
              const matchedOption = postingPages.find(
                (page) => page.name === inputValue
              );

              if (matchedOption) {
                setNewPostingPage(matchedOption);
              } else {
                setNewPostingPage({ name: inputValue, _id: null });
              }
            }}
            minLength={0}
            options={postingPages}
            paginate={false}
            maxResults={50}
            placeholder="Create or select a posting page"
            renderMenu={(results, menuProps) => {
              if (results.length === 0) {
                return null;
              }
              return (
                <Menu {...menuProps}>
                  {results.map((result, index) => {
                    return (
                      <MenuItem
                        key={result._id}
                        option={result}
                        position={index}
                      >
                        {result.name}
                      </MenuItem>
                    );
                  })}
                </Menu>
              );
            }}
          />
        </div>
      )}
      {editPostingPage ? (
        <button
          type="button"
          className="ml-2 cursor-pointer btn"
          onClick={() => {
            if (newPostingPage.name) {
              setEditPostingPage(false);
            } else {
              window.alert("Please select or add a folder!");
            }
          }}
        >
          <CorrectIcon />
        </button>
      ) : (
        <button
          type="button"
          className="ml-2 cursor-pointer btn"
          onClick={() => setEditPostingPage(true)}
        >
          <EditIcon />
        </button>
      )}
    </div>
  );
};
