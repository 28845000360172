import React, { useEffect, useState } from "react";
import Select from "react-select";
import { timeSlotStyles } from "../../views/Session/components/AddTimeFrameModal/react-select-styles";
import "./style.scss";
import { CLOTHES_TYPE, MENS_SIZES_RENDER_ARRAY, WOMEN_DRESS_SIZES_RENDER_ARRAY } from "../../constants";

const SelectSize = ({ sizeObject, setSizeObject, onSelectSize, clothesType, setClothesType, customClass="" }) => {

  const [renderArray, setRenderArray] = useState(null);
  useEffect(() => {
    if(clothesType === CLOTHES_TYPE.MEN){
      setRenderArray(MENS_SIZES_RENDER_ARRAY);
    } else if(clothesType === CLOTHES_TYPE.WOMEN){
      setRenderArray(WOMEN_DRESS_SIZES_RENDER_ARRAY)
    }
  }, [clothesType]);

  const onSelctClothesType = (type) => {
    setClothesType(type)
    setSizeObject({})
  }

  return (
    <div className="select-size-component">
      <div className={`d-flex mt-3 ${customClass ? customClass : 'align-items-center'}`}>
        <span className="h-12-600 mr-2">I wear:</span>
        <div className="d-flex aling-items-center">
          <div className="d-flex align-items-center mr-4">
            <input
              type="radio"
              id="clothes_for_men"
              name="type_of_clothes"
              checked={clothesType === CLOTHES_TYPE.MEN ? true: false}
              onChange={() => onSelctClothesType(CLOTHES_TYPE.MEN)}
            />
            <label htmlFor="clothes_for_men" className="mb-0 ml-2">
              Clothes for Men
            </label>
          </div>
          <div className="d-flex align-items-center">
            <input
              type="radio"
              id="clothes_for_women"
              name="type_of_clothes"
              checked={clothesType === CLOTHES_TYPE.WOMEN ? true: false}
              onChange={() => onSelctClothesType(CLOTHES_TYPE.WOMEN)}
            />
            <label htmlFor="clothes_for_women" className="mb-0 ml-2">
              Clothes for Women
            </label>
          </div>
        </div>
      </div>
      <div className="select-row mt-3 align-items-center justify-content-between">
        {renderArray?.length > 0 && renderArray.map( item => {
          if(sizeObject.hasOwnProperty(item.type)){
            item.options.forEach((option) => {
              if(option.value === sizeObject[item.type].value){
                item.defaultValue = option;
              }
            })
          }else{
            item.defaultValue = null
          }
          return(
            <div className="selectSize-container" key={item.id}>
              <span className="h-12-600">{item.label}</span>
              <Select
                defaultValue={item.defaultValue}
                isSearchable={true}
                onChange={(e) => onSelectSize(e, item.type)}
                options={item.options}
                styles={timeSlotStyles}
              />
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default SelectSize;
