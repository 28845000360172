export const timeSlotStyles = {
    container: (base) => ({
        ...base,
        width: '100%'
    }),
    control: (base, state) => ({
        ...base,
        minHeight: '34px',
        borderRadius: '4px',
        // border: '1px solid rgba(34, 54, 79, 0.20)',
        borderColor: ' #E7E7E7',
        height: '34px !important',
        padding: '0px',
        border: `${state.isFocused ? '1px solid #4e70a3' : '1px solid rgba(34, 54, 79, 0.20)'}`,
        boxShadow: `${state.isFocused ? '0 0 0 0.2rem rgba(37, 53, 77, 0.25)' : 'none'}`,
    }),
    menuList: (base,  state) => ({
        ...base,
        overflowX: 'hidden',
        overflowY: 'auto',
    }),
    option: (base, state) => ({
        ...base,
        color: '#22364F',
        padding: '8px 12px',
        margin: '0px 2px',
        borderRadius: '4px',
        // width: '187px',
        overflow: 'hidden',
        backgroundColor: `${state.isDisabled ? undefined : state.isSelected ? '#E4E7EB' : state.isFocused ? '#EDF1F5' : undefined}`
    }),
    valueContainer: (base, state) => ({
        ...base,
        height: '34px !important',
        padding: '6px 8px'
    }),
    input: (provided) => ({
        ...provided,
        color: '#393C40',
        cursor: 'text',
        padding: '0px',
        margin: '0',
        height: '18px !important'
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#667085',
        margin: '0'
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        display: 'block',
        padding: '0px',
        width: '20px',
        height: '20px'
    }),
    indicatorSeparator: (base, state) => ({
        ...base,
        display: 'none'
    }),
    indicatorsContainer: (base, state) => ({
        ...base,
        padding: '0 8px',
    })
};
