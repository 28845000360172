import React from 'react';
import AuthHeader from '../Auth/AuthHeader';

const BookADemo = ({ history }) => {
  return (
    <div className="d-flex align-items-center flex-column login-page">
      <AuthHeader history={history} />
      
      {/* Link styled as a button with white font color */}
      <a href="https://calendar.app.google/rdT9XZBhrUZiTZmd8" target="_blank" rel="noopener noreferrer" className="btn btn-primary" style={{ color: '#FFF' }}>
        Book a Demo
      </a>
    </div>
  );
};

export default BookADemo;
