import { api_host, token } from './consts'

export const addPostingLink = async (postingLinkObject, id) => {
  const resp = await fetch(`${api_host}/studio/add-posting-link/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(postingLinkObject)
  })
  return await resp.json()
}

export const updatePostingLink = async (postingLinkObject, studioId, linkId) => {
  const resp = await fetch(`${api_host}/studio/update-posting-link/${studioId}/${linkId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(postingLinkObject)
  })
  return await resp.json()
}

export const removePostingLink = async (studio, linkId) => {
  const resp = await fetch(`${api_host}/studio/remove-posting-link/${studio}/${linkId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  return await resp.json()
}