import React from "react";

const YesIcon = (props) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5Z"
      fill="url(#paint0_radial_880_594)"
    />
    <path
      d="M12.0026 11.1666C12.9231 11.1666 13.6693 10.4204 13.6693 9.49992C13.6693 8.57944 12.9231 7.83325 12.0026 7.83325C11.0821 7.83325 10.3359 8.57944 10.3359 9.49992C10.3359 10.4204 11.0821 11.1666 12.0026 11.1666Z"
      fill="url(#paint1_radial_880_594)"
    />
    <path
      d="M4.0026 11.1666C4.92308 11.1666 5.66927 10.4204 5.66927 9.49992C5.66927 8.57944 4.92308 7.83325 4.0026 7.83325C3.08213 7.83325 2.33594 8.57944 2.33594 9.49992C2.33594 10.4204 3.08213 11.1666 4.0026 11.1666Z"
      fill="url(#paint2_radial_880_594)"
    />
    <path
      d="M10.8769 10.5C10.8769 10.5 10.2115 12.1667 7.99619 12.1667C5.94419 12.1667 5.11719 10.5 5.11719 10.5C5.11719 10.5 5.65952 11.2683 8.02385 11.2683C10.3882 11.2683 10.8769 10.5 10.8769 10.5Z"
      fill="url(#paint3_radial_880_594)"
    />
    <path
      d="M7.99754 12.1667C5.72588 12.1667 4.92188 10.2271 4.92188 10.2271C4.92188 10.2271 5.30521 12.8334 7.99754 12.8334C10.6899 12.8334 11.0732 10.2271 11.0732 10.2271C11.0732 10.2271 10.2692 12.1667 7.99754 12.1667Z"
      fill="url(#paint4_radial_880_594)"
    />
    <path
      d="M10.7897 10.096C10.9414 9.96931 11.1484 10.1676 11.0287 10.325C10.4814 11.0446 9.5314 11.8333 7.99707 11.8333C6.46273 11.8333 5.51273 11.0446 4.9654 10.325C4.84573 10.1676 5.05273 9.96931 5.2044 10.096C5.83073 10.6183 6.7784 11.1666 7.99707 11.1666C9.21573 11.1666 10.1634 10.6183 10.7897 10.096Z"
      fill="url(#paint5_radial_880_594)"
    />
    <path
      d="M11.5026 8.16659C11.5947 8.16659 11.6693 8.09197 11.6693 7.99992C11.6693 7.90787 11.5947 7.83325 11.5026 7.83325C11.4106 7.83325 11.3359 7.90787 11.3359 7.99992C11.3359 8.09197 11.4106 8.16659 11.5026 8.16659Z"
      fill="#212121"
    />
    <path
      d="M9.2494 8.14641C9.2494 8.14641 9.82473 7.83175 10.3354 7.83175C10.8461 7.83175 11.4214 8.14641 11.4214 8.14641L11.6317 7.89508C11.6317 7.89508 11.0434 7.16675 10.3354 7.16675C9.6274 7.16675 9.03906 7.89508 9.03906 7.89508L9.2494 8.14641Z"
      fill="#212121"
    />
    <path
      d="M9.16667 8.16659C9.25871 8.16659 9.33333 8.09197 9.33333 7.99992C9.33333 7.90787 9.25871 7.83325 9.16667 7.83325C9.07462 7.83325 9 7.90787 9 7.99992C9 8.09197 9.07462 8.16659 9.16667 8.16659Z"
      fill="#212121"
    />
    <path
      d="M6.83854 8.16683C6.93059 8.16683 7.00521 8.09221 7.00521 8.00016C7.00521 7.90812 6.93059 7.8335 6.83854 7.8335C6.74649 7.8335 6.67188 7.90812 6.67188 8.00016C6.67188 8.09221 6.74649 8.16683 6.83854 8.16683Z"
      fill="#212121"
    />
    <path
      d="M4.58533 8.14641C4.58533 8.14641 5.16067 7.83175 5.67133 7.83175C6.182 7.83175 6.75733 8.14641 6.75733 8.14641L6.96767 7.89508C6.96767 7.89508 6.37933 7.16675 5.67133 7.16675C4.96333 7.16675 4.375 7.89508 4.375 7.89508L4.58533 8.14641Z"
      fill="#212121"
    />
    <path
      d="M4.5026 8.16683C4.59465 8.16683 4.66927 8.09221 4.66927 8.00016C4.66927 7.90812 4.59465 7.8335 4.5026 7.8335C4.41056 7.8335 4.33594 7.90812 4.33594 8.00016C4.33594 8.09221 4.41056 8.16683 4.5026 8.16683Z"
      fill="#212121"
    />
    <defs>
      <radialGradient
        id="paint0_radial_880_594"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8 8.5) scale(6)"
      >
        <stop stopColor="#FFE16E" />
        <stop offset="0.629" stopColor="#FFD226" />
        <stop offset="0.75" stopColor="#FDCD23" />
        <stop offset="0.899" stopColor="#F6BD1B" />
        <stop offset="0.999" stopColor="#F0AF13" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_880_594"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.0026 9.49992) scale(1.66667)"
      >
        <stop stopColor="#FF8400" stopOpacity="0.6" />
        <stop offset="0.999" stopColor="#FF8400" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_880_594"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4.0026 9.49992) scale(1.66667)"
      >
        <stop stopColor="#FF8400" stopOpacity="0.6" />
        <stop offset="0.999" stopColor="#FF8400" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_880_594"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.99476 10.6087) scale(3.48547 1.88832)"
      >
        <stop offset="0.947" stopColor="#FFE16E" />
        <stop offset="1" stopColor="#FFE16E" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_880_594"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.03233 9.66413) scale(3.08749 3.15673)"
      >
        <stop offset="0.819" stopColor="#CC9000" />
        <stop offset="0.988" stopColor="#FBBC23" stopOpacity="0.069" />
        <stop offset="1" stopColor="#FFBF26" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_880_594"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.99707 10.4477) scale(3.21733 1.20779)"
      >
        <stop stopColor="#1C1911" />
        <stop offset="0.999" stopColor="#171714" />
      </radialGradient>
    </defs>
  </svg>
);

export default YesIcon;
