import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { Accordion, Container, Button } from 'react-bootstrap'
import { set as setProfiles } from '../../../store/freelancerProfiles'
import { add as addRequest } from '../../../store/freelancerRequests'
import Pagination from '../../../components/Pagination'
import { createRequest, apiListFreelancers, listRequests } from '../../../services'
import { set as setRequests } from '../../../store/freelancerRequests'
import { FaCheck, FaSpinner } from 'react-icons/fa'
import FreelancerItem from './FreelancerItem'
import ThumbImage from '../../../components/ThumbImage'
import { Request } from './Invited'


const PAGE_SIZE = 10

export default ({
  session,
  position,
  sessionDate,
  name,
  willWorkAs,
  invitedOnly
}) => {

  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [currentKey, setCurrentKey] = useState('')

  const freelancers = useSelector(state => state.freelancerProfiles)
  const freelancerRequests = useSelector(state => state.freelancerRequests)

  const dispatch = useDispatch()

  useEffect(() => {
    setPage(0)
  }, [invitedOnly, willWorkAs])

  const loadUninvited = useCallback(async () => {
    setLoading(true)
    const temp = []
    if (willWorkAs[0]) {
      temp.push('session_runner')
    }
    if (willWorkAs[1]) {
      temp.push('lobby_manager')
    }
    await apiListFreelancers({
      skip: page * PAGE_SIZE,
      name: name,
      will_work_as: temp
    }).then(res => {
      dispatch(setProfiles(res))
    }).catch(err => {
      console.log(err)
    })

    await listRequests({
      skip: invitedOnly ? page * 5 : 0,
      session: session._id,
      name,
      will_work_as: temp,
      take: invitedOnly ? 5 : 100
    }).then(res => {
      dispatch(setRequests(res))
    }).catch(err => {
      console.log(err)
    })
    setLoading(false)
  }, [page, name, willWorkAs, invitedOnly])

  useEffect(() => {
    loadUninvited()
  }, [loadUninvited])

  const onInviteBtnClick = (e, freelancer_id) => {
    if (loading) { return }
    setLoading(true)
    e.stopPropagation()
    const formdata = new FormData()
    formdata.append('session', session._id)
    formdata.append('requested_person', freelancer_id)
    formdata.append('position', position)
    formdata.append('session_date', sessionDate)
    createRequest(formdata).then(res => {
      loadUninvited()
      dispatch(addRequest(res))
      setLoading(false)
    }).catch(err => {
      setLoading(false)
    })
  }

  const items = invitedOnly ? freelancerRequests.requests.map(r => {
    return r.requested_person
  }).filter(f => !!f) : freelancers.profiles
  const totalCount = invitedOnly ? freelancerRequests.count : freelancers.total

  return (
    <div className='freelancer-table-wrapper'>
      <div className='freelancer-table-container'>
        
        <div className={`loading ${loading ? 'show' : ''}`}>
          <div className='d-flex flex-column align-items-center text-danger bg-white px-4 pt-3 pb-1 border-danger border'>
            <FaSpinner size='32px' className='spinning' />
            <span className='h4'>Processing...</span>
          </div>
        </div>

        <div className='container-fluid header-row'>
          <div className='header-logo'>

          </div>
          <div className='ml-3 name-col'>
            &nbsp;
          </div>
          <div className='timezone-col ml-5 mr-auto'>
            Timezone
          </div>
          <div className='booked-on-col mr-4'>
            Booked on
          </div>
          <div className='freelancer-response-col mr-4'>
            Freelancer Response
          </div>
          <div className='book-status-col'>
            Book Status
          </div>
          <div className='action-col ml-3'>
            Action
          </div>
        </div>
        <Accordion
          className="hover-highlight"
          onSelect={k => setCurrentKey(k)}
        >
          {items.map((it, idx) => {
            const request = freelancerRequests.requests.find(req => req.requested_person._id === it._id)
            if (request) {
              return (
                <Request
                  key={`${idx}-${request._id}`}
                  request={request}
                  profile={it}
                  session={session}
                  refreshData={loadUninvited}
                  isExpanded={currentKey === request._id}
                />
              )
            }
            return (
              <div key={`${idx}-${it._id}`} className='mb-2'>
                <Accordion.Toggle
                  as="div"
                  eventKey={it._id}
                  className='cursor-pointer border bg-light rounded'
                >
                  <Container fluid>
                    <div className='d-flex align-items-center'>
                      <ThumbImage
                        src={it.logo}
                        className="header-logo my-2"
                      />
                      <div className='ml-3 name-col'>
                        <h5 className="my-0">
                          {it.first_name} {it.last_name}
                        </h5>
                        <div className="text-danger text-capitalize">
                          {it.freelancer_profile.will_work_as.map(it => it.split('_').join(' ')).join(', ')}
                        </div>
                        <span>
                          ({it.email})
                        </span>
                      </div>
                      <h6 className="mb-0 ml-5 mr-auto timezone-col">
                        {it.freelancer_profile.timezone}
                      </h6>
                      <div className="text-center ml-auto booked-on-col mr-4">
                        {it.booked_dates.length > 0 && (
                          <div className='mt-2'>
                            {`${it.booked_dates.map(d => moment(new Date(d)).format('MM/DD')).join(',')}`}
                          </div>
                        )}
                      </div>
                      <div className="text-center freelancer-response-col mr-4">

                      </div>
                      <div className="text-center book-status-col">

                      </div>
                      <div className='action-col'>
                        {freelancerRequests.requests.findIndex(req => req.requested_person._id === it._id) === -1
                          ? (
                            <Button
                              variant="danger"
                              onClick={(e) => { onInviteBtnClick(e, it._id) }}
                            >
                              Invite
                            </Button>
                          ) : (
                            <span className='text-danger'>
                              <FaCheck
                                color="#fe0923"
                                className='mr-2'
                              />
                              Invited
                            </span>
                          )
                        }
                      </div>
                    </div>
                  </Container>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={it._id}>
                  <div className="px-4 py-2 border border-top-0 bg-white">
                    <FreelancerItem freelancer={it} />
                  </div>
                </Accordion.Collapse>
              </div>
            )
          })}

        </Accordion>
        <div className="mt-3">
          <Pagination
            pageCount={Math.ceil(+totalCount / PAGE_SIZE)}
            page={page}
            setPage={setPage}
          />
        </div>
      </div>

    </div>
  )
}
