import React, { useEffect, useState, useRef } from "react";
import { Modal, Form } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { apiGetAllEmailTemplates, sendClientEmail } from "../../services";
import { TINYMCE_KEY } from "../../constants";
const moment = require('moment-timezone');

export default ({ show, onHide, studio, handleConfirmRemove, appointment }) => {
  const editorRef = useRef(null);
  const [subject, setSubject] = useState(`${studio?.name} talent info`);
  const [initialEmail, setInitialEmail] = useState("");
  const [talentName, setTalentName] = useState(""); 
 
  useEffect(() => {
    if (show && appointment) {
      const loadData = async () => {
        const res = await apiGetAllEmailTemplates();
        const template = res.find((t) => t.name === "DELETE_APPOINTMENT");
        const appointmentDate = `${appointment.appointmentDate} ${appointment.talent.start}`;
        const dateInTalentTimeZone = moment.tz(`${appointmentDate}`, 'YYYY-MM-DD HH:mm', appointment.talent.timeZone);
        const date =  dateInTalentTimeZone.format("YYYY-MM-DD")
        const time = dateInTalentTimeZone.format("HH:mm")
        if (template) {
          const replaceFunc = (str) => {
            return str
              .replace(/PROJECT_NAME/g, `${studio?.name}`)
              .replace(/SESSION_NAME/g, `${studio?.name}`)
              .replace(/APPOINTMENT_DATE/g, `${date}`)
              .replace(/APPOINTMENT_TIME/g, `${time}`)
          };
          setSubject(replaceFunc(template.subject));
          setInitialEmail(replaceFunc(template.html));
        }
      };
      loadData();

      const talentName = `${appointment?.talent?.talent?.first_name} ${appointment?.talent?.talent?.last_name}`
      setTalentName(talentName);
    }
  }, [show, studio, appointment]);


  const onSendBtnClick = (e) => {
    e.preventDefault();
    const data = {
      to: appointment.talent.talent.email,
      content: editorRef.current.targetElm.value,
      subject,
    };
    handleConfirmRemove()
    sendClientEmail(data).then((res) => {
      res.json().then((t) => {
        onHide();
      });
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      className="send-talent-email-modal remove-talent-modal"
      centered
    >
      <Modal.Header closeButton>
        <h4 className="mb-0 mr-3 h-20-600">
          Confirm Removal: {talentName}
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className="h-14-400 lh-150">
          Are you sure you want to remove <strong>{talentName}</strong> from the schedule?
          HeyJoe will send the following email to their provided email address
          after confirmation.
        </div>
        
        <Form.Group className="mt-4">
            <Form.Label className="h-12-600">Subject</Form.Label>
            <Form.Control
              value={subject}
              onChange={(e)=>{setSubject(e.target.value)}}
              className="h-14-400"
            />
          </Form.Group>
        <label className="h-12-600">Email</label>
        <Editor
          key={initialEmail}
          apiKey={TINYMCE_KEY}
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={initialEmail}
          init={{
            height: "300px",
            menubar: false,
            relative_urls: false,
            remove_script_host: false,
            convert_urls: true,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
          className="h-14-400"
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          variant="danger"
          className="h-14-600 cancel-btn"
          onClick={onHide}
        >
          Cancel
        </button>
        <button
          type="submit"
          variant="danger"
          className="h-14-700 remove-btn"
          onClick={onSendBtnClick}

        >
          Remove Participant
        </button>
      </Modal.Footer>
    </Modal>
  );
};
