import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import {
  RightCaret,
  WhiteCalendarPlusIcon,
} from "../../../assets/icons/session";

import "./SchedulePage.scss";
import AddTimeFrameModal from "../components/AddTimeFrameModal/AddTimeFrameModal";
import TimeFrames from "../components/TimeFrames/TimeFrames";
import useSchedule from "./hooks/use-schedule";
import BaseEmailModal from "../../Studio/BaseEmailModal";
import { USER_TYPES } from "../../../constants";
import { getUser } from "../../../services";

export default ({ match }) => {
  const history = useHistory();
  const {
    studio,
    context,
    currentDate,
    studio_uri,
    session_id,
    day_id,
    dayIndex,
    showEmptyState,
    availableTimeFrames,
    showModal,
    hiringRoles,
    rolesArray,
    timeFramesStartEndDates,
    defaultValues,
    addOrEditTimeFrame,
    appointmentScheduledTimeFrame,
    emailData,
    showEmailModal,
    scheduleAppointmentRolesDetail,
    isEnableShare,
    newlyCreatedTimeFrameId,
    setShowModal,
    setRolesArray,
    onAddOrEditTimeFrame,
    refreshTimeFrames,
    onEditTimeFrame,
    onDragAndDropAppointment,
    onEmailModalHide,
    onEmailSend,
    onDeleteTimeFrame,
    onShareDayAppointment,
    onShareTimeFrameAppointment,
  } = useSchedule({ match });

  const [totalRoles, setTotalRoles] = useState([]);
  const user = getUser();
  
  useEffect(() => {
    if(studio?.project_roles){
      setTotalRoles(studio?.project_roles);
    }
  }, [studio]);

  const isAdmin = [
    USER_TYPES.SUPER_ADMIN,
    USER_TYPES.CASTING_DIRECTOR,
    USER_TYPES.SESSION_MANAGER,
  ].includes(user.user_type);

  return (
    <div className="schedule-container">
      <div className="flex align-center gap-2">
        {isAdmin && (
          <>
            {context.title && currentDate && currentDate.start_time ? (
              <>
                <span
                  onClick={() => {
                    history.push("/");
                  }}
                  className="bread-crumb"
                >
                  Projects
                </span>
                <span>
                  {" "}
                  <RightCaret />
                </span>
                <span
                  onClick={() => {
                    history.push(`/session/${studio_uri}/edit/${session_id}`);
                  }}
                  className="bread-crumb"
                >
                  {context.title}
                </span>
                <span>
                  {" "}
                  <RightCaret />
                </span>
                <span>
                  Day {dayIndex + 1} -{" "}
                  {currentDate &&
                    moment(currentDate.start_time).format("ddd MMM DD, yyyy")}
                </span>
              </>
            ) : (
              <div>Loading...</div>
            )}
          </>
        )}
      </div>
      <div className="schedule-content">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="mb-0 mt-4 h-32-400">{context.title} - Day {dayIndex + 1}</h1>
          {isEnableShare && <button className="share-button h-14-600" onClick={onShareDayAppointment}>Share</button>}
        </div>
        <>
          {showEmptyState && isAdmin ? (
            <div className="empty-state-container">
              <div className="empty-state-content">
                <h2 className="empty-state-title">Add a Time Frame</h2>
                <div className="empty-state-description">
                  Using Time Frames you can add roles and specify the number of
                  participants that you want for each role in your schedule.
                </div>
                <div className="mt-4">
                  <button
                    className="btn btn-primary br-8 h-14-700 flex align-center gap-2"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    <WhiteCalendarPlusIcon /> + Add Time Frame
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <TimeFrames
              availableTimeFrames={availableTimeFrames}
              newlyCreatedTimeFrameId={newlyCreatedTimeFrameId}
              setShowModal={setShowModal}
              onEditTimeFrame={onEditTimeFrame}
              studio={studio}
              session_id={session_id}
              day_id={day_id}
              refreshTimeFrames={refreshTimeFrames}
              onDragAndDropAppointment={onDragAndDropAppointment}
              onEmailModalHide={onEmailModalHide}
              onDeleteTimeFrame={onDeleteTimeFrame}
              onShareTimeFrameAppointment={onShareTimeFrameAppointment}
              isAdmin={isAdmin}
            />
          )}
        </>
      </div>
      <AddTimeFrameModal
        defaultValues={defaultValues}
        showModal={showModal}
        setShowModal={setShowModal}
        rolesArray={rolesArray}
        setRolesArray={setRolesArray}
        onAddOrEditTimeFrame={onAddOrEditTimeFrame}
        hiringRoles={totalRoles}
        currentDate={currentDate}
        addOrEditTimeFrame={addOrEditTimeFrame}
        timeFramesStartEndDates={timeFramesStartEndDates}
        appointmentScheduledTimeFrame={appointmentScheduledTimeFrame}
        scheduleAppointmentRolesDetail={scheduleAppointmentRolesDetail}
      />
      <BaseEmailModal
        show={showEmailModal}
        onHide={onEmailModalHide}
        studio={studio}
        emailData={emailData}
        onEmailSend={onEmailSend}
      />
    </div>
  );
};
