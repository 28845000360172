import React, { useEffect, useRef, useState } from "react";
import { Editor } from '@tinymce/tinymce-react'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Modal, Accordion, Card, Container, Row, Col, Form, Button } from 'react-bootstrap'
import DateTimePicker from "react-datetime-picker";
import { FaEye, FaListAlt, FaTimes, FaTrashAlt, FaUpload } from "react-icons/fa";
import _ from 'lodash'
import {
  static_root, uploadFile, searchUsers, getOneSession, getUser,
  updateSession, createSession
} from "../services";
import { TINYMCE_KEY, SESSION_LOCATION_TYPE, SESSION_TIME_TYPE, SESSION_BOOK_TYPE, USER_TYPES } from "../constants";
import { update as updateStudioInStore } from '../store/studios'
import { toggleLoadingState } from '../utils'

let fnTimeoutHandler = null;
let openSendEmail = false;

const FileField = ({ label, value, disabled, setValue, toggleLoadingState }) => {
  const inputRef = useRef(null)
  const [uploading, setUploading] = useState(false)
  return (
    <div>
      <div className="d-flex align-items-center file-upload-field">
        <div className="file-upload-input-wrapper mr-3">
          {!disabled && (
            <Button disabled={uploading} size="sm" variant="text" onClick={() => {
              inputRef.current.click()
            }}>
              <FaUpload />
            </Button>
          )}
          <input
            ref={inputRef}
            type="file"
            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
            className="form-control mb-3 d-none"
            onChange={(ev) => {
              toggleLoadingState(true)
              setUploading(true)
              uploadFile(ev.target.files[0]).then(data => {
                setValue((value || []).concat(data.key || data.Key))
                setUploading(false)
                toggleLoadingState(false)
              })
            }}
          />
        </div>
        <label className="mb-0 mr-2">
          {label}
        </label>
      </div>
      {(value || []).map((v, vIdx) => {
        return (
          <div key={v + vIdx} className='d-flex align-items-center ml-4'>
            <span className="text-secondary"><b>{vIdx + 1}:</b> {v.slice(7)}</span>
            <small className="ml-2 cursor-pointer" onClick={() => {
              window.open(`${static_root}${v}`)
            }}><FaEye /></small>
            <small className="ml-2 cursor-pointer" onClick={() => {
              setValue(value.filter((v, vi) => vi !== vIdx))
            }} ><FaTrashAlt /></small>
          </div>
        )
      })}
    </div>
  )
}

const SessionFilesForm = ({ date, onChange }) => {
  const [oneDate, setOneDate] = useState(date)
  const setDateField = (field, value) => {
    setOneDate({
      ...oneDate,
      [field]: value
    })
  }
  useEffect(() => {
    onChange(oneDate)
  }, [onChange, oneDate])
  const user = getUser()
  const isAdmin = [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR].includes(user.user_type)
  return (
    <div className="">
      <div className="w-50">
        <FileField
          label='Sizecard File'
          value={oneDate.size_card_pdf}
          setValue={v => {
            setDateField("size_card_pdf", v);
          }}
          toggleLoadingState={toggleLoadingState}
        />
        <FileField
          label='Schedule File'
          value={oneDate.schedule_pdf}
          setValue={v => {
            setDateField("schedule_pdf", v);
          }}
          toggleLoadingState={toggleLoadingState}
        />
        <FileField
          label='Storyboard File'
          value={oneDate.storyboard_pdf}
          setValue={v => {
            setDateField("storyboard_pdf", v);
          }}
          toggleLoadingState={toggleLoadingState}
        />
      </div>
      <div className="w-50">
        <FileField
          label='Script File'
          value={oneDate.script_pdf}
          setValue={v => {
            setDateField("script_pdf", v);
          }}
          toggleLoadingState={toggleLoadingState}
        />
        <label className="d-flex align-items-center mb-1 ml-5">
          <input disabled={!isAdmin} defaultChecked={!!oneDate.show_to_talent} type="checkbox" name="show_to_talent" className="mr-2" onChange={ev => {
            setDateField('show_to_talent', ev.target.checked)
          }} />
          <span>Show To Talent</span>
        </label>
        <FileField
          label='Treatment File'
          value={oneDate.treatment_pdf}
          setValue={v => {
            setDateField("treatment_pdf", v);
          }}
          toggleLoadingState={toggleLoadingState}
        />
      </div>
    </div>
  )
}

export default SessionFilesForm
