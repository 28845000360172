import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Form, Button } from "react-bootstrap";
import { Link } from 'react-router-dom'
import _ from "lodash";
import {
  getOneSession,
  getUser,
  updateSession,
  createSession,
  getStudioByUri,
  allowTalentCreateRole,
  addStudioRole
} from "../../services";
import {
  SESSION_BOOK_TYPE,
  USER_TYPES,
} from "../../constants";
import { update as updateStudioInStore } from "../../store/studios";
import { useHistory } from "react-router-dom";
import { TitleContext } from "../../Context";
import { CorrectIcon, EditIcon, InviteIcon, MailIcon, RightCaret, SortIcon, WrongIcon } from "../../assets/icons/session";
import DayItem from "./components/dayItem/DayItem";
import { SessionActionBts } from "../Studio/Session";
import SendTalentEmailModal from "../Studio/SendTalentEmailModal";
import "./SessionEditPage.scss";
import AddRoles from "./components/addRoles/AddRoles";
import GroupSorter from "../PostingPage/GroupSorter";

export default ({ match }) => {
  const [session, setSession] = useState(null);
  const [studio, setStudio] = useState(null);
  const [dateIndex, setDateIndex] = useState(1);
  const [dates, setDates] = useState([]);
  const user = getUser();
  const history = useHistory();
  const context = useContext(TitleContext);
  const [activeTab, setActiveTab] = useState('dates');
  const [sessionId, setSessionId] = useState(null);
  const [rolesArray, setRolesArray] = useState([]);
  const [sessionName, setSessionName] = useState("");
  const [editSessionName, setEditSessionName] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loadDataAgain, setLoadDataAgain] = useState(false);

  const [emailCheckinLink, setEmailCheckinLink] = useState("");
  const host = window.location.origin;

  const isAdmin = [
    USER_TYPES.SUPER_ADMIN,
    USER_TYPES.CASTING_DIRECTOR,
    USER_TYPES.SESSION_MANAGER,
  ].includes(user.user_type);

  const dispatch = useDispatch();

  useEffect(() => {
    const loadData = async () => {
      try {
        const studio_uri = match.params.uri;
        const session_id = match.params.session_id;
        setSessionId(session_id);
        const studio = await getStudioByUri(studio_uri);
        setRolesArray(studio?.project_roles);
        if (session_id === "new") {
          setSessionName("1st call");
          setDates([
            {
              managers: [],
              lobbyManager: [],
              start_time: moment().startOf('day').add(10, 'hours').toDate(),
              start_time_type: "1st call",
              book_status: SESSION_BOOK_TYPE[1],
              time_frame_count:0,
            },
          ]);
        } else {
          const session = await getOneSession(session_id);
          setSession(session);
          setSessionName(session?.name);
          setDates(
            session.dates.length === 0
              ? [
                  {
                    managers: [],
                    lobbyManager: [],
                    start_time: moment().startOf('day').add(10, 'hours').toDate(),
                    start_time_type: "1st call",
                    book_status: SESSION_BOOK_TYPE[1],
                    time_frame_count: 0
                  },
                ]
              : session.dates
          );
        }
        setStudio(studio);
      } catch (e) {
        history.push("/");
      }
    };
    loadData();
  }, [loadDataAgain]);

  useEffect(() => {
    if(studio && studio?.allow_talent_create_role){
      setIsChecked(studio.allow_talent_create_role)
    }
  },[studio])

  useEffect(() => {
    if(sessionName === "1st call" || sessionName === "Callback"){
      setSessionName(dates[0]?.start_time_type)
    }
  },[dates])

  const isDateAlreadyExist = useCallback((idx,date) => {
    return dates.filter((date,index)=> {
      return index != idx
    } ).findIndex((it) => {
      return moment(it.start_time).format("YYYY-MM-DD") ===
        moment(date).format("YYYY-MM-DD");
    }) !== -1;
  },[dates])
  const setDateField = (idx, field, value) => {

    const temp = _.cloneDeep(dates);
    if(isDateAlreadyExist(idx,value)){
      temp[idx]['error'] = {date:"date already scheduled"}
      setDates(temp);
      return 
    }
    temp[idx][field] = value;
    delete temp[idx]['error']
    const onHold =
      []
        .concat(temp[idx]["managers"])
        .concat([].concat(temp[idx]["lobbyManager"])).length === 0;
    temp[idx]["book_status"] = onHold
      ? SESSION_BOOK_TYPE[1]
      : SESSION_BOOK_TYPE[0];

    setDates(temp);
  };

  const removeDate = (idx) => {
    const temp = [...dates];
    temp.splice(idx, 1);
    setDates(temp);
    updateData(temp);
  };

  const onAddDateClick = () => {
    const lastDate = dates[dates.length - 1];
    setDates([
      ...dates,
      {
        managers: [],
        lobbyManager: [],
        start_time: 
        moment(lastDate.start_time).startOf('day').add(1,'days').add(10, 'hours').diff(moment()) > 0 ? 
        moment(lastDate.start_time).startOf('day').add(1,'days').add(10, 'hours')
        : moment().startOf('day').add(10, 'hours').diff(moment()) > 0 ?
         moment().startOf('day').add(10, 'hours').toDate() : 
         moment().startOf('day').add(1,'days').add(10, 'hours').toDate(),
        // start_time: moment(lastDate.start_time).startOf('day').add(1,'days').add(10, 'hours').toDate(),
        start_time_type: "1st call",
        book_status: SESSION_BOOK_TYPE[1],
      },
    ]);
    setDateIndex(dates.length + 1);
  };
  const updateData = async (passDates) => {
    
    let sessionId = null;
    const buff = new FormData(document.querySelector("#session-form"));
    const formData = new FormData();
    formData.append("name", sessionName);
    formData.append("twr", buff.get("twr"));
    
    let chooseDates;
    if(passDates){
      chooseDates = passDates?.filter((it) => it.start_time !=="").filter((it) => !it.error)
    } else {
      chooseDates = dates?.filter((it) => it.start_time !=="").filter((it) => !it.error)
   
    }
    formData.append("dates", JSON.stringify(chooseDates));

    if (session) {
      await updateSession(session._id, formData).then((res) => {
        if (studio.sessions) {
          const idx = studio.sessions.findIndex((it) => it._id === res._id);
          const sessions = [...studio.sessions];
          sessions[idx] = res;
          const temp = { ...studio, sessions };
          dispatch(updateStudioInStore(temp));
        }
        sessionId = res._id;
      });
    } else {
      formData.append("studio", studio._id);
      await createSession(formData).then((res) => {
        if (studio.sessions) {
          const sessions = [...studio.sessions, res];
          const temp = { ...studio, sessions };
          dispatch(updateStudioInStore(temp));
        }
        sessionId = res._id;
        setSession(res);
      });
    }

    return sessionId;
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    // await updateData();
    // context.setTitle("");
    // history.push("/");
  };
  const updateOneDate = () =>{
    updateData();
    setLoadDataAgain(prev => !prev)
  }

  useEffect(() => {
    const title = studio ? studio.name : "";
    document.title = title;
    context.setTitle(title);
  }, [studio]);

  const goToFreelancerInvite = async () => {
    const sessionId = await updateData();
    history.push(`/session/${studio.uri}/${sessionId}/find-freelancer`);
  };
  const validateDate = useCallback((idx, message) => {
    const temp = _.cloneDeep(dates);
    temp[idx]['error'] = {date:message}
    setDates(temp);
  },[dates])


  const onClickAllow = async () => {
    
    await allowTalentCreateRole(session.studio, !isChecked).then((res) => {
      console.log("allowTalentCreateRole:>", res);
    });
  };

  if (!studio) {
    return (
      <div className="session-edit-page-container page-content">
        Loading...
      </div>
    );
  }

  const updateRoleOrder = async(items) => {
    await addStudioRole(session.studio, items).then((res) => {
      setRolesArray(res.roles)
    });
  }

  return (
    <div  className="session-edit-page-wrapper">

      <div className="session-edit-page-container">
        <div className="flex align-center gap-2">
          <span
            onClick={() => {
              history.push("/");
            }}
            className="bread-crumb"
          >
            Projects
          </span>
          <span>
            {" "}
            <RightCaret /> {context.title}
          </span>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-5 mt-4 flex-wrap">
          <div className="d-flex align-items-center mb-0 ">
            {editSessionName ? <>
              <input
                value={sessionName}
                onChange={(ev) => setSessionName(ev.target.value)}
                type="text"
                name="session_name"
                id="session_name"
                className="h-32-400 input"
                />
              <button
                className="edit-button"
                onClick={ () => {
                  updateData();
                  setEditSessionName(false)}}>
                  <CorrectIcon /></button>
              <button
                className="edit-button" 
                onClick={ () => {
                  setSessionName(session.name)
                  setEditSessionName(false)}}>
                  <WrongIcon /></button>
              </> : 
            <>
              <h1 className="h-32-400 mr-2 my-0">{sessionName}</h1>
              <button className="edit-button" onClick={ () => setEditSessionName(true)}>
                <EditIcon />
              </button>
            </>}
          </div>
          <div className="right-button-panel">
            <div className="session-btn-container">
              <SessionActionBts studio={studio} session={session} styling="flex-fill"/>
              <button
                title="Send Talent Email"
                  className='action-button h-12-600'
                  onClick={() => {
                        setEmailCheckinLink(`${host}/onboard/${studio.uri}/${session._id}`)
                  }}><MailIcon /> <span>Send Talent Email</span></button>

              {[USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR].includes(
                user.user_type
              ) && (
                <button
                  className='action-button h-12-600'
                  title="View Project Invites"
                  onClick={() => {
                    goToFreelancerInvite();
                  }}
                >
                  <InviteIcon /> <span>Project Invites</span>
                </button>
              )}
            </div>
            <div className="button-panel-right">
              <Link
                  title="Add Session"
                  className='action-button h-12-600'
                  to={`/session/${studio.uri}/edit/new`}
                  target="_blank">+ Add Session</Link>
              <Button
                type="button"
                className="primary-solid-button h-12-600 br-8 text-white"
                onClick={() => {
                  setActiveTab('roles')
                  setRolesArray([...rolesArray, '']);
                }}
                disabled={!!studio?.limelite_project_id}
              >
                + Add Role
              </Button>
              <Button
                type="button"
                className="primary-solid-button h-12-600 br-8 text-white"
                onClick={() => {
                  setActiveTab('dates')
                  onAddDateClick()
                }}
              >
                + Add Date
              </Button>
            </div>
          </div>
        </div>

        <div className="tab-header">
          <button className="tab-item  h-14-400" onClick={ () => setActiveTab('dates')}>
            <span>Dates</span>
            <div className={`${activeTab === 'dates' ? 'active' : null} horizontal-line`}/>
          </button>
          <button className="tab-item h-14-400" onClick={ () => setActiveTab('roles')}>
            <span>Roles</span>
            <div className={`${activeTab === 'roles' ? 'active' : null} horizontal-line`}/>
          </button>
        </div>
        {activeTab === 'dates' &&  
          <Form
          id="session-form"
          className="d-flex flex-column h-100"
          onSubmit={onSubmit}
          >
            <div className="p-3 day-item-container">
              {dates.map((oneDate, idx) => {
                return (
                  <DayItem
                    key={idx}
                    idx={idx}
                    oneDate={oneDate}
                    isAdmin={isAdmin}
                    setDateField={setDateField}
                    removeDate={removeDate}
                    dateIndex={dateIndex}
                    setDateIndex={setDateIndex}
                    goToFreelancerInvite={goToFreelancerInvite}
                    studio={studio}
                    session={session}
                    updateData={updateOneDate}
                    validateDate={validateDate}
                  />
                );
              })}
            </div>
          </Form> 
        }    
        <div className="role-item-container">
          {activeTab === 'roles' &&

            (
              <>
                <div className={!!studio?.limelite_project_id ? "d-none" : "checkbox-container d-flex align-items-center mb-2 justify-content-between"}>
                  <div className="d-flex align-items-center">
                    <input 
                      id="create-role"
                      type="checkbox"
                      checked={isChecked}
                      onChange={() => {
                        setIsChecked((prev) => !prev)
                        onClickAllow()}}
                      className={isChecked ? "checked" : ""}
                      disabled={!!studio?.limelite_project_id}
                    />
                    <label className="h-14-400 m-0 ls-2" htmlFor="create-role">Allow talent created roles</label>
                  </div>
                  <GroupSorter
                    groups={rolesArray}
                    update={updateRoleOrder}
                    title="Sort Roles"
                    btnContent={ [<SortIcon key="sort-icon-s"/>, "Sort Roles"]}
                    btnClass='action-button h-10-600 ml-2'
                  />
                </div>
                {rolesArray.map((role, index) => {
                  return (
                    <React.Fragment key={role}>
                      <AddRoles
                        rolesArray={rolesArray}
                        setRolesArray={setRolesArray}
                        index={index}
                        session={session}
                        studio={studio} />
                    </React.Fragment>
                    )
                  })}
              </>
            )
          }

        </div>
        
        <SendTalentEmailModal
          show={!!emailCheckinLink}
          onHide={() => {
            setEmailCheckinLink(null);
          }}
          emailCheckinLink={emailCheckinLink}
          studio={studio}
        />
      </div>
    </div>
  );
};
