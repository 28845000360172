import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getStudioByUri } from "../../services";
import "./style.scss";
import SendPostingLinkEmailModal from "../Studio/SendPostingLinkEmailModal";

const PostingLinkPage = (props) => {
  const studio_uri = props.match.params.uri;
  const posting_link_id = props.match.params.postinglink_id;
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [studio, setStudio] = useState({});
  const [postingLink, setPostingLink] = useState({});

  const host = window.location.origin
  const currentLink =`${host}/posting-link-page/${studio_uri}/${postingLink._id}`

  useEffect(() => {
    const fetchData = async () => {
      const _studio = await getStudioByUri(studio_uri);
      setStudio(_studio);
      const _postingLink = _studio.posting_links.find(
        (link) => link._id === posting_link_id
      );
      setPostingLink(_postingLink);
    };
    fetchData();
  }, [studio_uri, posting_link_id]);

  return (
    <div className="posting-link-page">
      <div className="d-flex align-items-center justify-content-between">
        <h1 className="h-32-400 mr-2 my-2">{postingLink.name}</h1>
        <div className="d-flex align-items-center">
          <span className="h-14-400 text-secondary underline">{currentLink}</span>
          <button className="action-button mx-2 h-12-600" onClick={() =>  navigator.clipboard.writeText(currentLink)}>Copy Link</button>
          <button className="action-button h-12-600" onClick={() => setShowEmailModal(true)}>Share Link</button>
        </div>
      </div>
      <div className="posting-page-item-container mt-4">
        {postingLink.posting_pages &&
          postingLink.posting_pages.map((page) => {
            return (
              <div key={page._id} className="posting-page-item">
                <h4 className="h-14-600 ls-2">{page.name}</h4>
                <Link
                  to={`/posting-page-via-link/${studio_uri}/${postingLink._id}/${page._id}`}
                  className="action-button h-12-600"
                  target="_blank"
                >
                  View Page
                </Link>
              </div>
            );
          })}
      </div>
       
      <SendPostingLinkEmailModal
        show={showEmailModal}
        onHide={() => {
          setShowEmailModal(null)
        }}
        studio={studio}
        emailSessionParams={postingLink}
        emailSessionLink={`${host}/posting-link-page/${studio_uri}/${postingLink._id}`}
      />
    </div>
  );
};

export default PostingLinkPage;
