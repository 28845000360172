import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import DatePicker from 'react-multi-date-picker'
import AvatarChoose from '../../../components/avatar-choose'
import { Container, Col, Form, Button } from 'react-bootstrap'
import _ from 'lodash'
import { updateUserFields } from '../../../services'
import { FREELANCER_TIMEZONE, FREELANCER_WORK_AS } from '../../../constants'

const ProfileForm = ({ user }) => {
  const [logo, setLogo] = useState(null)
  const [willWorkAs, setWillWorkAs] = useState(_.get(user, 'freelancer_profile.will_work_as', []))
  const [availableDates, setAvailableDates] = useState(_.get(user, 'freelancer_profile.available_dates', []))
  const [nonAvailableDates, setNonAvailableDates] = useState(_.get(user, 'freelancer_profile.non_available_dates', []))
  const [receiveEmail, setReceiveEmail] = useState(true)
  
  const willWorkAsOption = willWorkAs.length > 1 ? 'both': willWorkAs[0]
  const history = useHistory()

  const onSubmit = async (e) => {
    e.preventDefault()
    const temp = new FormData(e.target)

    const freelancer_profile = {
      will_work_as: willWorkAs,
      experience: temp.get('experience'),
      available_dates: availableDates,
      non_available_dates: nonAvailableDates,
      availability_notes: temp.get('availability_notes'),
      timezone: temp.get('timezone'),
      phone: temp.get('phone'),
      receive_email: receiveEmail
    }
    const formData = new FormData()
    formData.append('first_name', temp.get('first_name'))
    formData.append('last_name', temp.get('last_name'))
    if (logo) { formData.append('logo', logo) }
    formData.append('freelancer_profile', JSON.stringify(freelancer_profile))
    const res = await updateUserFields(user._id, formData)
    history.push('?')
  }
  return (
    <div className='profile-edit'>
      <AvatarChoose
        logo={logo}
        setLogoFile={setLogo}
      />
      <Form onSubmit={onSubmit}>
        <Container className="mt-4">
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className='h6'>First Name</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                defaultValue={_.get(user, 'first_name','')}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className='h6'>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                defaultValue={_.get(user, 'last_name','')}
              />
            </Form.Group>
          </Form.Row>
          <Form.Group>
            <Form.Label className='d-block h6'>Will work as</Form.Label>
            <Form.Control
              as="select"
              value={willWorkAsOption}
              onChange={(ev) => {
                if (ev.target.value === 'both') {
                  setWillWorkAs(Object.values(FREELANCER_WORK_AS))
                } else {
                  setWillWorkAs([ev.target.value])
                }
              }}
            >
              <option disabled={willWorkAsOption} value={undefined}>-</option>
              {Object.values(FREELANCER_WORK_AS).map(workAs => {
                return (<option key={workAs} value={workAs}>{workAs}</option>)
              })}
              <option key='both' value='both'>Both</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label className='d-block h6'>Experience</Form.Label>
            <Form.Control
              as="textarea"
              name='experience'
              defaultValue={_.get(user, 'freelancer_profile.experience', '')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className='d-block h6'>Available dates</Form.Label>
            <DatePicker name="available_dates" containerClassName='w-100' inputClass='form-control' value={availableDates} onChange={setAvailableDates} />
          </Form.Group>
          <Form.Group>
            <Form.Label className='d-block h6'>Non available dates</Form.Label>
            <DatePicker containerClassName='w-100' inputClass='form-control' value={nonAvailableDates} onChange={setNonAvailableDates} />
          </Form.Group>
          <Form.Group>
            <Form.Label className='d-block h6'>Availability Notes</Form.Label>
            <Form.Control
              as="textarea"
              name='availability_notes'
              defaultValue={_.get(user, 'freelancer_profile.availability_notes', '')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className='d-block h6'>Timezone</Form.Label>
            <Form.Control
              as="select"
              name="timezone"
              defaultValue={_.get(user, 'freelancer_profile.timezone', '')}
            >
              {FREELANCER_TIMEZONE.map(tz => (<option key={tz} value={tz}>{tz}</option>))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label className='h6'>Phone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              defaultValue={_.get(user, 'freelancer_profile.phone', '')}
            />
          </Form.Group>
          <Form.Group className="d-none">
            <Form.Check
              label="Receive Email"
              checked={receiveEmail}
              onChange={ev => { setReceiveEmail(!!ev.target.checked) }}
            />
          </Form.Group>
          <Form.Row className="justify-content-end">
            <Button
              variant="primary"
              className="mr-2 btn-w-md"
              as={Link}
              to="?"
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              className='btn-w-md'
              type="submit"
            >
              Save
            </Button>
          </Form.Row>
        </Container>
      </Form>
    </div>
  )
}

export default ProfileForm
