import React, {
  useState,
  useEffect,
  useContext,
} from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Dropdown, Navbar, Image } from 'react-bootstrap'
import { FaRegCalendarAlt, FaFilePdf, FaExternalLinkAlt, FaUpload, FaStickyNote } from 'react-icons/fa';
import { getUser, static_root } from '../services'
import { FILE_FIELDS, USER_TYPES } from '../constants'
import { titleCase } from '../utils'
import { TitleContext } from '../Context'
import defaultLogo from '../assets/heyjoe-white.png'
import UserDropDown from './UserDropDown';
import './Header.scss'

const excludePaths = [
  '/message',
]

const Header = (props) => {
  const [user, setUser] = useState(null)
  const [session, setSession] = useState(null)
  const { title } = useContext(TitleContext)

  useEffect(() => {
    const handler = (ev) => {
      console.log('ev: ', ev);
      setSession(ev.detail)
    }
    document.body.addEventListener('current-session', handler)
    return () => {
      document.body.removeEventListener('current-session', handler)
    }
  }, [])

  useEffect(() => {
    const u = getUser()
    setUser(u)
  }, [])

  if (excludePaths.find(path => props.location.pathname.startsWith(path))) {
    return null
  }

  if (!user) {
    return null
  }

  const logoLink = '/'
  const logoTarget = '_blank'

  const companyLogo = user.user_type === USER_TYPES.CASTING_DIRECTOR
    ? static_root + user.logo
    : defaultLogo

  const sessionDateData = session ? session.dates.find(date => {
    return new Date().toDateString() === new Date(date.start_time).toDateString()
  }) : null

  let queryParams = {}
  try {
    queryParams = JSON.parse('{"' + decodeURI(window.location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
  } catch (err) {
    queryParams = {}
  }
  const testMode = !!queryParams.test

  return (
    <Navbar className="py-4 global-header no-print" bg="danger">
      <Navbar.Brand
        as={Link}
        to={logoLink}
        target={logoTarget}
        id="header-logo"
      >
        <div className='header-logo py-1'>
          <Image
            src={companyLogo}
          />
        </div>
      </Navbar.Brand>
      <div id="header-title">
        <h3>{title}</h3>
        {sessionDateData && (
          <Dropdown className="ml-2" alignRight>
            <Dropdown.Toggle variant={testMode ? "success" : "danger"} id="dropdown-basic">
              <FaFilePdf size="22" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {FILE_FIELDS.filter(field => sessionDateData[field] && sessionDateData[field].length > 0).map(field => {
                return (
                  <Dropdown.Item key={field} className="text-secondary">
                    <div className='d-flex'>
                      <span>{titleCase(field.replace('_pdf', ''))}</span>
                      {sessionDateData[field].map((v, vIdx) => {
                        return (
                          <span key={v + vIdx} className="mx-2 cursor-pointer" onClick={() => {
                            window.open(`${static_root}${v}`)
                          }}>{vIdx + 1}:&nbsp;&nbsp;<FaExternalLinkAlt /></span>
                        )
                      })}
                    </div>
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      <div className='d-flex align-items-center ml-auto'>
        {[USER_TYPES.SESSION_MANAGER, USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR].includes(user.user_type) && sessionDateData && (
          <button
            className="h5 mr-0 btn btn-danger mt-2 header-support-btn mr-2"
            onClick={() => {
              document.body.dispatchEvent(new CustomEvent('session-date-edit-files'))
            }}
          >
            <FaUpload size={25} />
          </button>
        )}
        {[USER_TYPES.SESSION_MANAGER, USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR].includes(user.user_type) && sessionDateData && (
          <button
            className="h5 mr-0 btn btn-danger mt-2 header-support-btn mr-2"
            onClick={() => {
              document.body.dispatchEvent(new CustomEvent('session-date-edit-note'))
            }}
          >
            <FaStickyNote size={30} />
          </button>
        )}
        {[USER_TYPES.SESSION_MANAGER, USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR].includes(user.user_type) && (
          <button
            className="h5 mr-0 btn btn-danger mt-2 header-support-btn mr-2"
            onClick={() => {
              window.open('/calendar')
            }}
          >
            <FaRegCalendarAlt size={30} />
          </button>
        )}

        <UserDropDown />

      </div>
    </Navbar>
  )
}

export default withRouter(Header)
