import React from 'react'
import {
  Container,
  Row,
  Col,
  Button  
} from 'react-bootstrap'
import { deleteUser, getUser } from '../../services'

export default () => {
  const user = getUser()
  const handleDelete = async () => {
    await deleteUser(user.id)
    window.localStorage.removeItem('token')
    window.location.reload(true)
  }

  return (
    <Container>
      <Row>
        <Col>
          <h3 className="text-center mt-5 mb-2">
            Are you sure?
          </h3>
          <p className="text-center mb-5">
            To delete your account, please click the button below:
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <Button
            type="button"
            variant="danger"
            onClick={handleDelete}
          >
            Delete Account
          </Button>  
        </Col>
      </Row>
    </Container>
  )
}
