import React from "react";
import useProfile from "../hooks/use-profile";
import moment from "moment";

const MySchedule = () => {
  const { userAppointments, timezoneAbbr } = useProfile();

  const openCheckingLink = (uri, session_id) => {
    window.open(
      `${window.location.href.split('?')[0]}onboard/${uri}/${session_id}`,
      "_blank"
    );
  };

  return (
    <div className="MySchedule white-bg">
      {userAppointments?.length > 0 ? (
        <>
          {userAppointments
            .sort((a, b) =>
              moment(a.start_time).isBefore(moment(b.start_time)) ? 1 : -1
            )
            .map((appointment) => {
              return (
                <div key={appointment._id} className="schedule-item">
                  <div className="day-container">
                    <span className="h-22-600">
                      {moment(appointment?.start_time).format("D")}
                    </span>
                    <span className="h-12-600 secondary-color">
                      {moment(appointment?.start_time).format("MMM")}
                    </span>
                  </div>
                  <div className="details-container">
                    <span className="h-12-400 secondary-color">
                      {moment(appointment?.start_time).format("hh:mm A")} ({timezoneAbbr})
                    </span>
                    <span className="h-14-600">{appointment?.role}</span>
                  </div>
                  <button
                    type="button"
                    className="primary-solid-button h-14-700 ml-auto"
                    onClick={() =>
                      openCheckingLink(
                        appointment?.session?.studio?.uri,
                        appointment?.session?._id
                      )
                    }
                    disabled={!moment().isSame(moment(appointment?.start_time), 'day')}
                  >
                    Check In
                  </button>
                </div>
              );
            })}
        </>
      ) : (
        <div className="empty-state mt-3">
          <img
            className="empty-state-img"
            title="Call in again"
            src={require("../../../assets/calendar.png")}
            alt=""
          />
          <div className="h-12-400 secondary-color">
            Currently, there are no appointments to display.
            <br />
            Any upcoming appointments will appear here.
          </div>
        </div>
      )}
    </div>
  );
};

export default MySchedule;
