import React from 'react'
import moment from 'moment'
import { futureOnlyDates } from '../../../utils'

const FreelancerItem = ({ freelancer }) => {
  return (
    <div>
      <label className='font-weight-bold mb-0'>Experience</label>
      <p>{freelancer.freelancer_profile.experience}</p>
      <label className='font-weight-bold mb-0'>Availability Notes</label>
      <p>{freelancer.freelancer_profile.availability_notes}</p>
    </div>
  )
}

export default FreelancerItem
